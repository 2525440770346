<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';
import { Plus } from 'lucide-vue-next';
import { AxiosError } from 'axios';
import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { exercisesApi } from '@/api/exercises';
import { routinePhasesApi } from '@/api/routine-phases';
import type { RoutineExerciseSetAttributes, RoutinePhaseAttributes, RoutinePhaseExerciseAttributes } from '@/types';
import type { RoutinePhase, RoutinePhaseExercise } from '@/types/extended';
import {
  BaseInput,
  BaseButton,
  RoutinePhaseFormExercise,
  ExercisePicker,
  AudioInput,
  BaseModal,
} from '@/components';
import { useTransformRoutineToAttributes } from '@/composables/transform-routine-to-attributes';
import { useDraggableList } from '@/composables/draggable-list';

interface Props {
  phaseId: number;
  phases: RoutinePhase[];
  phaseAttributes: RoutinePhaseAttributes;
  open: boolean;
}

const props = defineProps<Props>();

const initialPhase = computed(() => props.phases.find((phase) => phase.id === props.phaseId));

const { data: phase } = useQuery({
  queryKey: ['routinePhase', props.phaseId],
  queryFn: () => routinePhasesApi.get(props.phaseId),
  initialData: initialPhase.value,
  refetchOnMount: false,
});

const emit = defineEmits<{
  'close': [void];
  'update': [RoutinePhaseAttributes];
  }>();

const phaseSchema = toTypedSchema(
  yup.object().shape({
    id: yup.number().nullable(),
    name: yup.string().required('Nombre es requerido'),
    position: yup.number().required('Posición es requerida'),
    sets: yup.number().required('Series es requerida').min(1, 'Series debe ser al menos 1'),
    audio: yup.mixed().nullable(),
    routinePhaseExercisesAttributes: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(),
        exerciseId: yup.number().required('Ejercicio es requerido'),
        position: yup.number().required('Posición es requerida'),
        duration: yup.number().required('Duración es requerida'),
        kind: yup.string().required('Tipo de ejercicio es requerido'),
        rest: yup.number().notRequired(),
        _destroy: yup.boolean(),
        routineExerciseSetsAttributes: yup.array().of(
          yup.object().shape({
            id: yup.number().nullable(),
            comment: yup.string().nullable(),
            setNumber: yup.number().required('Número de serie es requerido'),
            repetitions: yup.number().nullable()
              .transform((value, originalValue) => (originalValue === '' ? null : value))
              .test(
                'repetitions',
                'Reps es requerido',
                (value, context) => {
                  const from = context.from;
                  if (!from) return true;

                  const exercise = from[1].value;
                  if (exercise.kind === 'repetition') {
                    return value !== null && value !== undefined;
                  }

                  return true;
                },
              ),
            weight: yup.number().nullable()
              .transform((value, originalValue) => (originalValue === '' ? null : value)),
            audio: yup.mixed().nullable(),
            _destroy: yup.boolean(),
          }),
        ),
      }),
    ),
  }),
);

const initialValues = ref({ ...props.phaseAttributes });
const { values, setFieldValue, handleSubmit, resetForm } = useForm<RoutinePhaseAttributes>({
  validationSchema: phaseSchema,
  initialValues: initialValues.value,
});

const routinePhaseExercisesAttributes = computed(() => values.routinePhaseExercisesAttributes || []);
const {
  sortedItems: draggableExercises,
  onDragStart,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
} = useDraggableList({
  items: routinePhaseExercisesAttributes,
  onUpdate: (newItems) => setFieldValue('routinePhaseExercisesAttributes', newItems),
});

const showExercisePicker = ref(false);
const errorMessage = ref<string | null>(null);

function openExercisePicker() {
  showExercisePicker.value = true;
}

function closeExercisePicker() {
  showExercisePicker.value = false;
}

function removeExercise(index: number) {
  const updatedExercises = [...draggableExercises.value];
  if (updatedExercises[index].id) {
    updatedExercises[index] = { ...updatedExercises[index], _destroy: true };
  } else {
    updatedExercises.splice(index, 1);
  }
  setFieldValue('routinePhaseExercisesAttributes', updatedExercises);
}

function handleExerciseChange(
  index: number,
  field: string,
  value: number | string | RoutineExerciseSetAttributes[] | undefined,
) {
  const updatedExercises = [...draggableExercises.value];
  if (field === 'routineExerciseSetsAttributes') {
    updatedExercises[index] = {
      ...updatedExercises[index],
      routineExerciseSetsAttributes: value as RoutineExerciseSetAttributes[],
    };
  } else {
    updatedExercises[index] = { ...updatedExercises[index], [field]: value };
  }
  setFieldValue('routinePhaseExercisesAttributes', updatedExercises);
}

const { data: exercises } = useQuery({
  queryKey: ['exercises'],
  queryFn: () => exercisesApi.getAll(),
  initialData: [],
  refetchOnWindowFocus: false,
});

const RESET_MUTATION_TIMEOUT = 2000;

function formatErrors(responseErrors: Record<string, string | string[]>) {
  const items = Object.entries(responseErrors).map(([key, value], index) => {
    const messages = Array.isArray(value) ? value : [value];

    return `${index + 1}. ${key}:\n${messages.map(msg => `  - ${msg}`).join('\n')}`;
  });

  return `Errores al guardar la rutina:\n${items.join('\n')}`;
}

const { transformRoutinePhaseToAttributes } = useTransformRoutineToAttributes();
const queryClient = useQueryClient();
const { mutate, isIdle, isPending, isError, isSuccess, reset } = useMutation({
  mutationFn: (phaseAttributes: RoutinePhaseAttributes) => {
    if (phaseAttributes.id) {
      return routinePhasesApi.update(phaseAttributes.id, phaseAttributes);
    }

    return routinePhasesApi.create(phaseAttributes);
  },
  onSuccess: (routinePhase: RoutinePhase) => {
    const newRoutinePhaseAttributes = transformRoutinePhaseToAttributes(routinePhase);
    emit('update', newRoutinePhaseAttributes);

    queryClient.setQueryData(['routinePhase', routinePhase.id], routinePhase);

    setTimeout(() => {
      resetForm({ values: newRoutinePhaseAttributes });
      errorMessage.value = null;
      initialValues.value = { ...newRoutinePhaseAttributes };
      reset();
    }, RESET_MUTATION_TIMEOUT);
  },
  onError: (error) => {
    if (error instanceof AxiosError && error.response?.data?.errors) {
      const responseErrors = error.response.data.errors;
      errorMessage.value = formatErrors(responseErrors);
    } else if (error instanceof AxiosError && error.response?.data.detail) {
      errorMessage.value = error.response.data.detail;
    } else {
      errorMessage.value = 'Ocurrió un error al guardar la rutina';
    }
  },
});

function copyPhaseAttributes(phaseAttributes: RoutinePhaseAttributes) {
  return {
    id: phaseAttributes.id,
    name: phaseAttributes.name,
    position: phaseAttributes.position,
    sets: phaseAttributes.sets,
    audio: phaseAttributes.audio,
    routinePhaseExercisesAttributes: phaseAttributes.routinePhaseExercisesAttributes?.map(exercise => ({
      id: exercise.id,
      exerciseId: exercise.exerciseId,
      position: exercise.position,
      duration: exercise.duration,
      kind: exercise.kind,
      rest: exercise.rest,
      _destroy: exercise._destroy,
      routineExerciseSetsAttributes: exercise.routineExerciseSetsAttributes?.map(set => ({
        id: set.id,
        comment: set.comment,
        setNumber: set.setNumber,
        audio: set.audio,
        repetitions: set.repetitions,
        weight: set.weight,
        _destroy: set._destroy,
      })),
    })),
  };
}

const isFormChanged = computed(() => {
  const currentValues = copyPhaseAttributes(values);
  const initialFormValues = copyPhaseAttributes(initialValues.value);

  return JSON.stringify(currentValues) !== JSON.stringify(initialFormValues);
});

const buttonText = computed(() => {
  if (!isFormChanged.value) return 'Sin cambios';
  if (isIdle.value) return 'Guardar';
  if (isPending.value) return 'Guardando...';
  if (isError.value) return 'Error';
  if (isSuccess.value) return 'Guardado';

  return 'Guardar';
});

const buttonClass = computed(() => {
  if (!isFormChanged.value) return 'bg-gray-300 text-gray-500 hover:bg-gray-300';
  if (isIdle.value) return 'bg-black text-white hover:bg-gray-800';
  if (isPending.value) return 'bg-black text-white cursor-not-allowed';
  if (isError.value) return 'bg-red-500 text-white';
  if (isSuccess.value) return 'bg-green-500 text-white hover:bg-green-600';

  return 'bg-black text-white hover:bg-gray-800';
});

function onSave() {
  handleSubmit((formValues) => {
    mutate(formValues);
  })();
}

function onClose() {
  emit('close');
}

function getRoutinePhaseExercise(routinePhaseExerciseAttributes: RoutinePhaseExerciseAttributes) {
  return phase.value?.routinePhaseExercises.find(
    (exercise: RoutinePhaseExercise) => exercise.id === routinePhaseExerciseAttributes.id,
  );
}

function updateRoutineExerciseSets(routinePhaseExerciseAttributes: RoutinePhaseExerciseAttributes[], setCount: number) {
  return routinePhaseExerciseAttributes.map(routinePhaseExercise => {
    const currentSets = routinePhaseExercise.routineExerciseSetsAttributes || [];
    const updatedSets = Array.from({ length: setCount }, (_, index) => {
      if (index < currentSets.length) {
        return currentSets[index];
      }

      return { setNumber: index + 1 };
    });

    for (let i = setCount; i < currentSets.length; i++) {
      if (currentSets[i].id) {
        updatedSets.push({ ...currentSets[i], _destroy: true });
      }
    }

    return {
      ...routinePhaseExercise,
      routineExerciseSetsAttributes: updatedSets,
    };
  });
}

watch(() => values.sets, (newSetCount) => {
  if (newSetCount < 1) return;

  const updatedExercises = updateRoutineExerciseSets(draggableExercises.value, newSetCount);
  setFieldValue('routinePhaseExercisesAttributes', updatedExercises);
}, { immediate: true });

function handleExerciseAddition(exerciseIds: number[]) {
  const newExercises = exerciseIds.map((exerciseId) => {
    const newExercise: RoutinePhaseExerciseAttributes = {
      exerciseId,
      position: draggableExercises.value.length + 1,
      kind: 'repetition',
      routineExerciseSetsAttributes: Array.from({ length: values.sets }, (_, index) => ({
        setNumber: index + 1,
      })),
    };

    return newExercise;
  });

  const updatedExercises = [...draggableExercises.value, ...newExercises];
  setFieldValue('routinePhaseExercisesAttributes', updatedExercises);
  closeExercisePicker();
}

</script>

<template>
  <BaseModal
    :open="props.open"
    title="Editar bloque"
    @close="onClose"
  >
    <form class="flex h-full flex-col">
      <div class="flex flex-1 flex-col gap-4 pb-4">
        <BaseInput
          id="name"
          :model-value="values.name"
          name="name"
          autocomplete="off"
          class="text-xl font-bold"
          @update:model-value="setFieldValue('name', $event)"
        />
        <div class="flex gap-4">
          <div class="flex-1">
            <label
              for="sets"
              class="mb-1 block text-sm text-gray-500"
            >
              Series
            </label>
            <BaseInput
              id="sets"
              :model-value="values.sets"
              name="sets"
              type="number"
              class="text-xs"
              @update:model-value="setFieldValue('sets', $event)"
            />
          </div>
          <div class="flex-1">
            <label
              for="audio"
              class="mb-1 block text-sm text-gray-500"
            >
              Audio
            </label>
            <AudioInput
              :model-value="values.audio"
              :audio-url="phase?.audioUrl"
              @update="setFieldValue('audio', $event)"
            />
          </div>
        </div>
        <div>
          <h3 class="mb-2 border-b border-gray-200 pb-2 text-sm font-medium text-gray-500">
            Ejercicios
          </h3>
          <ul
            class="flex flex-col gap-2 divide-gray-200"
          >
            <li
              v-for="(routinePhaseExerciseAttributes, index) in draggableExercises"
              :key="routinePhaseExerciseAttributes.position"
              draggable="true"
              :class="{ 'hidden': routinePhaseExerciseAttributes._destroy }"
              @dragstart="onDragStart($event, routinePhaseExerciseAttributes, index)"
              @dragover="onDragOver"
              @dragenter="onDragEnter"
              @dragleave="onDragLeave"
              @drop="onDrop($event, index)"
            >
              <RoutinePhaseFormExercise
                :routine-phase-exercise="getRoutinePhaseExercise(routinePhaseExerciseAttributes)"
                :routine-phase-exercise-attributes="routinePhaseExerciseAttributes"
                :exercises="exercises"
                :index="index"
                @update="handleExerciseChange"
                @remove="removeExercise"
              />
            </li>
          </ul>
          <BaseButton
            type="button"
            variant="secondary"
            size="sm"
            class="w-full"
            @click="openExercisePicker"
          >
            <Plus class="mr-2 size-4" />
            Agregar ejercicios
          </BaseButton>
        </div>
      </div>
      <ExercisePicker
        v-if="showExercisePicker"
        mode="multiple"
        @close="closeExercisePicker"
        @select="handleExerciseAddition"
      />
      <div class="flex flex-col gap-4">
        <BaseButton
          type="button"
          size="sm"
          :class="['w-full text-xs', buttonClass]"
          :disabled="!isFormChanged"
          @click="onSave"
        >
          {{ buttonText }}
        </BaseButton>
        <p
          v-if="errorMessage"
          class="whitespace-pre-wrap text-sm text-red-500"
        >
          {{ errorMessage }}
        </p>
      </div>
    </form>
  </BaseModal>
</template>
