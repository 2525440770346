<script setup lang="ts">
import { ref, computed, watch, onMounted, nextTick } from 'vue';
import { format } from 'date-fns';
import type { Lead, LeadComment, Trainer, SubmissionAnswer, LeadChatMessage } from '@/types';
import { leadCommentsApi } from '@/api/lead-comments';
import { trainersApi } from '@/api/trainers';
import { trainerSelectionApi } from '@/api/trainer-selection';
import { initialSurveySubmissionsApi } from '@/api/initial-survey-submissions';

const props = defineProps<{
  lead: Lead | null,
  statuses: string[]
}>();

const emits = defineEmits(['close', 'update:lead', 'open-message-modal']);

const newComment = ref('');
const localLeadComments = ref<LeadComment[]>([]);
const trainers = ref<Trainer[]>([]);
const selectedTrainerId = ref<number | null>(null);
const localStatus = ref('');
const localContactedAt = ref('');
const localPlannedContactWeek = ref('');
const actionMessage = ref('');
const actionSuccess = ref(false);
const editingPhone = ref(false);
const editedPhone = ref('');
const chatMessagesContainer = ref<HTMLElement | null>(null);

// Reset local state when a new lead is loaded
watch(() => props.lead, (newLead) => {
  if (newLead) {
    localLeadComments.value = newLead.leadComments ? [...newLead.leadComments] : [];
    selectedTrainerId.value = newLead.assignedTrainer?.id || null;
    localStatus.value = newLead.status || '';
    localContactedAt.value = newLead.contactedAt || '';
    localPlannedContactWeek.value = newLead.plannedContactWeek || '';
    actionMessage.value = '';
    actionSuccess.value = false;
    newComment.value = '';
    editingPhone.value = false;
    editedPhone.value = '';
  }
}, { immediate: true });

onMounted(async () => {
  trainers.value = await trainersApi.getAll();
});

function formatDate(date: string): string {
  return format(new Date(date), 'MMM d, yyyy HH:mm');
}

async function assignTrainer() {
  if (!selectedTrainerId.value || !props.lead) return;

  try {
    const trainerSelection = await trainerSelectionApi.create({
      trainerId: selectedTrainerId.value,
      email: props.lead.email,
    });

    const assignedTrainer = trainers.value.find(t => t.id === trainerSelection.trainerId);

    // Update lead status if it's a new assignment
    if (!props.lead.assignedTrainer) {
      await updateLead({ status: 'contacted', contactedAt: new Date().toISOString() });
    }

    const updatedLead = { ...props.lead, assignedTrainer };
    emits('update:lead', updatedLead);

    actionMessage.value = props.lead.assignedTrainer ? 'Trainer changed successfully!' : 'Trainer assigned successfully!';
    actionSuccess.value = true;

    // Add a comment about the trainer assignment or change
    if (assignedTrainer) {
      const action = props.lead.assignedTrainer ? 'changed to' : 'assigned to this lead:';
      await addComment(`Trainer ${action} ${assignedTrainer.fullName}`);
    }
  } catch (error) {
    console.error('Error assigning trainer:', error);
    actionMessage.value = 'Failed to assign trainer. Please try again.';
    actionSuccess.value = false;
  }
}

async function updateLead(updates: Partial<Lead>) {
  if (!props.lead) return;

  try {
    const updatedLead = await initialSurveySubmissionsApi.update(props.lead.id, updates);
    emits('update:lead', { ...props.lead, ...updatedLead });
    actionMessage.value = 'Lead updated successfully!';
    actionSuccess.value = true;
  } catch (error) {
    console.error('Error updating lead:', error);
    actionMessage.value = 'Failed to update lead. Please try again.';
    actionSuccess.value = false;
  }
}

function openMessageModal() {
  if (props.lead?.status === 'new') {
    emits('open-message-modal', props.lead);
  } else {
    openWhatsApp();
  }
}

function openWhatsApp() {
  if (props.lead) {
    const formattedPhone = formatPhoneNumber(props.lead.countryCode, props.lead.phoneNumber).replace(/\+/g, '');
    const whatsappUrl = `https://wa.me/${formattedPhone}`;
    window.open(whatsappUrl, '_blank');
  }
}

async function addComment(content: string) {
  const commentContent: string = content.trim();

  if (!props.lead || !commentContent) {
    actionMessage.value = 'Comment cannot be empty.';
    actionSuccess.value = false;

    return;
  }

  try {
    const comment = await leadCommentsApi.create({
      content: commentContent,
      initialSurveySubmissionId: props.lead.id,
    });

    localLeadComments.value.unshift(comment);
    newComment.value = '';

    const updatedLead = { ...props.lead, leadComments: [comment, ...localLeadComments.value] };
    emits('update:lead', updatedLead);

    actionMessage.value = 'Comment added successfully.';
    actionSuccess.value = true;
  } catch (error) {
    console.error('Error adding comment:', error);
    actionMessage.value = 'Failed to add comment. Please try again.';
    actionSuccess.value = false;
  }
}

function getAnswerValue(title: string): string {
  return props.lead?.submissionAnswers.find((a: SubmissionAnswer) => a.title === title)?.value || '-';
}

function formatPhoneNumber(countryCode: string, phoneNumber: string | null | undefined): string {
  if (!phoneNumber) return '-';
  const cleanCountryCode = countryCode.replace('+', '');
  if (phoneNumber.startsWith('+')) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith(cleanCountryCode)) {
    return `+${phoneNumber}`;
  }
  if (cleanCountryCode === '52') {
    return `+52${phoneNumber.startsWith('1') ? '' : '1'}${phoneNumber}`;
  }

  return `+${cleanCountryCode}${phoneNumber}`;
}

function startEditingPhone() {
  editingPhone.value = true;
  editedPhone.value = props.lead?.phoneNumber || '';
}

async function saveEditedPhone() {
  if (!props.lead || editedPhone.value === props.lead.phoneNumber) {
    editingPhone.value = false;

    return;
  }

  try {
    const updatedLead = await initialSurveySubmissionsApi.update(props.lead.id, { phoneNumber: editedPhone.value });
    emits('update:lead', { ...props.lead, phoneNumber: updatedLead.phoneNumber });
    editingPhone.value = false;
  } catch (error) {
    console.error('Error updating phone number:', error);
  }
}

const leadDetails = computed(() => [
  { label: 'Name', value: props.lead?.name },
  { label: 'Email', value: props.lead?.email },
  {
    label: 'Phone',
    value: props.lead ? formatPhoneNumber(props.lead.countryCode, props.lead.phoneNumber) : '-',
    editable: true,
  },
  { label: 'Original Phone', value: props.lead?.phoneNumber || '-' },
  { label: 'City', value: props.lead?.city },
  { label: 'Country', value: props.lead?.country },
  { label: 'Age', value: getAnswerValue('¿Qué edad tienes?') },
  { label: 'Gender', value: getAnswerValue('¿Cuál es tu sexo?') },
  { label: 'Preferred Coach Gender', value: props.lead?.preferredCoachGender },
  { label: 'Experience', value: props.lead?.experience },
  { label: 'Fitness Goal', value: props.lead?.fitnessGoal },
  { label: 'Other Success Metrics', value: getAnswerValue('¿De qué otra forma se ve el éxito para ti?') },
  { label: 'Preferred Training Location', value: props.lead?.preferredTrainingLocation },
  { label: 'Desired Training Intensity', value: getAnswerValue('¿Qué nivel de intensidad de entrenamiento quieres?') },
  { label: 'Obstacles', value: getAnswerValue('¿Qué cosas no te han permitido alcanzar tus objetivos?') },
  { label: 'Referral', value: getAnswerValue('¿Alguien te invitó a Kapso como su referido?') },
  { label: 'Referrer', value: props.lead?.referrer ? `${props.lead.referrer.firstName} ${props.lead.referrer.lastName} ⭐` : '-' },
  { label: 'Referrer is Kapso User', value: getAnswerValue('¿La persona que te refirió es usuari@ de Kapso?') },
  { label: 'How did you find Kapso', value: getAnswerValue('¿Cómo llegaste a Kapso?') },
]);

const leadFullName = computed(() => {
  if (props.lead) {
    return `${props.lead.name}${props.lead.referrer ? ' ⭐' : ''}`;
  }

  return 'Lead Details';
});

// No need to reverse the chat messages anymore
const chatMessages = computed(() => props.lead?.leadChatMessages || []);

// Function to scroll to the bottom of the chat messages
function scrollToBottom() {
  nextTick(() => {
    if (chatMessagesContainer.value) {
      chatMessagesContainer.value.scrollTop = chatMessagesContainer.value.scrollHeight;
    }
  });
}

// Call scrollToBottom when the component mounts and when new messages are added
onMounted(scrollToBottom);
watch(() => props.lead?.leadChatMessages, scrollToBottom);

function getSenderName(message: LeadChatMessage): string {
  return message.senderPhone === props.lead?.phoneNumber ? props.lead?.name || 'Lead' : 'Kapso';
}

function isMessageFromLead(message: LeadChatMessage): boolean {
  return message.senderPhone === props.lead?.phoneNumber;
}
</script>

<template>
  <div
    v-if="lead"
    class="fixed inset-0 flex items-center justify-center overflow-y-auto bg-black bg-opacity-50"
  >
    <div class="relative my-8 flex w-11/12 max-w-7xl flex-col rounded-lg bg-white p-6 shadow-xl">
      <button
        class="absolute right-2 top-2 rounded-full bg-gray-200 px-4 py-2 font-bold text-gray-800 transition duration-300 hover:bg-gray-300"
        @click="$emit('close')"
      >
        ×
      </button>

      <div class="flex">
        <div class="w-2/3 pr-6">
          <h2 class="mb-4 text-2xl font-bold text-gray-800">
            {{ leadFullName }}
          </h2>
          <div class="grid grid-cols-2 gap-x-4 gap-y-2">
            <div
              v-for="(item, index) in leadDetails"
              :key="index"
              class="mb-2"
            >
              <div class="text-xs font-medium text-gray-500">
                {{ item.label }}
              </div>
              <div
                class="text-sm font-semibold text-gray-800"
                @dblclick="item.editable && startEditingPhone()"
              >
                <template v-if="item.label === 'Phone' && editingPhone">
                  <input
                    v-model="editedPhone"
                    type="text"
                    class="w-full rounded border p-1"
                    @keyup.enter="saveEditedPhone"
                  >
                  <button
                    class="ml-1 rounded bg-green-500 px-2 py-1 text-xs text-white"
                    @click="saveEditedPhone"
                  >
                    ✓
                  </button>
                </template>
                <template v-else>
                  {{ item.value }}
                </template>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="text-xs font-medium text-gray-500">
              Additional Information
            </div>
            <div class="text-sm font-semibold text-gray-800">
              {{ getAnswerValue('¿Algo más que deberíamos saber?') }}
            </div>
          </div>

          <!-- Lead Chat Messages section -->
          <h3 class="mb-4 mt-6 text-xl font-semibold">
            Chat Messages
          </h3>
          <div
            v-if="lead.leadChatMessages && lead.leadChatMessages.length > 0"
            ref="chatMessagesContainer"
            class="mb-4 h-96 space-y-4 overflow-y-auto rounded border p-4"
          >
            <div
              v-for="message in chatMessages"
              :key="message.id"
              class="flex"
              :class="{ 'justify-end': isMessageFromLead(message) }"
            >
              <div
                class="max-w-[70%] rounded p-4"
                :class="isMessageFromLead(message) ? 'bg-blue-100' : 'bg-gray-100'"
              >
                <p class="mb-1 text-xs font-bold">
                  {{ getSenderName(message) }}
                </p>
                <p class="text-sm text-gray-800">
                  {{ message.textContent }}
                </p>
                <p class="mt-2 text-xs text-gray-500">
                  {{ formatDate(message.createdAt) }}
                </p>
              </div>
            </div>
          </div>
          <div
            v-else
            class="mb-4 text-sm text-gray-500"
          >
            No chat messages yet.
          </div>
        </div>

        <div class="w-1/3 border-l pl-6">
          <!-- Actions section -->
          <div class="mb-6">
            <h3 class="mb-4 text-xl font-semibold">
              Lead Actions
            </h3>
            <div class="space-y-4">
              <!-- Assign Trainer -->
              <div>
                <label class="mb-1 block text-sm font-medium text-gray-700">Assign Trainer</label>
                <div class="flex items-center">
                  <select
                    v-model="selectedTrainerId"
                    class="mr-2 w-full rounded border p-2 text-sm"
                    @change="assignTrainer"
                  >
                    <option value="">
                      Select a trainer
                    </option>
                    <option
                      v-for="trainer in trainers"
                      :key="trainer.id"
                      :value="trainer.id"
                    >
                      {{ trainer.fullName }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Change Status -->
              <div>
                <label class="mb-1 block text-sm font-medium text-gray-700">Change Status</label>
                <div class="flex items-center">
                  <select
                    v-model="localStatus"
                    class="mr-2 w-full rounded border p-2 text-sm"
                    @change="updateLead({ status: localStatus })"
                  >
                    <option
                      v-for="status in statuses"
                      :key="status"
                      :value="status"
                    >
                      {{ status }}
                    </option>
                  </select>
                </div>
              </div>

              <!-- Set Contacted At -->
              <div>
                <label class="mb-1 block text-sm font-medium text-gray-700">Set Contacted At</label>
                <div class="flex items-center">
                  <input
                    v-model="localContactedAt"
                    type="date"
                    class="mr-2 w-full rounded border p-2 text-sm"
                    @change="updateLead({ contactedAt: localContactedAt })"
                  >
                </div>
              </div>

              <!-- Set Planned Contact Week -->
              <div>
                <label class="mb-1 block text-sm font-medium text-gray-700">Set Planned Contact Week</label>
                <div class="flex items-center">
                  <input
                    v-model="localPlannedContactWeek"
                    type="date"
                    class="mr-2 w-full rounded border p-2 text-sm"
                    @change="updateLead({ plannedContactWeek: localPlannedContactWeek })"
                  >
                </div>
              </div>

              <!-- Send Message -->
              <div>
                <button
                  class="w-full rounded bg-blue-500 px-4 py-2 text-sm font-bold text-white hover:bg-blue-600"
                  @click="openMessageModal"
                >
                  {{ lead.status === 'new' ? 'Send Message' : 'Open WhatsApp' }}
                </button>
              </div>
            </div>
            <p
              v-if="actionMessage"
              class="mt-2 text-sm"
              :class="{ 'text-green-600': actionSuccess, 'text-red-600': !actionSuccess }"
            >
              {{ actionMessage }}
            </p>
          </div>

          <!-- Comments section -->
          <h3 class="mb-4 text-xl font-semibold">
            Comments
          </h3>
          <div
            v-if="localLeadComments.length > 0"
            class="mb-4 max-h-[30vh] space-y-4 overflow-y-auto"
          >
            <div
              v-for="comment in localLeadComments"
              :key="comment.id"
              class="rounded bg-gray-100 p-4"
            >
              <p class="text-sm text-gray-800">
                {{ comment.content }}
              </p>
              <p class="mt-2 text-xs text-gray-500">
                {{ formatDate(comment.createdAt) }}
              </p>
            </div>
          </div>
          <div
            v-else
            class="mb-4 text-sm text-gray-500"
          >
            No comments yet.
          </div>

          <div>
            <textarea
              v-model="newComment"
              class="w-full rounded border p-2 text-sm"
              rows="3"
              placeholder="Add a comment..."
            />
            <button
              class="mt-2 w-full rounded bg-blue-500 px-4 py-2 text-sm font-bold text-white hover:bg-blue-600"
              @click="addComment(newComment)"
            >
              Add Comment
            </button>
            <p
              v-if="actionMessage"
              class="mt-2 text-sm"
              :class="{ 'text-green-600': actionSuccess, 'text-red-600': !actionSuccess }"
            >
              {{ actionMessage }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
