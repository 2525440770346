import type { InitialSurveySubmission, TallySubmissionPayload } from '@/types';
import { api } from './index';

export const initialSurveySubmissionsApi = {
  create(submission: Partial<TallySubmissionPayload>) {
    const path = '/api/internal/initial_survey_submissions';

    return api({
      method: 'post',
      url: path,
      data: { initialSurveySubmission: submission },
    });
  },
  async update(id: number, data: { status?: string; contactedAt?: string }) {
    const response = await api({
      method: 'put',
      url: `/api/internal/initial_survey_submissions/${id}`,
      data: { initialSurveySubmission: data },
    });

    return response.data as InitialSurveySubmission;
  },
};
