<script setup lang="ts">
import { ref, defineProps, defineEmits, computed, watch, onMounted, onUnmounted } from 'vue';
import { format, parseISO, startOfWeek, endOfWeek, isWithinInterval } from 'date-fns';
import { es } from 'date-fns/locale';
import type { Lead } from '@/types';
import FilterDropdown from './FilterDropdown.vue';
import { initialSurveySubmissionsApi } from '@/api/initial-survey-submissions';

const props = defineProps<{
  leads: Lead[],
  statuses: string[]
}>();

const emits = defineEmits(['view-details', 'send-message', 'update-lead']);

const statusColors = {
  new: 'bg-blue-100 text-blue-800',
  contacted: 'bg-yellow-100 text-yellow-800',
  in_conversation: 'bg-green-100 text-green-800',
  link_sent: 'bg-purple-100 text-purple-800',
  discarded: 'bg-red-100 text-red-800',
  sold: 'bg-emerald-100 text-emerald-800',
  invalid: 'bg-gray-100 text-gray-800',
};

const filters = ref({
  country: [],
  status: [],
  experience: [],
  fitnessGoal: [],
  trainingLocation: [],
  contactedWeek: [],
  plannedContactWeek: [],
  createdAtWeek: [],
});

const uniqueValues = ref({
  country: [],
  status: [],
  experience: [],
  fitnessGoal: [],
  trainingLocation: [],
  contactedWeek: [],
  plannedContactWeek: [],
  createdAtWeek: [],
});

watch(() => props.leads, (newLeads) => {
  if (Array.isArray(newLeads)) {
    uniqueValues.value = {
      country: [...new Set(newLeads.map(lead => lead.country))],
      status: props.statuses,
      experience: [...new Set(newLeads.map(lead => lead.experience))],
      fitnessGoal: [...new Set(newLeads.map(lead => lead.fitnessGoal))],
      trainingLocation: [...new Set(newLeads.flatMap(lead => lead.preferredTrainingLocation.split(',').map(loc => loc.trim())))],
      contactedWeek: getUniqueWeeks(newLeads.map(lead => lead.contactedAt)),
      plannedContactWeek: getUniqueWeeks(newLeads.map(lead => lead.plannedContactWeek)),
      createdAtWeek: getUniqueWeeks(newLeads.map(lead => lead.createdAt)),
    };
  }
}, { immediate: true });

function getUniqueWeeks(dates: (string | null)[]): string[] {
  const weeks = new Set<string>();
  dates.forEach(date => {
    if (date) {
      const weekStart = startOfWeek(parseISO(date), { weekStartsOn: 1 });
      weeks.add(format(weekStart, "'Week of' yyyy-MM-dd"));
    }
  });

  return ['All', ...Array.from(weeks)];
}

function formatDate(dateString: string): string {
  return format(new Date(dateString), 'd MMMM yyyy', { locale: es });
}

function formatDateForInput(dateString: string | null): string {
  if (!dateString) return '';

  return format(parseISO(dateString), 'yyyy-MM-dd');
}

async function updateLead(lead: Lead) {
  try {
    const updatedLead = await initialSurveySubmissionsApi.update(lead.id, {
      status: lead.status,
      contactedAt: lead.contactedAt ? `${lead.contactedAt}T12:00:00Z` : null,
      plannedContactWeek: lead.plannedContactWeek ? `${lead.plannedContactWeek}T12:00:00Z` : null,
    });

    emits('update-lead', {
      ...lead,
      ...updatedLead,
      contactedAt: updatedLead.contactedAt ? format(parseISO(updatedLead.contactedAt), 'yyyy-MM-dd') : null,
      plannedContactWeek: updatedLead.plannedContactWeek ? format(parseISO(updatedLead.plannedContactWeek), 'yyyy-MM-dd') : null,
    });
  } catch (error) {
    console.error('Error updating lead:', error);
  }
}

function updateFilter(filterName: string, selectedValues: string[]) {
  filters.value[filterName] = selectedValues;
}

const filteredLeads = computed(() => {
  if (!Array.isArray(props.leads)) return [];

  return props.leads.filter(lead => {
    const contactedWeekMatch = isInWeek(lead.contactedAt, filters.value.contactedWeek);
    const plannedContactWeekMatch = isInWeek(lead.plannedContactWeek, filters.value.plannedContactWeek);
    const createdAtWeekMatch = isInWeek(lead.createdAt, filters.value.createdAtWeek);

    return (filters.value.country.length === 0 || filters.value.country.includes(lead.country)) &&
           (filters.value.status.length === 0 || filters.value.status.includes(lead.status)) &&
           (filters.value.experience.length === 0 || filters.value.experience.includes(lead.experience)) &&
           (filters.value.fitnessGoal.length === 0 || filters.value.fitnessGoal.includes(lead.fitnessGoal)) &&
           (filters.value.trainingLocation.length === 0 || lead.preferredTrainingLocation.split(',').some(loc => filters.value.trainingLocation.includes(loc.trim()))) &&
           contactedWeekMatch &&
           plannedContactWeekMatch &&
           createdAtWeekMatch;
  });
});

function isInWeek(dateString: string | null, weekOptions: string[]): boolean {
  if (weekOptions.length === 0 || weekOptions.includes('All')) return true;
  if (!dateString) return false;

  const date = parseISO(dateString);

  return weekOptions.some(weekOption => {
    const weekStart = parseISO(weekOption.replace('Week of ', ''));
    const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });

    return isWithinInterval(date, { start: weekStart, end: weekEnd });
  });
}

const buttonClasses = 'rounded px-2 py-1 text-white text-xs';
const selectClasses = 'rounded border p-1 text-xs font-semibold';

const selectedCell = ref<string | null>(null);
const copiedValue = ref<string | null>(null);

function selectCell(cellId: string) {
  selectedCell.value = cellId;
}

function copySelectedCell(event: KeyboardEvent) {
  if (selectedCell.value && (event.metaKey || event.ctrlKey) && event.key === 'c') {
    const [field, leadId] = selectedCell.value.split('-');
    const lead = filteredLeads.value.find(l => l.id.toString() === leadId);
    if (lead) {
      let valueToCopy = '';
      switch (field) {
      case 'name':
      case 'countryCode':
      case 'phoneNumber':
      case 'country':
      case 'status':
      case 'fitnessGoal':
      case 'preferredTrainingLocation':
        valueToCopy = lead[field] as string;
        break;
      case 'plannedContactWeek':
      case 'contactedAt':
        valueToCopy = lead[field] || '';
        break;
      case 'createdAt':
        valueToCopy = formatDate(lead.createdAt);
        break;
      case 'experience':
        valueToCopy = lead.experience;
        break;
      }
      copiedValue.value = valueToCopy;
      navigator.clipboard.writeText(valueToCopy);
      event.preventDefault();
    }
  }
}

function pasteToSelectedCell(event: KeyboardEvent) {
  if (selectedCell.value && copiedValue.value && (event.metaKey || event.ctrlKey) && event.key === 'v') {
    const [field, leadId] = selectedCell.value.split('-');
    const lead = filteredLeads.value.find(l => l.id.toString() === leadId);
    if (lead && (field === 'plannedContactWeek' || field === 'contactedAt')) {
      lead[field] = copiedValue.value;
      updateLead(lead);
      event.preventDefault();
    }
  }
}

onMounted(() => {
  document.addEventListener('keydown', copySelectedCell);
  document.addEventListener('keydown', pasteToSelectedCell);
});

onUnmounted(() => {
  document.removeEventListener('keydown', copySelectedCell);
  document.removeEventListener('keydown', pasteToSelectedCell);
});

function openWhatsApp(lead: Lead) {
  const formattedPhone = formatPhoneNumber(lead.countryCode, lead.phoneNumber).replace(/\+/g, '');
  const whatsappUrl = `https://wa.me/${formattedPhone}`;
  window.open(whatsappUrl, '_blank');
}

function handleMessageClick(lead: Lead) {
  if (lead.status === 'new') {
    emits('send-message', lead);
  } else {
    openWhatsApp(lead);
  }
}

const leadStatusSummary = computed(() => {
  const summary = props.statuses.reduce((acc, status) => {
    acc[status] = filteredLeads.value.filter(lead => lead.status === status).length;

    return acc;
  }, {} as Record<string, number>);

  summary.total = filteredLeads.value.length;

  return summary;
});

function formatPhoneNumber(countryCode: string, phoneNumber: string | null | undefined): string {
  if (!phoneNumber) return '-';
  const cleanCountryCode = countryCode.replace('+', '');
  if (phoneNumber.startsWith('+')) {
    return phoneNumber;
  }
  if (phoneNumber.startsWith(cleanCountryCode)) {
    return `+${phoneNumber}`;
  }
  if (cleanCountryCode === '52') {
    return `+52${phoneNumber.startsWith('1') ? '' : '1'}${phoneNumber}`;
  }
  return `+${cleanCountryCode}${phoneNumber}`;
}

const editingPhone = ref<number | null>(null);
const editedPhone = ref('');

function startEditingPhone(leadId: number, phoneNumber: string) {
  editingPhone.value = leadId;
  editedPhone.value = phoneNumber;
}

async function saveEditedPhone(lead: Lead) {
  if (editedPhone.value === lead.phoneNumber) {
    editingPhone.value = null;
    return;
  }

  try {
    const updatedLead = await initialSurveySubmissionsApi.update(lead.id, { phoneNumber: editedPhone.value });
    const updatedLeadWithPhone = { ...lead, phoneNumber: updatedLead.phoneNumber };
    emits('update-lead', updatedLeadWithPhone);
    editingPhone.value = null;
  } catch (error) {
    console.error('Error updating phone number:', error);
  }
}
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <div class="mb-4 flex gap-x-2 text-xs">
      <span class="ml-2 font-bold">
        Total: {{ leadStatusSummary.total }}
      </span>
      <span
        v-for="status in statuses"
        :key="status"
      >
        {{ status }}: {{ leadStatusSummary[status] }} ({{ ((leadStatusSummary[status] || 0) / leadStatusSummary.total).toFixed(2) * 100 }}%)
      </span>
    </div>
    <div class="grow overflow-auto">
      <table class="w-full border-collapse border text-xs">
        <thead>
          <tr class="bg-gray-100">
            <th class="z-2 sticky left-0 whitespace-nowrap border bg-gray-100 p-2">
              Name
            </th>
            <th class="whitespace-nowrap border p-2">
              Phone
            </th>
            <th class="whitespace-nowrap border p-2">
              Country
              <FilterDropdown
                :options="uniqueValues.country"
                :selected-values="filters.country"
                @update:selected="updateFilter('country', $event)"
              />
            </th>
            <th class="whitespace-nowrap border p-2">
              Status
              <FilterDropdown
                :options="uniqueValues.status"
                :selected-values="filters.status"
                @update:selected="updateFilter('status', $event)"
              />
            </th>
            <th class="whitespace-nowrap border p-2">
              Experience
              <FilterDropdown
                :options="uniqueValues.experience"
                :selected-values="filters.experience"
                @update:selected="updateFilter('experience', $event)"
              />
            </th>
            <th class="whitespace-nowrap border p-2">
              Main Fitness Goal
              <FilterDropdown
                :options="uniqueValues.fitnessGoal"
                :selected-values="filters.fitnessGoal"
                @update:selected="updateFilter('fitnessGoal', $event)"
              />
            </th>
            <th class="whitespace-nowrap border p-2">
              Training Location
              <FilterDropdown
                :options="uniqueValues.trainingLocation"
                :selected-values="filters.trainingLocation"
                @update:selected="updateFilter('trainingLocation', $event)"
              />
            </th>
            <th class="whitespace-nowrap border p-2">
              Planned Contact Week
              <FilterDropdown
                :options="uniqueValues.plannedContactWeek"
                :selected-values="filters.plannedContactWeek"
                @update:selected="updateFilter('plannedContactWeek', $event)"
              />
            </th>
            <th class="whitespace-nowrap border p-2">
              Contacted At
              <FilterDropdown
                :options="uniqueValues.contactedWeek"
                :selected-values="filters.contactedWeek"
                @update:selected="updateFilter('contactedWeek', $event)"
              />
            </th>
            <th class="whitespace-nowrap border p-2">
              Created At
              <FilterDropdown
                :options="uniqueValues.createdAtWeek"
                :selected-values="filters.createdAtWeek"
                @update:selected="updateFilter('createdAtWeek', $event)"
              />
            </th>
            <th class="sticky right-0 whitespace-nowrap border bg-gray-100 p-2">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="filteredLeads.length === 0">
            <td
              colspan="12"
              class="py-4 text-center"
            >
              No leads found. Try adjusting your filters.
            </td>
          </tr>
          <tr
            v-for="lead in filteredLeads"
            :key="lead.id"
            class="border hover:bg-gray-50"
          >
            <td
              class="sticky left-0 whitespace-nowrap border bg-white p-2"
              :class="{ 'bg-blue-100': selectedCell === `name-${lead.id}` }"
              @click="selectCell(`name-${lead.id}`)"
            >
              {{ lead.name }}
              <span v-if="lead.referrer" class="ml-1">⭐</span>
            </td>
            <td
              class="whitespace-nowrap border p-2 relative"
              :class="{ 'bg-blue-100': selectedCell === `phoneNumber-${lead.id}` }"
              @click="selectCell(`phoneNumber-${lead.id}`)"
              @dblclick="startEditingPhone(lead.id, lead.phoneNumber)"
            >
              <template v-if="editingPhone === lead.id">
                <div class="flex items-center">
                  <input
                    v-model="editedPhone"
                    type="text"
                    class="w-full rounded border p-1 pr-6 text-xs"
                    @keyup.enter="saveEditedPhone(lead)"
                  >
                  <button
                    class="absolute right-1 top-1/2 -translate-y-1/2 rounded bg-green-500 px-1 py-0.5 text-white text-xs"
                    @click="saveEditedPhone(lead)"
                  >
                    ✓
                  </button>
                </div>
              </template>
              <template v-else>
                {{ formatPhoneNumber(lead.countryCode, lead.phoneNumber) }}
              </template>
            </td>
            <td
              class="whitespace-nowrap border p-2"
              :class="{ 'bg-blue-100': selectedCell === `country-${lead.id}` }"
              @click="selectCell(`country-${lead.id}`)"
            >
              {{ lead.country }}
            </td>
            <td
              class="whitespace-nowrap border p-2"
              :class="{ 'bg-blue-100': selectedCell === `status-${lead.id}` }"
              @click="selectCell(`status-${lead.id}`)"
            >
              <select
                v-model="lead.status"
                class="rounded border p-1 text-xs font-semibold"
                :class="statusColors[lead.status]"
                @change="updateLead(lead)"
              >
                <option
                  v-for="status in statuses"
                  :key="status"
                  :value="status"
                >
                  {{ status.replace('_', ' ') }}
                </option>
              </select>
            </td>
            <td
              class="whitespace-nowrap border p-2"
              :class="{ 'bg-blue-100': selectedCell === `experience-${lead.id}` }"
              @click="selectCell(`experience-${lead.id}`)"
            >
              {{ lead.experience }}
            </td>
            <td
              class="whitespace-nowrap border p-2"
              :class="{ 'bg-blue-100': selectedCell === `fitnessGoal-${lead.id}` }"
              @click="selectCell(`fitnessGoal-${lead.id}`)"
            >
              {{ lead.fitnessGoal }}
            </td>
            <td
              class="whitespace-nowrap border p-2"
              :class="{ 'bg-blue-100': selectedCell === `preferredTrainingLocation-${lead.id}` }"
              @click="selectCell(`preferredTrainingLocation-${lead.id}`)"
            >
              {{ lead.preferredTrainingLocation }}
            </td>
            <td
              class="whitespace-nowrap border p-2"
              :class="{ 'bg-blue-100': selectedCell === `plannedContactWeek-${lead.id}` }"
              @click="selectCell(`plannedContactWeek-${lead.id}`)"
            >
              <input
                :value="formatDateForInput(lead.plannedContactWeek)"
                type="date"
                class="rounded border p-1"
                @input="(e) => { lead.plannedContactWeek = (e.target as HTMLInputElement).value; updateLead(lead); }"
              >
            </td>
            <td
              class="whitespace-nowrap border p-2"
              :class="{ 'bg-blue-100': selectedCell === `contactedAt-${lead.id}` }"
              @click="selectCell(`contactedAt-${lead.id}`)"
            >
              <input
                :value="formatDateForInput(lead.contactedAt)"
                type="date"
                class="rounded border p-1"
                @input="(e) => { lead.contactedAt = (e.target as HTMLInputElement).value; updateLead(lead); }"
              >
            </td>
            <td
              class="whitespace-nowrap border p-2"
              :class="{ 'bg-blue-100': selectedCell === `createdAt-${lead.id}` }"
              @click="selectCell(`createdAt-${lead.id}`)"
            >
              {{ formatDate(lead.createdAt) }}
            </td>
            <td class="sticky right-0 whitespace-nowrap border bg-white p-2">
              <button
                class="rounded bg-blue-500 px-2 py-1 text-white"
                @click="$emit('view-details', lead)"
              >
                View
              </button>
              <button
                class="ml-2 rounded bg-green-500 px-2 py-1 text-white"
                @click="handleMessageClick(lead)"
              >
                Message
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.bg-blue-100 {
  background-color: #dbeafe;
}
</style>
