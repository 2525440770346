<script setup lang="ts">
import { computed } from 'vue';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { Check, ChevronDown } from 'lucide-vue-next';

type Option = {
  id: number | string,
  name: string,
};

const props = withDefaults(defineProps<{
  name: string,
  options: Option[],
  by?: keyof Option,
}>(), {
  by: 'id',
});

const modelValue = defineModel<Option[typeof props.by]>();

const selectedOption = computed(() =>
  props.options.find((option) => option[props.by] === modelValue.value),
);

const displayValue = computed(() => selectedOption.value?.name || 'Seleccionar');
</script>

<template>
  <Listbox
    v-model="modelValue"
    :name="props.name"
    as="div"
    class="relative"
  >
    <div class="rounded border border-slate-200 bg-white ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-slate-950 focus-within:ring-offset-2">
      <ListboxButton class="flex w-full items-center justify-between gap-2 px-3 py-2">
        <span class="text-left">
          {{ displayValue }}
        </span>
        <ChevronDown class="size-4" />
      </ListboxButton>
    </div>
    <ListboxOptions
      class="absolute z-50 mt-1 flex max-h-80 w-full flex-col gap-1 overflow-auto rounded border border-gray-300 bg-white p-2 text-left text-xs shadow focus:outline-none"
    >
      <ListboxOption
        v-for="option in props.options"
        v-slot="{ active, selected }"
        :key="option.id"
        :value="props.by === 'id' ? option.id : option.name"
      >
        <div
          class="flex cursor-pointer items-center gap-2 rounded px-2 py-3"
          :class="{'bg-gray-100': active}"
        >
          <Check
            v-if="selected"
            class="size-4"
          />
          <div
            v-else
            class="size-4"
          />
          <span>
            {{ option.name }}
          </span>
        </div>
      </ListboxOption>
    </ListboxOptions>
  </Listbox>
</template>
