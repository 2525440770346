<script setup lang="ts">
import { add, format } from 'date-fns';
import { minutesInHour } from 'date-fns/constants';
import { ScheduleXCalendar } from '@schedule-x/vue';
import {
  createCalendar,
  viewDay,
  viewWeek,
  viewMonthGrid,
  viewMonthAgenda,
} from '@schedule-x/calendar';
import { createCurrentTimePlugin } from '@schedule-x/current-time';
import '@schedule-x/theme-default/dist/index.css';
import { computed, watch } from 'vue';
import type { Routine } from '@/types';

const props = defineProps<{
  routines: Routine[],
}>();

type Event = {
  id: number;
  title: string;
  start: string;
  end: string;
  isPublished: boolean;
  isCompleted: boolean;
};

const events = computed<Event[]>(() => props.routines.map((routine): Event => ({
  id: routine.id,
  title: `${routine.name}`,
  start: format(routine.scheduledAt, 'yyyy-MM-dd HH:mm'),
  end: format(add(new Date(routine.scheduledAt), { minutes: minutesInHour }), 'yyyy-MM-dd HH:mm'),
  isPublished: routine.isPublished,
  isCompleted: routine.isCompleted,
})));

const calendarApp = createCalendar({
  plugins: [createCurrentTimePlugin()],
  views: [viewDay, viewWeek, viewMonthGrid, viewMonthAgenda],
  defaultView: viewMonthGrid.name,
  events: events.value,
  dayBoundaries: {
    start: '06:00',
    end: '22:00',
  },
  weekOptions: {
    gridHeight: 800,
  },
});

watch(events, () => {
  calendarApp.events.set(events.value);
});
</script>

<template>
  <div class="flex flex-col gap-y-2">
    <ScheduleXCalendar
      :calendar-app="calendarApp"
    >
      <template #timeGridEvent="{ calendarEvent }">
        <div
          class="w-full cursor-pointer border-l-2 p-2"
          :class="calendarEvent.isPublished ? 'border-l-green-800 bg-green-200' : 'border-l-gray-800 bg-gray-200'"
        >
          {{ calendarEvent.title }}
        </div>
      </template>
      <template #monthGridEvent="{ calendarEvent }">
        <div
          class="flex w-full cursor-pointer flex-col border-l-2 p-2"
          :class="{
            'border-l-green-800 bg-green-200': calendarEvent.isCompleted && calendarEvent.isPublished,
            'border-l-yellow-600 bg-yellow-100': !calendarEvent.isCompleted && calendarEvent.isPublished,
            'border-l-gray-700 bg-gray-100': !calendarEvent.isPublished,
          }"
        >
          <span>{{ calendarEvent.title }}</span>
        </div>
      </template>
    </ScheduleXCalendar>
  </div>
</template>
