<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';
import { exercisesApi } from '@/api/exercises';
import ExerciseVideo from '@/components/exercise-video.vue';

const props = defineProps<{
  exerciseId: number;
}>();

const { data: exercise } = useQuery({
  queryKey: ['exercise', props.exerciseId],
  queryFn: () => exercisesApi.get(props.exerciseId),
  staleTime: Infinity,
  retry: false,
});

</script>

<template>
  <ExerciseVideo
    v-if="exercise"
    :exercise="exercise"
  />
</template>
