<script setup lang="ts">
import { computed, ref, toRef } from 'vue';
import { format, differenceInMinutes } from 'date-fns';
import { es } from 'date-fns/locale';
import { TabGroup, TabList, Tab } from '@headlessui/vue';
import { useQuery } from '@tanstack/vue-query';
import type { Trainee } from '@/types';
import type { Routine, Workout } from '@/types/extended';
import { exerciseExecutionsApi } from '@/api/exercise-executions';
import WorkoutDetailsExercise from './workout-details-exercise.vue';
import WorkoutCompare from './workout-compare.vue';

const props = defineProps<{
  routine?: Routine;
  workout: Workout;
  trainee: Trainee;
}>();

const workout = toRef(props.workout);

const formattedStartDate = computed(() => {
  if (workout.value.startedAt) {
    return format(new Date(workout.value.startedAt), "d 'de' MMMM 'a las' HH:mm", { locale: es });
  }

  return 'Fecha no disponible';
});

const workoutDuration = computed(() => {
  if (workout.value.startedAt && workout.value.endedAt) {
    const durationInMinutes = differenceInMinutes(new Date(workout.value.endedAt), new Date(workout.value.startedAt));

    return `${durationInMinutes} minutos`;
  }

  return '-';
});

const tabs = ref(['Series', 'Ejecuciones']);
const selectedTab = ref(tabs.value[0]);

const exerciseIds = computed(() => {
  if (props.routine) {
    return props.routine.routinePhases.flatMap(phase =>
      phase.routinePhaseExercises.map(exercise => exercise.exerciseId),
    );
  }

  return [];
});

const { data: exerciseExecutions, refetch: refetchExerciseExecutions } = useQuery({
  queryKey: ['exerciseExecutions', workout.value.traineeId],
  queryFn: () => exerciseExecutionsApi.getAll({ traineeId: workout.value.traineeId, exerciseIds: exerciseIds.value }),
  enabled: false,
});

function handleTabChange(index: number) {
  selectedTab.value = tabs.value[index];
  if (selectedTab.value === 'Ejecuciones') {
    refetchExerciseExecutions();
  }
}

function getExerciseExecutions(exerciseId: number) {
  return exerciseExecutions.value?.filter(exerciseExecution => exerciseExecution.exerciseId === exerciseId);
}

</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center justify-between">
      <h2 class="text-2xl font-bold">
        {{ trainee.fullName }} - {{ workout.name }}
      </h2>
    </div>

    <div>
      <div class="mb-4 space-y-2">
        <p><strong>Inicio:</strong> {{ formattedStartDate }}</p>
        <p><strong>Duración:</strong> {{ workoutDuration }}</p>
        <p><strong>RPE:</strong> {{ workout.perceivedExertion ? `${workout.perceivedExertion}/10` : '-' }}</p>
        <p><strong>Comentario:</strong> {{ workout.comment || '-' }}</p>
      </div>

      <template v-if="routine">
        <div class="flex gap-4">
          <div class="w-3/4 rounded-lg bg-gray-100">
            <TabGroup @change="handleTabChange">
              <TabList class="flex h-12 items-center gap-1 rounded-md bg-gray-100 p-1">
                <Tab
                  v-for="tab in tabs"
                  :key="tab"
                  v-slot="{ selected }"
                  as="template"
                >
                  <button
                    class="w-full rounded-md px-4 py-2 text-sm font-medium transition-colors"
                    :class="selected ? 'bg-white shadow' : 'text-gray-700 hover:bg-gray-200'"
                  >
                    {{ tab }}
                  </button>
                </Tab>
              </TabList>
            </TabGroup>
            <div
              v-for="phase in routine.routinePhases"
              :key="phase.id"
              class="mb-6 rounded-lg border bg-white p-6"
            >
              <h4 class="mb-2 text-xl font-semibold">
                {{ phase.name }}
              </h4>
              <WorkoutDetailsExercise
                v-for="exercise in phase.routinePhaseExercises"
                :key="exercise.id"
                :routine-phase-exercise="exercise"
                :workout="workout"
                :phase-id="phase.id"
                :selected-tab="selectedTab"
                :exercise-executions="getExerciseExecutions(exercise.exerciseId)"
              />
            </div>
          </div>
          <WorkoutCompare
            :current-workout="workout"
            :trainee="trainee"
            :workout-plan-id="routine.workoutPlanId"
            class="w-1/4"
          />
        </div>
      </template>
    </div>
  </div>
</template>
