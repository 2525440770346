import { ref, computed, type Ref, type UnwrapRef } from 'vue';

interface DraggableItem {
  position: number;
  [key: string]: unknown;
}

export function useDraggableList<T extends DraggableItem>({
  items,
  onUpdate,
}: {
  items: Ref<T[]>,
  onUpdate: (newItems: T[]) => void,
}) {
  const draggedItem = ref<T | null>(null);
  const draggedIndex = ref<number | null>(null);

  const sortedItems = computed(() => items.value);

  function onDragStart(event: DragEvent, item: T, index: number) {
    if (event.dataTransfer) {
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('text/plain', index.toString());
    }
    draggedItem.value = item as UnwrapRef<T>;
    draggedIndex.value = index;
  }

  function onDragOver(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'move';
    }
  }

  function onDragEnter(event: DragEvent) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    target.classList.add('bg-gray-100');
  }

  function onDragLeave(event: DragEvent) {
    const target = event.target as HTMLElement;
    target.classList.remove('bg-gray-100');
  }

  function reorderItems(targetIndex: number): T[] {
    const newItems = [...items.value];
    newItems.splice(draggedIndex.value!, 1);
    newItems.splice(targetIndex, 0, draggedItem.value as T);

    return newItems.map((item, index) => ({ ...item, position: index + 1 }));
  }

  function onDrop(event: DragEvent, targetIndex: number) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    target.classList.remove('bg-gray-100');
    if (draggedIndex.value !== null && draggedItem.value) {
      const updatedItems = reorderItems(targetIndex);
      items.value = updatedItems;
      onUpdate(updatedItems);
    }

    draggedItem.value = null;
    draggedIndex.value = null;
  }

  return {
    sortedItems,
    onDragStart,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDrop,
  };
}
