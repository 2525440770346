<script setup lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import OpenAI from 'openai';
import { Info, Search, Trash, WandSparkles, Play } from 'lucide-vue-next';
import { useQuery } from '@tanstack/vue-query';
import { exercisesApi } from '@/api/exercises';
import type { RoutinePhase, RoutinePhaseExercise } from '@/types/extended';
import { useRoutinesStore } from '@/stores/routines';
import type { RoutineExerciseSet } from '@/types';
import { Combobox } from '@/components/ui/combobox';
import { analytics } from '@/utils/analytics';
import BaseButton from './base-button.vue';
import ExerciseDetails from './exercise-details.vue';
import ExercisePicker from './exercise-picker.vue';
import AudioRecorder from './audio-recorder.vue';
import BaseTextarea from './base-textarea.vue';

const store = useRoutinesStore();

const props = defineProps<{
  phase: RoutinePhase,
  editMode: boolean,
}>();

const { data: exercises } = useQuery({
  queryKey: ['exercises'],
  queryFn: () => exercisesApi.getAll(),
  initialData: [],
});

const { openaiApiKey, traineesIds } = storeToRefs(store);

const openai = new OpenAI({
  apiKey: openaiApiKey.value,
  dangerouslyAllowBrowser: true,
});

type ExpandedExercise = RoutinePhaseExercise & {
  routinePhaseExerciseId: number,
  routineExerciseSetId: number | null,
  setNumber: number,
  repetitions?: number,
  weight?: number,
  comment: string | undefined,
  nonBlankCommentsCount: number,
  audio: Blob | null,
  audioUrl: string | null,
};

const selectedExerciseForDetails = ref<ExpandedExercise | null>(null);
const selectedExerciseForReplacement = ref<RoutinePhaseExercise | null>(null);
const showExercisePicker = ref(false);
const exercisePickerMode = ref<'single' | 'multiple'>('multiple');

function findRoutineExerciseSet(
  exerciseSets: RoutineExerciseSet[], setIndex: number,
): RoutineExerciseSet | undefined {
  return exerciseSets.find(set => set.setNumber === setIndex + 1);
}

const expandedExercises = computed(() => {
  const { routinePhaseExercises, sets } = props.phase;
  if (routinePhaseExercises.length === 0) return [];

  return Array.from({ length: sets }).map((_, setIndex) =>
    routinePhaseExercises.map((exercise) => {
      const routineExerciseSet = findRoutineExerciseSet(exercise.routineExerciseSets, setIndex);

      return {
        ...exercise,
        routinePhaseExerciseId: exercise.id,
        routineExerciseSetId: routineExerciseSet?.id,
        setNumber: setIndex + 1,
        position: exercise.position,
        repetitions: routineExerciseSet?.repetitions || undefined,
        weight: routineExerciseSet?.weight || undefined,
        comment: routineExerciseSet?.comment || undefined,
        audio: routineExerciseSet?.audio,
        audioUrl: routineExerciseSet?.audioUrl,
      } as ExpandedExercise;
    }),
  );
});

function exerciseCommentsCount(exercise: RoutinePhaseExercise) {
  const count = exercise.routineExerciseSets.filter((set) => set.comment !== '').length;

  if (count === 0) return 'Sin comentarios';

  return `${count} comentario${count > 1 ? 's' : ''}`;
}

const expandedView = ref(false);

const visibleExercises = computed<ExpandedExercise[][]>(
  () => (expandedView.value ? expandedExercises.value : [props.phase.routinePhaseExercises]) as ExpandedExercise[][],
);

function updateSet(set: ExpandedExercise) {
  if (set.routineExerciseSetId === null || set.comment === undefined) return;

  store.updateSet({
    routineId: props.phase.routineId,
    phasePosition: props.phase.position,
    routinePhaseExerciseId: set.routinePhaseExerciseId,
    routineExerciseSetId: set.routineExerciseSetId || -1,
    routineExerciseSet: {
      id: set.routineExerciseSetId,
      repetitions: set.repetitions,
      weight: set.weight,
      comment: set.comment,
      routinePhaseExerciseId: set.routinePhaseExerciseId,
      setNumber: set.setNumber,
      audio: set.audio,
      audioUrl: set.audioUrl,
    },
  });
}

function updateRoutinePhaseExercise(routinePhaseExercise: RoutinePhaseExercise, newExerciseId?: number | string) {
  if (newExerciseId !== undefined) {
    routinePhaseExercise.exerciseId = Number(newExerciseId);
  }

  store.updateRoutinePhaseExercise({
    routineId: props.phase.routineId,
    phasePosition: props.phase.position,
    routinePhaseExercisePosition: routinePhaseExercise.position,
    routinePhaseExercise: {
      ...routinePhaseExercise,
      duration: routinePhaseExercise.durationInSeconds,
      rest: routinePhaseExercise.restInSeconds,
    },
  });
}

function updateRoutinePhase(event: Event, keyName: string) {
  const value = (event.target as HTMLInputElement).value;

  store.updateRoutinePhase({
    routineId: props.phase.routineId,
    phasePosition: props.phase.position,
    phase: { ...props.phase, [keyName]: value },
  });
}

function deleteRoutinePhase(phase: RoutinePhase) {
  store.deleteRoutinePhase({
    routineId: phase.routineId,
    phasePosition: phase.position,
  });
}

function deleteRoutinePhaseExercise(exercise: RoutinePhaseExercise) {
  store.deleteRoutinePhaseExercise({
    routineId: props.phase.routineId,
    phasePosition: props.phase.position || -1,
    routinePhaseExercisePosition: exercise.position,
  });
}

function openExercisePicker(mode: 'single' | 'multiple') {
  exercisePickerMode.value = mode;
  showExercisePicker.value = true;
}

function closeExercisePicker() {
  showExercisePicker.value = false;
}

function handleExerciseAddition(exerciseIds: number[]) {
  if (exercisePickerMode.value === 'single') {
    if (selectedExerciseForReplacement.value && exerciseIds.length > 0) {
      updateRoutinePhaseExercise(selectedExerciseForReplacement.value, exerciseIds[0]);
      selectedExerciseForReplacement.value = null;
    }
  } else {
    exerciseIds.forEach((exerciseId) => {
      store.addEmptyRoutinePhaseExercise({
        routineId: props.phase.routineId,
        phasePosition: props.phase.position,
      });
      const lastExercise = props.phase.routinePhaseExercises[props.phase.routinePhaseExercises.length - 1];
      updateRoutinePhaseExercise(lastExercise, exerciseId);
    });
  }
  closeExercisePicker();
}

function openSelectExerciseForReplacement(exercise: ExpandedExercise) {
  selectedExerciseForReplacement.value = exercise;
  openExercisePicker('single');
  if (analytics) {
    analytics.capture('open_search_exercise_click');
  }
}

function handlePhaseAudioChange(audio: Blob) {
  const file = new File([audio], 'audio.wav', { type: 'audio/wav' });

  store.updateRoutinePhase({
    routineId: props.phase.routineId,
    phasePosition: props.phase.position,
    phase: { ...props.phase, audio: file },
  });
}

async function generateComment(exercise: ExpandedExercise) {
  if (analytics) {
    analytics.capture('generate_comment_click', {
      exerciseId: exercise.exerciseId,
      exerciseName: exercise.exerciseName,
      exerciseKind: exercise.kind,
      exerciseDuration: exercise.durationInSeconds,
      previousComment: exercise.comment,
    });
  }

  const exerciseData = `${exercise.position}. ${exercise.exerciseName} (Ejercicio de ${exercise.kind}). ` +
    `Duración estimada: ${exercise.durationInSeconds} segs. ` +
    `Descanso: ${exercise.restInSeconds} segs.`;

  const otherExercisesComments = expandedExercises.value
    .flat()
    .filter((ex) => ex.routinePhaseExerciseId !== exercise.routinePhaseExerciseId)
    .map((ex) => {
      const setData = `Peso: ${ex.weight ? ex.weight : 'N/A'} kilos ` +
        `- ${ex.repetitions ? ex.repetitions : 'N/A'} reps. ` +
        `${ex.exerciseName} (set ${ex.setNumber}). Comentario entrenador: ${ex.comment}.`;

      return setData;
    })
    .join('; ');

  const systemMessage = 'Eres kinesiólogo.' +
    'Tu rol es completar un input en un formulario en una página web en base a la data entregada por el usuario.' +
    'El input a completar es un comentario de un personal trainer a su cliente.' +
    'El cliente leerá ese comentario justo antes de ejecutar ese ejercicio. ' +
    'No saludes al principio. Se sobrio. No agregues mensajes motivacionales ni entusiastas al final.' +
    'Usa un estilo de comentario similar al de los comentarios de los otros ejercicios, si es que hay.';

  const prompt = `Data de este ejercicio: ${exerciseData}. ` +
    `Otros ejercicios de esta fase de la rutina: ${otherExercisesComments}. ` +
    'Comentario:';
  const message = await openai.chat.completions.create({
    messages: [
      { role: 'system', content: systemMessage },
      { role: 'user', content: prompt },
    ],
    model: 'gpt-4o-mini',
  });

  updateSet({ ...exercise, comment: message.choices[0].message.content || undefined });
}

function getPhaseAudioUrl(phase: RoutinePhase) {
  if (phase.audio) {
    return URL.createObjectURL(phase.audio);
  }

  return phase.audioUrl;
}

function handleFileInputChange(event: Event, keyName: string) {
  const target = event.target as HTMLInputElement;
  store.updateRoutinePhase({
    routineId: props.phase.routineId,
    phasePosition: props.phase.position,
    phase: { ...props.phase, [keyName]: target.files?.[0] },
  });
}

function handleExerciseAudioChange(exercise: ExpandedExercise, audio: Blob) {
  const file = new File([audio], 'audio.wav', { type: 'audio/wav' });

  updateSet({
    ...exercise,
    audio: file,
  });
}

function getSetAudioUrl(set: ExpandedExercise) {
  if (set.audio) {
    return URL.createObjectURL(set.audio);
  }

  return set.audioUrl || '';
}

function playAudio(audioUrl: string) {
  const audio = new Audio(audioUrl);
  audio.play();
}

</script>
<template>
  <div>
    <div
      class="flex w-full items-center gap-2 py-2 text-left transition-all"
    >
      <h2 class="text-sm font-semibold">
        <template v-if="editMode">
          <div class="flex flex-col gap-y-2">
            <div class="flex items-center gap-x-1">
              <input
                :value="props.phase.position"
                class="w-5 bg-gray-100 p-1"
                @input="event => updateRoutinePhase(event, 'position')"
              >
              <input
                class="w-60 border-2 bg-white p-1"
                :value="props.phase.name"
                @input="event => updateRoutinePhase(event, 'name')"
              >
              <div>
                (
                <input
                  class="w-8 border-2 bg-white p-1"
                  :value="props.phase.sets"
                  @input="event => updateRoutinePhase(event, 'sets')"
                >
                sets)
              </div>
              <span v-if="props.phase._destroy">
                ❌ Se eliminará
              </span>
              <BaseButton
                v-else
                variant="outline"
                size="sm"
                @click="deleteRoutinePhase(props.phase)"
              >
                <Trash class="size-4" /> Eliminar
              </BaseButton>
            </div>
            <div class="ml-2 flex items-center gap-x-2 border bg-white p-1">
              <audio
                v-if="getPhaseAudioUrl(phase)"
                :src="getPhaseAudioUrl(phase)"
                controls
              />
              <audio-recorder
                @update="handlePhaseAudioChange"
              />
              <input
                type="file"
                accept="audio/*"
                @change="event => handleFileInputChange(event, 'audio')"
              >
            </div>
          </div>
        </template>
        <template v-else>
          {{ props.phase.position }}. {{ props.phase.name }} ({{ props.phase.sets }} sets)
        </template>
      </h2>
      <BaseButton
        class="ml-auto"
        size="sm"
        variant="outline"
        @click="expandedView = !expandedView;"
      >
        {{ expandedView ? '💬 Ocultar series' : '💬 Ver series' }}
      </BaseButton>
    </div>
    <ExerciseDetails
      v-if="selectedExerciseForDetails"
      :exercise-id="selectedExerciseForDetails.exerciseId"
      :trainee-id="traineesIds[0]"
      @close="selectedExerciseForDetails = null"
    />
    <ExercisePicker
      v-if="showExercisePicker"
      :mode="exercisePickerMode"
      @close="closeExercisePicker"
      @select="handleExerciseAddition"
    />
    <div class="ml-2 flex flex-col rounded-lg border bg-white p-2">
      <div
        class="col-span-full mb-2 grid items-center gap-2 text-xs"
        :class="{
          'grid-cols-12': expandedView,
          'grid-cols-8': !expandedView && editMode,
          'grid-cols-7': !expandedView && !editMode,
        }"
      >
        <div class="col-span-3 flex items-center px-1 font-medium text-gray-500">
          Ejercicio
        </div>
        <div class="col-span-1 px-1 font-medium text-gray-500">
          Tipo
        </div>
        <div class="col-span-1 px-1 font-medium text-gray-500">
          Duración
        </div>
        <div class="col-span-1 px-1 font-medium text-gray-500">
          Descanso
        </div>
        <div
          :class="[expandedView ? 'col-span-5 grid grid-cols-5 items-center' : 'col-span-1']"
        >
          <div
            v-if="expandedView"
            class="px-1 font-medium text-gray-500"
          >
            Peso
          </div>
          <div
            v-if="expandedView"
            class="px-1 font-medium text-gray-500"
          >
            Reps
          </div>
          <div
            class="col-span-3 px-1 font-medium text-gray-500"
          >
            Comentarios
          </div>
        </div>
        <div
          v-if="editMode"
          class="col-span-1 flex gap-x-1 px-1 font-medium text-gray-500"
        >
          Acciones
        </div>
        <template
          v-for="(exerciseSet, setNumber) in visibleExercises"
          :key="setNumber"
        >
          <div
            v-if="expandedView && visibleExercises.length > 1"
            class="mt-1 font-bold"
            :class="{
              'col-span-11': expandedView && editMode,
              'col-span-10': expandedView && !editMode,
              'col-span-7': !expandedView && editMode,
              'col-span-6': !expandedView && !editMode,
            }"
          >
            Set {{ setNumber + 1 }}
          </div>
          <template
            v-for="exercise in exerciseSet"
            :key="exercise.id"
          >
            <div class="col-span-3 flex w-full items-center gap-2 px-1 font-medium">
              <template v-if="editMode">
                <input
                  v-if="!expandedView"
                  v-model="exercise.position"
                  class="w-5 bg-gray-100 p-1"
                  @change="updateRoutinePhaseExercise(exercise)"
                >
                <span v-else>
                  {{ exercise.position }}.
                </span>
                <Combobox
                  :key="exercise.exerciseId"
                  class="w-full"
                  :model-value="exercise.exerciseId"
                  name="exerciseId"
                  :options="exercises"
                  @update:model-value="event => updateRoutinePhaseExercise(exercise, event)"
                />
              </template>
              <template v-else>
                <p>
                  {{ exercise.position }}.
                  {{ exercise.exerciseName }}
                </p>
              </template>
              <BaseButton
                size="icon"
                variant="outline"
                class="ml-auto shrink-0"
                @click="selectedExerciseForDetails = exercise"
              >
                <Info class="size-4" />
              </BaseButton>
              <BaseButton
                v-if="editMode"
                size="icon"
                variant="outline"
                class="shrink-0"
                @click="openSelectExerciseForReplacement(exercise)"
              >
                <Search class="size-4" />
              </BaseButton>
            </div>
            <div class="px-1">
              <select
                v-if="editMode"
                v-model="exercise.kind"
                class="w-20 bg-gray-100 p-1"
                @change="updateRoutinePhaseExercise(exercise)"
              >
                <option
                  disabled
                  value=""
                >
                  Seleccionar
                </option>
                <option>time</option>
                <option>repetition</option>
              </select>
              <p v-else>
                {{ exercise.kind }}
              </p>
            </div>
            <div class="px-1">
              <input
                v-if="editMode"
                v-model="exercise.durationInSeconds"
                class="w-20 bg-gray-100 p-1"
                type="number"
                @change="updateRoutinePhaseExercise(exercise)"
              >
              <p v-else>
                {{ exercise.durationInSeconds }} segs
              </p>
            </div>
            <div class="px-1">
              <input
                v-if="editMode"
                v-model="exercise.restInSeconds"
                class="w-20 bg-gray-100 p-1"
                type="number"
                @change="updateRoutinePhaseExercise(exercise)"
              >
              <p v-else>
                {{ exercise.restInSeconds }} segs
              </p>
            </div>
            <div
              v-if="expandedView"
              class="pl-1"
              :class="[expandedView ? 'col-span-5 grid grid-cols-5 items-center gap-2' : 'col-span-1']"
            >
              <div>
                <input
                  v-if="editMode"
                  v-model="exercise.weight"
                  class="w-full bg-gray-100 p-1"
                  type="number"
                  @change="updateSet(exercise)"
                >
                <div v-else>
                  <span v-if="exercise.weight">
                    {{ exercise.weight }} kilos
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
              <div>
                <input
                  v-if="editMode"
                  v-model="exercise.repetitions"
                  class="w-full bg-gray-100 p-1"
                  type="number"
                  @change="updateSet(exercise)"
                >
                <div v-else>
                  <span v-if="exercise.repetitions">
                    {{ exercise.repetitions }} reps
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
              <div
                v-if="editMode"
                class="col-span-3 flex items-center gap-1"
              >
                <BaseTextarea
                  v-model="exercise.comment"
                  class="w-full"
                  name="comment"
                  text-size="sm"
                  @change="updateSet(exercise)"
                />
                <BaseButton
                  variant="outline"
                  size="icon"
                  @click="generateComment(exercise)"
                >
                  <WandSparkles class="size-4" />
                </BaseButton>
              </div>
              <div
                v-else
                class="col-span-3"
              >
                <span v-if="exercise.comment">
                  {{ exercise.comment }}
                </span>
                <span v-else>-</span>
              </div>
            </div>
            <div v-else>
              <span>
                {{ exerciseCommentsCount(exercise) }}
              </span>
            </div>
            <div
              v-if="expandedView && !editMode"
              class="px-1"
            >
              <play
                v-if="getSetAudioUrl(exercise)"
                class="size-5"
                @click="playAudio(getSetAudioUrl(exercise))"
              />
            </div>
            <div
              v-if="editMode"
              class="flex items-center gap-x-1 px-1"
            >
              <span v-if="exercise._destroy">
                ❌ Se eliminará
              </span>
              <BaseButton
                v-else
                variant="secondary"
                size="icon"
                @click="deleteRoutinePhaseExercise(exercise)"
              >
                <Trash class="size-4" />
              </BaseButton>
              <template
                v-if="editMode && expandedView"
              >
                <audio-recorder
                  @update="audio => handleExerciseAudioChange(exercise, audio)"
                />
                <template v-if="getSetAudioUrl(exercise)">
                  <play
                    class="size-5"
                    @click="playAudio(getSetAudioUrl(exercise))"
                  />
                  <audio
                    :src="getSetAudioUrl(exercise)"
                    class="hidden"
                  />
                </template>
              </template>
            </div>
          </template>
        </template>
      </div>
      <div
        v-if="props.editMode"
        class="mt-2"
      >
        <BaseButton
          variant="secondary"
          size="sm"
          @click="openExercisePicker('multiple')"
        >
          Agregar ejercicios
        </BaseButton>
      </div>
    </div>
  </div>
</template>
