<script setup lang="ts">
import { ref } from 'vue';
import { BaseButton, BaseModal } from '@/components';
import { routinesApi } from '@/api/routines';
import type { Routine } from '@/types/extended';
import { useMutation } from '@tanstack/vue-query';

const props = defineProps<{
  isOpen: boolean;
  routines: Routine[];
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'published'): void;
}>();

const errorMessage = ref('');

const { isError, isPending, isSuccess, mutate } = useMutation({
  mutationFn: async () => Promise.all(
    props.routines.map(routine =>
      routinesApi.update(routine.id, {
        id: routine.id,
        published: true,
      }),
    ),
  ),
  onSuccess: () => {
    emit('published');
  },
  onError: (error) => {
    errorMessage.value = `Ocurrió un error al publicar las rutinas: ${error.message}`;
  },
});

function publishAllRoutines() {
  errorMessage.value = '';
  mutate();
}
</script>

<template>
  <BaseModal
    :open="isOpen"
    title="Publicar todas las rutinas"
    @close="emit('close')"
  >
    <template #default="{ close }">
      <p v-if="!isPending && !isSuccess">
        ¿Estás seguro de que quieres publicar todas las rutinas de esta semana?
      </p>
      <p v-if="isPending">
        Publicando rutinas...
      </p>
      <p v-if="isSuccess">
        ¡Rutinas publicadas exitosamente!
      </p>
      <p v-if="isError">
        {{ errorMessage }}
      </p>
      <div class="mt-6 flex justify-end gap-4">
        <BaseButton
          v-if="!isPending && !isSuccess"
          variant="secondary"
          @click="close"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          v-if="!isPending && !isSuccess"
          @click="publishAllRoutines"
        >
          Confirmar
        </BaseButton>
        <BaseButton
          v-if="isSuccess || isError"
          variant="secondary"
          @click="close"
        >
          Cerrar
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>
