<script setup lang="ts">
import { ref, watch, computed } from 'vue';

const props = defineProps<{
  options: string[];
  selectedValues: string[];
}>();

const emit = defineEmits(['update:selected']);

const isOpen = ref(false);
const search = ref('');
const selectedOptions = ref<Set<string>>(new Set());

watch(() => props.options, (newOptions) => {
  selectedOptions.value = new Set(newOptions);
  emit('update:selected', Array.from(selectedOptions.value));
}, { immediate: true });

watch(() => props.selectedValues, (newValues) => {
  selectedOptions.value = new Set(newValues);
}, { immediate: true });

const filteredOptions = computed(() => props.options.filter(option =>
  option.toLowerCase().includes(search.value.toLowerCase()),
));

function toggleOption(option: string) {
  if (selectedOptions.value.has(option)) {
    selectedOptions.value.delete(option);
  } else {
    selectedOptions.value.add(option);
  }
  emit('update:selected', Array.from(selectedOptions.value));
}

function toggleDropdown() {
  isOpen.value = !isOpen.value;
}

function selectAll() {
  selectedOptions.value = new Set(props.options);
  emit('update:selected', Array.from(selectedOptions.value));
}

function clearAll() {
  selectedOptions.value.clear();
  emit('update:selected', []);
}

const buttonText = computed(() => {
  if (selectedOptions.value.size === props.options.length) {
    return 'All';
  } else if (selectedOptions.value.size === 0) {
    return 'None';
  }

  return `${selectedOptions.value.size} s`;
});
</script>

<template>
  <div class="relative inline-block text-left">
    <div>
      <button
        type="button"
        class="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
        @click="toggleDropdown"
      >
        {{ buttonText }}
        <svg
          v-if="!isOpen"
          class="-mr-1 ml-2 size-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>

    <div
      v-if="isOpen"
      class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
    >
      <div
        class="py-1"
        role="none"
      >
        <input
          v-model="search"
          type="text"
          class="block w-full px-4 py-2 text-sm text-gray-700"
          placeholder="Search..."
        >
      </div>
      <div
        class="py-1"
        role="none"
      >
        <button
          class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          @click="selectAll"
        >
          Select All
        </button>
        <button
          class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
          @click="clearAll"
        >
          Clear
        </button>
      </div>
      <div
        class="max-h-60 overflow-y-auto py-1"
        role="none"
      >
        <label
          v-for="option in filteredOptions"
          :key="option"
          class="flex cursor-pointer items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        >
          <input
            type="checkbox"
            :checked="selectedOptions.has(option)"
            class="mr-2"
            @change="toggleOption(option)"
          >
          {{ option }}
        </label>
      </div>
    </div>
  </div>
</template>
