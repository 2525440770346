<script setup lang="ts">
import { useAttrs, computed, ref, toRef, watch } from 'vue';
import { useField } from 'vee-validate';
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/vue';
import { Label } from '@/components/ui/label';

function useAttrsWithoutClass() {
  const attrs = useAttrs();

  return Object.fromEntries(Object.entries(attrs).filter(([key, _]) => key !== 'class'));
}

declare interface SelectOption {
  name: string
  id: number | string
}

interface Props {
  modelValue?: string | number
  options: SelectOption[]
  label?: string
  placeholder?: string
  name: string
  required?: boolean
  showAddNewButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  placeholder: 'Buscar',
  label: undefined,
  showAddNewButton: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
  (e: 'new'): void
}>();

const name = toRef(props, 'name');

const {
  meta,
  value: inputValue,
  errorMessage,
} = useField(name, undefined, {
  initialValue: props.modelValue,
});

const query = ref('');

const filteredOptions = computed(() => {
  const MAX_OPTIONS_TO_SHOW = 50;

  if (query.value === '') return props.options.slice(0, MAX_OPTIONS_TO_SHOW);

  return props.options.filter((option) =>
    option.name
      .toLowerCase()
      .replace(/\s+/g, '')
      .includes(query.value.toLowerCase().replace(/\s+/g, '')),
  ).slice(0, MAX_OPTIONS_TO_SHOW);
});

const selectedOption = computed(() => props.options.find(option => option.id === inputValue.value));

const displayValue = computed(() => selectedOption.value?.name || query.value);

const noResults = computed(() => filteredOptions.value.length === 0 && query.value !== '');

const disabledClasses = 'bg-gray-100 text-gray-500';
const enabledClasses = 'bg-white focus-within:border-transparent outline-2 outline-sg-orange focus-within:outline';

const attrsWithoutClass = useAttrsWithoutClass();
const disabled = computed(() => !!attrsWithoutClass.disabled);

watch(inputValue, (value) => {
  emit('update:modelValue', value);
});

const downArrowUrl = new URL('../../../../assets/images/down-arrow.svg', import.meta.url).href;

</script>

<template>
  <div :class="$attrs.class">
    <Label
      v-if="label"
      :label="label"
      :name="name"
      :required="required"
    />
    <Combobox
      v-model="inputValue"
      :name="name"
      :disabled="disabled"
    >
      <div class="relative">
        <div
          class="flex w-full rounded border border-gray-300 px-2 py-1 text-left text-xs text-gray-600"
          :class="disabled ? disabledClasses : enabledClasses"
        >
          <ComboboxInput
            v-bind="attrsWithoutClass"
            class="w-full outline-none disabled:bg-gray-100 disabled:text-gray-500"
            :display-value="() => displayValue"
            :placeholder="placeholder"
            autocomplete="off"
            @change="query = $event.target.value"
          />
          <ComboboxButton
            class="px-2"
            :class="{ [disabledClasses]: disabled }"
            :disabled="disabled"
          >
            <inline-svg
              :src="downArrowUrl"
              class="outline-none"
              height="8"
              width="8"
            />
          </ComboboxButton>
        </div>
        <ComboboxOptions
          class="absolute z-50 mt-1 max-h-80 w-full overflow-auto rounded border border-gray-300 bg-white text-left text-xs text-gray-500 shadow focus:outline-none"
        >
          <ComboboxOption
            v-for="option in filteredOptions"
            v-slot="{ active }"
            :key="option.id"
            :value="option.id"
            as="template"
          >
            <li
              class="cursor-pointer px-2 py-1 text-xs"
              :class="{'bg-gray-100': active}"
            >
              {{ option.name }}
            </li>
          </ComboboxOption>
          <div
            v-if="noResults"
            class="flex cursor-pointer justify-between px-2 py-1 hover:bg-gray-100"
          >
            <p>
              No hay resultados
            </p>
            <button
              v-if="showAddNewButton"
              class="text-sky-400"
              type="button"
              @click="emit('new')"
            >
              Agregar nuevo
            </button>
          </div>
        </ComboboxOptions>
      </div>
    </Combobox>
    <p
      v-show="errorMessage && meta.touched"
      class="text-right text-xs text-red-600 md:col-span-2"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

