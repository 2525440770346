import type { MuscleGroup } from '@/types';
import { api } from './index';

export const muscleGroupsApi = {
  async getAll() {
    const path = '/api/internal/muscle_groups';

    const response = await api({
      method: 'get',
      url: path,
    });

    return response.data as MuscleGroup[];
  },
};
