import type { TallySubmissionPayload } from '@/types';
import { api } from './index';

export const preMeetingSurveysApi = {
  create(traineeId: number, submission: Partial<TallySubmissionPayload>) {
    const path = '/api/internal/pre_meeting_surveys';

    return api({
      method: 'post',
      url: path,
      data: { pre_meeting_survey: { ...submission, trainee_id: traineeId } },
    });
  },
};
