import type { TraineeNote } from '@/types';
import { api } from './index';

export const traineeNotesApi = {
  create(traineeNote: Partial<TraineeNote>) {
    const path = '/api/internal/trainee_notes';

    return api({
      method: 'post',
      url: path,
      data: { traineeNote },
    });
  },

  async getAll({ traineeId }: {traineeId: number}) {
    const path = '/api/internal/trainee_notes';

    const response = await api({
      method: 'get',
      url: path,
      params: { q: { 'trainee_id_eq': traineeId, s: 'created_at desc' } },
    });

    return response.data as TraineeNote[];
  },
};
