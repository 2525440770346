<script setup lang="ts">
import { Button } from '@/components/ui/button';

const props = withDefaults(defineProps<{
  type?: 'button' | 'submit' | 'reset';
}>(), {
  type: 'button',
});
</script>

<template>
  <Button
    :type="props.type"
    class="flex gap-1"
  >
    <slot name="icon" />
    <slot />
  </Button>
</template>
