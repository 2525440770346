<script setup lang="ts">
import { toRefs } from 'vue';
import { useField } from 'vee-validate';

type Value = boolean | string | null | object

const props = withDefaults(defineProps<{
  name: string;
  modelValue: Value | Value[];
  value?: Value;
  uncheckedValue?: boolean | string | null;
  required?: boolean;
}>(), {
  value: true,
  uncheckedValue: false,
  required: false,
});

const emit = defineEmits<{
  'update:modelValue': [value: boolean]
}>();

const { name, required } = toRefs(props);
const rules = required.value ? 'required' : undefined;

const { handleChange, checked } = useField(name, rules, {
  type: 'checkbox',
  checkedValue: props.value,
  uncheckedValue: props.uncheckedValue,
  initialValue: props.modelValue,
  syncVModel: true,
});

function onChange(event: Event) {
  const target = event.target as HTMLInputElement;
  emit('update:modelValue', target.checked);
  handleChange(target.checked);
}
</script>

<template>
  <label class="flex w-min cursor-pointer items-center gap-2">
    <input
      :name="name"
      :value="value"
      :checked="checked"
      type="checkbox"
      class="form-checkbox size-5 rounded-md border-gray-300 bg-gray-100 text-black focus:ring-black"
      v-bind="$attrs"
      @change="onChange"
    >
  </label>
</template>
