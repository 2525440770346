<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { format, subDays } from 'date-fns';
import { daysInWeek } from 'date-fns/constants';
import { workoutsApi } from '@/api/workouts';
import type { Trainee } from '@/types';
import type { Workout } from '@/types/extended';
import { BaseButton, BaseCombobox } from '@/components';
import { Copy } from 'lucide-vue-next';
import { api } from '@/api/fetch-wrapper';

const props = defineProps<{
  currentWorkout: Workout;
  trainee: Trainee;
  workoutPlanId: number;
}>();

const selectedWorkoutId = ref<number | undefined>(undefined);
const comparisonResult = ref<string>('');
const isLoading = ref(false);
const isCopied = ref(false);

const workoutsQueryParams = ref({
  'routine_workout_plan_id_eq': props.workoutPlanId,
  'ended_at_not_null': true,
  'routine_id_not_null': true,
  s: 'ended_at desc',
});

const { data: workouts } = useQuery({
  queryKey: ['workouts', workoutsQueryParams],
  queryFn: () => workoutsApi.getAll({ filters: workoutsQueryParams.value }),
  initialData: [],
});

function findDefaultWorkout() {
  const targetDate = subDays(new Date(props.currentWorkout.endedAt as string), daysInWeek);
  const defaultWorkout = workouts.value.find(
    workout => new Date(workout.endedAt as string).toDateString() === targetDate.toDateString(),
  );
  if (defaultWorkout) {
    selectedWorkoutId.value = defaultWorkout.id;
  }
}

watch(workouts, () => {
  if (workouts.value.length > 0) {
    findDefaultWorkout();
  }
});

const { data: selectedWorkout } = useQuery({
  queryKey: ['workout', selectedWorkoutId.value],
  queryFn: () => workoutsApi.get(selectedWorkoutId.value as number),
  enabled: () => !!selectedWorkoutId.value,
});

const workoutOptions = computed(() =>
  workouts.value
    .filter((workout: Workout) => workout.routineId && workout.endedAt && workout.id !== props.currentWorkout.id)
    .map((workout: Workout) => ({
      id: workout.id,
      name: `${workout.name} - ${format(workout.endedAt as string, 'dd/MM/yyyy')}`,
    })),
);

const error = ref('');

// eslint-disable-next-line max-statements
async function processStreamResponse(reader: ReadableStreamDefaultReader<Uint8Array>) {
  const decoder = new TextDecoder();

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { value, done } = await reader.read();
    if (done) break;

    const chunk = decoder.decode(value, { stream: true });
    const lines = chunk.split('\n');

    const SLICE_AT_INDEX = 5;

    for (const line of lines) {
      if (line.startsWith('data:')) {
        const jsonString = line.slice(SLICE_AT_INDEX).trim();
        const jsonData = JSON.parse(jsonString);
        if (jsonData.content) {
          comparisonResult.value += jsonData.content;
        }
      }
    }
  }
}

// eslint-disable-next-line max-statements
async function compareWorkouts() {
  isLoading.value = true;
  comparisonResult.value = '';

  try {
    const response = await api.post({
      url: 'https://kapso-ai.fly.dev/api/workouts/compare',
      body: {
        previousWorkout: selectedWorkout.value,
        currentWorkout: props.currentWorkout,
      },
      headers: {
        'Accept': 'text/event-stream',
        'Content-Type': 'application/json',
      },
    });

    if (!(response instanceof Response)) {
      throw new Error('Unexpected response type');
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('Unable to read the response');
    }

    await processStreamResponse(reader);
  } catch (e: unknown) {
    if (e instanceof Error) {
      error.value = e.toString();
    } else {
      error.value = 'Ha ocurrido un error';
    }
  } finally {
    isLoading.value = false;
  }
}

const IS_COPIED_TIMEOUT = 2000;

function copyToClipboard() {
  navigator.clipboard.writeText(comparisonResult.value).then(() => {
    isCopied.value = true;
    setTimeout(() => {
      isCopied.value = false;
    }, IS_COPIED_TIMEOUT);
  });
}
</script>

<template>
  <div class="flex flex-col gap-2">
    <h3 class="text-lg font-semibold">
      Comparar
    </h3>
    <BaseCombobox
      v-model="selectedWorkoutId"
      name="selectedWorkoutId"
      :options="workoutOptions"
      class="text-sm"
    />
    <BaseButton
      v-if="comparisonResult"
      class="mt-2 flex w-full items-center justify-center gap-2 text-sm"
      @click="copyToClipboard"
    >
      <Copy class="size-4" />
      {{ isCopied ? 'Copiado!' : 'Copiar resultado' }}
    </BaseButton>
    <BaseButton
      v-else
      :disabled="!selectedWorkoutId || isLoading"
      class="w-full text-sm"
      @click="compareWorkouts"
    >
      {{ isLoading ? 'Comparando...' : 'Comparar' }}
    </BaseButton>
    <div
      v-if="comparisonResult"
      class="rounded border p-2"
    >
      <pre class="whitespace-pre-wrap">{{ comparisonResult }}</pre>
    </div>
  </div>
</template>
