<script setup lang="ts">
import { onMounted } from 'vue';
import { analytics } from '@/utils/analytics';
import type { TallySubmissionPayload } from '@/types/';
import { initialSurveySubmissionsApi } from '@/api/initial-survey-submissions';

const props = defineProps<{
  waitlistEnabled: boolean;
}>();

// eslint-disable-next-line max-statements
onMounted(() => {
  const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

  // eslint-disable-next-line func-style
  const load = () => {
    // Load Tally embeds
    if (typeof Tally !== 'undefined') {
      Tally.loadEmbeds();

      return;
    }

    // Fallback if window.Tally is not available
    document
      .querySelectorAll('iframe[data-tally-src]:not([src])')
      .forEach((iframeEl) => {
        iframeEl.src = iframeEl.dataset.tallySrc;
      });
  };

  // If Tally is already loaded, load the embeds
  if (typeof Tally !== 'undefined') {
    load();

    return;
  }

  // If the Tally widget script is not loaded yet, load it
  if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
    const script = document.createElement('script');
    script.src = widgetScriptSrc;
    script.onload = load;
    script.onerror = load;
    document.body.appendChild(script);

    return;
  }
});

interface PageViewPayload {
	formId: string;
	page: number;
}

window.addEventListener('message', (e) => {
  if (e?.data?.includes('Tally.FormPageView')) {
    const payload = JSON.parse(e.data).payload as PageViewPayload;
    analytics.capture('initial_survey_page_view', payload);
  } else if (e?.data?.includes('Tally.FormSubmitted')) {
    const payload = JSON.parse(e.data).payload as TallySubmissionPayload;
    const email = payload.fields.find((field) => field.title === 'Email')?.answer.value;
    initialSurveySubmissionsApi.create(payload).then(() => {
      document.cookie = `lead_email=${email}; path=/`;
      if (!props.waitlistEnabled) {
        window.location.href = '/onboarding/trainers';
      }
    });
    analytics.capture('initial_survey_form_submitted', { ...payload, email });
  }
});
</script>

<template>
  <div>
    <iframe
      data-tally-src="https://tally.so/embed/wzq1yR?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1"
      loading="lazy"
      width="100%"
      height="1200"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Kapso"
    />
  </div>
</template>
