<script setup lang="ts">
import { ref, toRefs } from 'vue';
import BaseButton from '@/components/base-button.vue';
import BaseModal from '@/components/base-modal.vue';
import type { TraineeNote } from '@/types';
import { formatDate } from '@/utils/format-date';
import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query';
import { traineeNotesApi } from '@/api/trainee-notes';

const props = defineProps<{
    traineeId: number,
  }>();

const showNewNoteModal = ref(false);
const noteContent = ref('');

const queryClient = useQueryClient();

const { traineeId } = toRefs(props);

const { mutate: createNote } = useMutation({
  mutationFn: (note: Pick<TraineeNote, 'content' | 'traineeId'>) => traineeNotesApi.create(note),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: ['traineeNotes', traineeId] });
  },
});

const { data: notes } = useQuery({
  queryKey: ['traineeNotes', traineeId],
  queryFn: () => traineeNotesApi.getAll({ traineeId: traineeId.value }),
});

function toggleNewNoteModal() {
  showNewNoteModal.value = !showNewNoteModal.value;
}

function createNoteAndCloseModal(note: Pick<TraineeNote, 'content' | 'traineeId'>) {
  createNote(note);
  noteContent.value = '';
  toggleNewNoteModal();
}

</script>

<template>
  <div class="flex flex-col gap-4">
    <div class="flex items-center justify-between">
      <h2 class="text-xl font-bold">
        Notas
      </h2>
      <base-button
        variant="secondary"
        @click="toggleNewNoteModal()"
      >
        Agregar nota
      </base-button>
      <base-modal
        title="Agregar nota"
        :open="showNewNoteModal"
        @close="toggleNewNoteModal"
      >
        <div class="flex flex-col gap-4">
          <textarea
            v-model="noteContent"
            class="form-textarea h-32 w-full rounded border border-slate-200 bg-white px-3 py-2 text-sm ring-offset-white focus-within:border-slate-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
            placeholder="Escribe tu nota aquí"
          />
          <div class="flex justify-end gap-4">
            <base-button
              variant="secondary"
              class="rounded-lg border border-black px-4 py-2"
              @click="toggleNewNoteModal"
            >
              Cancelar
            </base-button>
            <base-button
              class="rounded-lg bg-black px-4 py-2 text-white"
              @click="createNoteAndCloseModal({ content: noteContent, traineeId})"
            >
              Guardar
            </base-button>
          </div>
        </div>
      </base-modal>
    </div>
    <template v-if="notes?.length !== 0">
      <div
        v-for="note in notes"
        :key="note.id"
        class="flex flex-col gap-2 rounded-lg border border-gray-200 bg-gray-50 p-4"
      >
        <span class="text-xs text-gray-500">
          {{ formatDate(note.createdAt, {dateStyle: 'full', timeStyle: 'short'}) }}
        </span>
        <p class="whitespace-pre-line text-sm">
          {{ note.content }}
        </p>
      </div>
    </template>
    <template v-else>
      <p>
        No hay notas para mostrar.
      </p>
    </template>
  </div>
</template>
