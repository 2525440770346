/* eslint-disable no-alert */
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { secondsInMinute } from 'date-fns/constants';
import type { Routine, RoutinePhase, RoutinePhaseExercise, WorkoutPlan } from '@/types/extended';
import { routinesApi } from '@/api/routines';
import { isAxiosError } from 'axios';
import { transformToNestedAttributes } from '@/utils/transform-to-nested-attributes';
import type { RoutineAttributes, RoutineExerciseSet } from '@/types';

// eslint-disable-next-line max-statements
export const useRoutinesStore = defineStore('routines', () => {
  const routines = ref<Record<number, Routine>>({});
  const workoutPlan = ref<WorkoutPlan>();
  const openaiApiKey = ref('');
  const traineesIds = computed(() => {
    if (!workoutPlan.value) return [];

    return workoutPlan.value.trainees.map(trainee => trainee.id);
  });

  function setOpenaiApiKey(apiKey: string) {
    openaiApiKey.value = apiKey;
  }

  function setRoutines(newRoutines: Routine[]) {
    routines.value = newRoutines.reduce((acc: Record<number, Routine>, routine) => {
      acc[routine.id] = routine;

      return acc;
    }, {});
  }

  function setRoutine(newRoutine: Routine) {
    routines.value = { ...(routines.value), [newRoutine.id]: newRoutine };
  }

  function removeRoutine(routineId: number) {
    delete routines.value[routineId];
  }

  function setWorkoutPlan(newWorkoutPlan: WorkoutPlan) {
    workoutPlan.value = newWorkoutPlan;
  }

  // eslint-disable-next-line max-statements
  async function saveRoutine(routine: Routine, options = { showSuccessAlert: true }) {
    try {
      const routineAttributes = {
        ...transformToNestedAttributes(
          routine, new Set(['routinePhases', 'routinePhaseExercises', 'routineExerciseSets']),
        ),
        published: routine.isPublished,
        duration: routine.durationInMinutes * secondsInMinute,
      } as unknown as RoutineAttributes;

      const updatedRoutine = await routinesApi.update(routine.id, routineAttributes);

      routines.value = { ...routines.value, [updatedRoutine.id]: updatedRoutine };
      if (options.showSuccessAlert) {
        alert('Rutina guardada correctamente');
      }
    } catch (error: unknown) {
      if (!isAxiosError(error)) {
        alert('Error desconocido al guardar la rutina');

        return;
      }
      const errorData = error.response?.data;
      const errorsMessage = `${Object.entries(errorData.errors)
        .map(([key, errors]) => `${key}:\n  - ${Array.isArray(errors) ? errors.join('\n  - ') : errors}`)
        .join('\n')}`;
      alert(`Errores al guardar la rutina:\n${errorsMessage}`);
    }
  }

  async function saveRoutines(newRoutines: Routine[]) {
    await Promise.all(
      newRoutines.map(async routine => await saveRoutine(routine, { showSuccessAlert: false })),
    );
    alert('Rutinas guardadas correctamente');
  }

  // eslint-disable-next-line max-statements
  function updateSet({
    routineId,
    phasePosition,
    routinePhaseExerciseId,
    routineExerciseSet,
  }: {
    routineId: number;
    phasePosition: number;
    routinePhaseExerciseId: number;
    routineExerciseSetId: number;
    routineExerciseSet: RoutineExerciseSet;
  }) {
    const newRoutines = { ...routines.value };
    const routine = newRoutines[routineId];

    const phaseIndex = routine.routinePhases.findIndex(p => p.position === phasePosition);
    if (phaseIndex === -1) return;

    const exerciseIndex = routine.routinePhases[phaseIndex].routinePhaseExercises.findIndex(
      e => e.id === routinePhaseExerciseId,
    );
    if (exerciseIndex === -1) return;

    const exercise = routine.routinePhases[phaseIndex].routinePhaseExercises[exerciseIndex];
    const setIndex = exercise.routineExerciseSets.findIndex(set => set.setNumber === routineExerciseSet.setNumber);

    if (setIndex === -1) {
      exercise.routineExerciseSets = [...exercise.routineExerciseSets, { ...routineExerciseSet }];
    } else {
      exercise.routineExerciseSets[setIndex] = { ...exercise.routineExerciseSets[setIndex], ...routineExerciseSet };
    }

    routines.value = { ...newRoutines };
  }

  function updateRoutinePhaseExercise({
    routineId, phasePosition, routinePhaseExercisePosition, routinePhaseExercise,
  }: {
    routineId: number;
    phasePosition: number;
    routinePhaseExercisePosition: number;
    routinePhaseExercise: RoutinePhaseExercise;
  }) {
    const newRoutines = { ...routines.value };
    const routine = newRoutines[routineId];

    const phaseIndex = routine.routinePhases.findIndex(p => p.position === phasePosition);
    if (phaseIndex === -1) return;
    const exerciseIndex = routine.routinePhases[phaseIndex].routinePhaseExercises.findIndex(
      e => e.position === routinePhaseExercisePosition,
    );
    if (exerciseIndex === -1) return;
    const oldRoutinePhaseExercise = routine.routinePhases[phaseIndex].routinePhaseExercises[exerciseIndex];
    routine.routinePhases[phaseIndex].routinePhaseExercises[exerciseIndex] = {
      ...oldRoutinePhaseExercise, ...routinePhaseExercise,
    };

    routines.value = { ...newRoutines };
  }

  function addEmptyRoutinePhaseExercise({ routineId, phasePosition }: { routineId: number; phasePosition: number }) {
    const newRoutines = { ...routines.value };
    const routine = newRoutines[routineId];
    const phaseIndex = routine.routinePhases.findIndex(p => p.position === phasePosition);
    if (phaseIndex === -1) return;

    routine.routinePhases[phaseIndex].routinePhaseExercises.push({
      position: routine.routinePhases[phaseIndex].routinePhaseExercises.length + 1,
      exerciseId: -1,
      exerciseName: '',
      kind: '',
      durationInSeconds: 0,
      restInSeconds: 0,
      duration: 0,
      rest: 0,
      routineExerciseSets: [],
    });

    routines.value = { ...newRoutines };
  }

  function addEmptyPhase(routineId: number) {
    const newRoutines = { ...routines.value };
    const routine = newRoutines[routineId];

    routine.routinePhases.push({
      name: '',
      sets: 1,
      routinePhaseExercises: [],
      position: routine.routinePhases.length + 1,
      routineId: routine.id,
      durationInMinutes: 0,
    });

    routines.value = { ...newRoutines };
  }

  function updateRoutinePhase({
    routineId, phasePosition, phase,
  }: {
    routineId: number;
    phasePosition: number;
    phase: RoutinePhase;
  }) {
    const newRoutines = { ...routines.value };
    const routine = newRoutines[routineId];
    const phaseIndex = routine.routinePhases.findIndex(p => p.position === phasePosition);
    if (phaseIndex === -1) return;

    routine.routinePhases[phaseIndex] = { ...routine.routinePhases[phaseIndex], ...phase };

    routines.value = { ...newRoutines };
  }

  function updateRoutine(routine: Routine) {
    routines.value = { ...routines.value, [routine.id]: routine };
  }

  function deleteRoutinePhase({
    routineId, phasePosition,
  } : {
    routineId: number;
    phasePosition: number;
  }) {
    const newRoutines = { ...routines.value };
    const routine = newRoutines[routineId];
    const phaseIndex = routine.routinePhases.findIndex(p => p.position === phasePosition);
    if (phaseIndex === -1) return;

    const phase = routine.routinePhases[phaseIndex];
    if (phase.id) {
      routine.routinePhases[phaseIndex] = {
        ...phase,
        _destroy: true,
      };
    } else {
      routine.routinePhases = routine.routinePhases.filter(p => p.position !== phasePosition);
    }

    routines.value = { ...newRoutines };
  }

  // eslint-disable-next-line max-statements
  function deleteRoutinePhaseExercise({
    routineId, phasePosition, routinePhaseExercisePosition,
  }: {
    routineId: number;
    phasePosition: number;
    routinePhaseExercisePosition: number;
  }) {
    const newRoutines = { ...routines.value };
    const routine = newRoutines[routineId];
    const phaseIndex = routine.routinePhases.findIndex(p => p.position === phasePosition);

    if (phaseIndex === -1) return;
    const exerciseIndex = routine.routinePhases[phaseIndex].routinePhaseExercises.findIndex(
      e => e.position === routinePhaseExercisePosition,
    );
    if (exerciseIndex === -1) return;

    const exercise = routine.routinePhases[phaseIndex].routinePhaseExercises[exerciseIndex];

    if (exercise.id) {
      routine.routinePhases[phaseIndex].routinePhaseExercises[exerciseIndex] = {
        ...routine.routinePhases[phaseIndex].routinePhaseExercises[exerciseIndex],
        _destroy: true,
      };
    } else {
      routine.routinePhases[phaseIndex].routinePhaseExercises = routine.routinePhases[phaseIndex].routinePhaseExercises.filter(
        e => e.position !== routinePhaseExercisePosition,
      );
    }

    routines.value = { ...newRoutines };
  }

  async function cloneRoutine(routineId: number, scheduledAt: string, workoutPlanId: number) {
    const response = await routinesApi.clone(routineId, scheduledAt, workoutPlanId);

    return response;
  }

  async function cloneRoutines(newRoutines: Routine[]) {
    const clonedRoutines = await Promise.all(
      newRoutines.map(async routine => await cloneRoutine(routine.id, routine.scheduledAt, routine.workoutPlanId)),
    );

    return clonedRoutines;
  }

  return {
    routines,
    openaiApiKey,
    traineesIds,
    setRoutines,
    setRoutine,
    removeRoutine,
    saveRoutine,
    saveRoutines,
    updateSet,
    updateRoutinePhaseExercise,
    addEmptyRoutinePhaseExercise,
    updateRoutinePhase,
    addEmptyPhase,
    updateRoutine,
    deleteRoutinePhase,
    deleteRoutinePhaseExercise,
    setWorkoutPlan,
    setOpenaiApiKey,
    cloneRoutines,
  };
});
