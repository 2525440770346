<script setup lang="ts">
import { onMounted } from 'vue';
import { analytics } from '@/utils/analytics';
import type { TallySubmissionPayload, Trainer } from '@/types/';
import { preMeetingSurveysApi } from '@/api/pre-meeting-surveys';

const props = defineProps<{
  trainer: Trainer;
  email: string;
  invitationUrl: string;
  traineeId?: number;
  tallyFormId: string;
}>();

// eslint-disable-next-line max-statements
onMounted(() => {
  const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

  // eslint-disable-next-line func-style
  const load = () => {
    // Load Tally embeds
    if (typeof Tally !== 'undefined') {
      Tally.loadEmbeds();

      return;
    }

    // Fallback if window.Tally is not available
    document
      .querySelectorAll('iframe[data-tally-src]:not([src])')
      .forEach((iframeEl) => {
        iframeEl.src = iframeEl.dataset.tallySrc;
      });
  };

  // If Tally is already loaded, load the embeds
  if (typeof Tally !== 'undefined') {
    load();

    return;
  }

  // If the Tally widget script is not loaded yet, load it
  if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
    const script = document.createElement('script');
    script.src = widgetScriptSrc;
    script.onload = load;
    script.onerror = load;
    document.body.appendChild(script);

    return;
  }
});

window.addEventListener('message', (e) => {
  if (e?.data?.includes('Tally.FormSubmitted')) {
    const payload = JSON.parse(e.data).payload as TallySubmissionPayload;
    analytics?.capture('pre_meeting_survey_form_submitted', { ...payload, traineeId: props.traineeId });
    preMeetingSurveysApi.create(props.traineeId!, payload).then(() => {
      window.location.href = props.invitationUrl;
    });
  }
});
</script>

<template>
  <div>
    <iframe
      :data-tally-src="`https://tally.so/embed/${props.tallyFormId}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&email=${props.email}`"
      loading="lazy"
      width="100%"
      height="1200"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      title="Kapso"
    />
  </div>
</template>
