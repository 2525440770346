import type { RoutineExerciseSet } from '@/types';
import type { Routine, RoutinePhase, RoutinePhaseExercise } from '@/types/extended';

export function useHumanReadableRoutine() {
  function getHumanReadableExerciseSet(exerciseSet: RoutineExerciseSet) {
    const result = `     - Comentario (set ${exerciseSet.setNumber}): ${exerciseSet.comment}`;

    return result;
  }

  function getHumanReadableExercise(exercise: RoutinePhaseExercise): string {
    let result = `  - ${exercise.exerciseName}`;

    if (exercise.kind === 'time') {
      result += ` - ${exercise.durationInSeconds} segundos`;
    } else if (exercise.kind === 'repetition') {
      result += ` - ${exercise.repetitions} reps`;
      if (exercise.weight) {
        result += ` - ${exercise.weight} kg`;
      }
    }

    for (const exerciseSet of exercise.routineExerciseSets) {
      result += `\n${getHumanReadableExerciseSet(exerciseSet)}`;
    }

    return `${result}\n`;
  }

  function getHumanReadablePhase(phase: RoutinePhase): string {
    let result = '';

    result += `Bloque: ${phase.name} (${phase.sets} series)\n`;

    for (const exercise of phase.routinePhaseExercises) {
      result += getHumanReadableExercise(exercise);
    }

    return `${result}\n`;
  }

  function getHumanReadableRoutine(routine: Routine): string {
    let result = '';

    result += `Equipamiento: ${routine.equipmentNames}\n`;
    result += `Duración: ${routine.durationInMinutes} minutos\n\n`;

    for (const phase of routine.routinePhases) {
      result += getHumanReadablePhase(phase);
    }

    return result;
  }

  return {
    getHumanReadableRoutine,
  };
}
