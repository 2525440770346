<script setup lang="ts">
import { toRef, ref } from 'vue';
import { useField } from 'vee-validate';
import { useTextareaAutosize } from '@vueuse/core';

defineOptions({
  inheritAttrs: false,
});

interface Props {
  modelValue?: string
  placeholder?: string
  name: string
  required?: boolean
  textSize?: 'sm' | 'base' | 'lg'
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  placeholder: undefined,
  textSize: 'base',
});

const name = toRef(props, 'name');

const {
  meta,
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
} = useField(name, undefined, {
  initialValue: props.modelValue,
  syncVModel: true,
});

const textareaRef = ref<HTMLTextAreaElement | undefined>(undefined);

const { textarea, input } = useTextareaAutosize({
  element: textareaRef,
  input: inputValue,
});

function handleInput(event: Event) {
  const target = event.target as HTMLTextAreaElement;
  handleChange(target.value);
  input.value = target.value;
}

</script>

<template>
  <div
    class="flex flex-col gap-1"
    :class="$attrs.class"
  >
    <div class="flex items-center">
      <slot name="icon" />
      <textarea
        v-bind="$attrs"
        :id="name"
        ref="textarea"
        :value="inputValue"
        :placeholder="placeholder"
        :name="name"
        type="text"
        class="form-input min-h-[34px] w-full resize-none rounded border bg-white px-3 py-2 text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-within:border-slate-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        :class="[errorMessage && meta.touched ? 'border-red-600 text-red-600' : 'border-slate-200', {
          'pl-10': $slots.icon,
          'text-xs': textSize === 'sm',
          'text-lg': textSize === 'lg',
        }]"
        @input="handleInput"
        @blur="handleBlur"
      />
    </div>
    <p
      v-show="errorMessage && meta.touched"
      class="text-right text-xs text-red-600"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
