<script setup lang="ts">
import { computed, ref } from 'vue';
import { Play, Shuffle } from 'lucide-vue-next';
import type { Exercise } from '@/types/extended';
import BaseButton from './base-button.vue';

const props = defineProps<{
  exercise: Exercise;
}>();

const isVideoPlaying = ref(false);
const isFemaleSelected = ref(true);
const hasFemalePoster = computed(() => !!props.exercise.femalePosterUrl);
const hasFemaleVideo = computed(() => !!props.exercise.femaleVideoUrl);
const hasMalePoster = computed(() => !!props.exercise.malePosterUrl);
const hasMaleVideo = computed(() => !!props.exercise.maleVideoUrl);

const videoUrl = computed(() => {
  if (isFemaleSelected.value && hasFemaleVideo.value) return props.exercise.femaleVideoUrl;
  if (hasMaleVideo.value) return props.exercise.maleVideoUrl;

  return undefined;
});
const posterUrl = computed(() => {
  if (isFemaleSelected.value && hasFemalePoster.value) return props.exercise.femalePosterUrl;
  if (hasMalePoster.value) return props.exercise.malePosterUrl;

  return undefined;
});

function playVideo() {
  isVideoPlaying.value = true;
}

function toggleGender() {
  if (isFemaleSelected.value && !hasMaleVideo.value) return;
  if (!isFemaleSelected.value && !hasFemaleVideo.value) return;

  isVideoPlaying.value = false;
  isFemaleSelected.value = !isFemaleSelected.value;
}
</script>

<template>
  <div class="relative">
    <div class="relative w-full">
      <BaseButton
        v-if="hasFemaleVideo && hasMaleVideo"
        size="icon"
        variant="outline"
        class="absolute right-1 top-1 z-10 p-1"
        @click="toggleGender"
      >
        <Shuffle
          class="text-black"
        />
      </BaseButton>
      <video
        v-if="videoUrl && isVideoPlaying"
        :src="videoUrl"
        :poster="posterUrl"
        controls
        autoplay
        class="w-full"
      />
      <button
        v-else-if="posterUrl"
        class="relative w-full cursor-pointer"
        @click="playVideo"
      >
        <img
          class="w-full"
          :src="posterUrl"
          alt="Video poster"
        >
        <div
          v-if="videoUrl"
          class="absolute inset-0 flex items-center justify-center"
        >
          <BaseButton
            size="lg"
            variant="text"
          >
            <Play
              fill="white"
              class="size-12 text-white"
            />
          </BaseButton>
        </div>
      </button>
    </div>
  </div>
</template>
