<script setup lang="ts">
import { computed, ref, inject } from 'vue';
import { GripVertical, Trash2, Info, Search } from 'lucide-vue-next';
import type {
  RoutinePhaseExerciseAttributes,
  NamedResource,
  RoutineExerciseSetAttributes,
  RoutineExerciseSet,
} from '@/types';
import type { RoutinePhaseExercise } from '@/types/extended';
import {
  BaseInput,
  BaseSelect,
  BaseButton,
  BaseCombobox,
  BaseTextarea,
  AudioInput,
} from '@/components';
import ExerciseDetails from './exercise-details.vue';
import ExercisePicker from './exercise-picker.vue';

interface Props {
  routinePhaseExercise?: RoutinePhaseExercise;
  routinePhaseExerciseAttributes: RoutinePhaseExerciseAttributes;
  exercises: NamedResource[];
  index: number;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  'update': [
    index: number,
    field: string,
    value: number | string | undefined | RoutineExerciseSetAttributes[],
  ];
  'remove': [index: number];
}>();

const traineeId = inject<number>('traineeId');

function handleChange(field: string, value: number | string | undefined | RoutineExerciseSetAttributes[]) {
  emit('update', props.index, field, value);
}

const showExerciseDetails = ref(false);
const showExercisePicker = ref(false);

function openExerciseDetails() {
  showExerciseDetails.value = true;
}

function closeExerciseDetails() {
  showExerciseDetails.value = false;
}

function openExercisePicker() {
  showExercisePicker.value = true;
}

function closeExercisePicker() {
  showExercisePicker.value = false;
}

function handleExerciseSelection(exerciseIds: number[]) {
  if (exerciseIds.length > 0) {
    handleChange('exerciseId', exerciseIds[0]);
  }
  closeExercisePicker();
}

const sets = computed({
  get: () => props.routinePhaseExerciseAttributes.routineExerciseSetsAttributes || [],
  set: (newSets) => handleChange('routineExerciseSetsAttributes', newSets),
});

function getAudioUrl(setAttributes: RoutineExerciseSetAttributes) {
  const set = props.routinePhaseExercise?.routineExerciseSets.find(
    (s: RoutineExerciseSet) => s.id === setAttributes.id,
  );

  return set?.audioUrl;
}

function updateSet(
  setIndex: number,
  field: keyof RoutineExerciseSetAttributes,
  value: number | string | undefined | File,
) {
  const updatedSets = sets.value.map((set, index) =>
    (index === setIndex ? { ...set, [field]: value } : set),
  );
  sets.value = updatedSets;
}

function fillEmptyRepetitionsAndWeight() {
  const updatedSets = sets.value.reduce((acc, set, index) => {
    const updatedSet = { ...set };

    if (set.repetitions === undefined || set.weight === undefined) {
      const lastSetWithValues = acc
        .slice(0, index)
        .reverse()
        .find(s => (s.repetitions !== undefined) || (s.weight !== undefined));

      if (lastSetWithValues) {
        if (set.repetitions === undefined) {
          updatedSet.repetitions = lastSetWithValues.repetitions;
        }
        if (set.weight === undefined) {
          updatedSet.weight = lastSetWithValues.weight;
        }
      }
    }

    acc.push(updatedSet);

    return acc;
  }, [] as RoutineExerciseSetAttributes[]);

  sets.value = updatedSets;
}

</script>

<template>
  <div class="text-sm">
    <div class="mb-2 flex items-center justify-between">
      <div class="flex items-center gap-2">
        <GripVertical class="size-4 cursor-move" />
        <div class="relative flex items-center">
          <BaseCombobox
            :id="`exercise-${index}-id`"
            :name="`routinePhaseExercisesAttributes[${index}].exerciseId`"
            :model-value="routinePhaseExerciseAttributes.exerciseId"
            :options="exercises"
            class="mr-4 w-full font-semibold"
            @update:model-value="(value) => handleChange('exerciseId', value)"
          />
          <BaseButton
            size="sm"
            variant="outline"
            @click.stop="openExerciseDetails"
          >
            <Info class="size-4" />
          </BaseButton>
          <BaseButton
            size="sm"
            variant="outline"
            @click.stop="openExercisePicker"
          >
            <Search class="size-4" />
          </BaseButton>
        </div>
      </div>
      <BaseButton
        variant="ghost"
        size="sm"
        @click="emit('remove', index)"
      >
        <Trash2 class="size-4" />
      </BaseButton>
    </div>
    <div class="ml-6 grid grid-cols-5 items-start gap-2">
      <div>
        <label
          :for="`exercise-${index}-kind`"
          class="mb-1 block text-xs text-gray-500"
        >
          Tipo
        </label>
        <BaseSelect
          :id="`exercise-${index}-kind`"
          :name="`routinePhaseExercisesAttributes[${index}].kind`"
          :model-value="routinePhaseExerciseAttributes.kind"
          :options="[
            { name: 'Tiempo', id: 'time' },
            { name: 'Reps', id: 'repetition' },
          ]"
          class="text-xs"
          @update:model-value="(value) => handleChange('kind', value)"
        />
      </div>
      <div>
        <label
          :for="`exercise-${index}-duration`"
          class="mb-1 block truncate text-xs text-gray-500"
        >
          Duración (seg)
        </label>
        <BaseInput
          :id="`exercise-${index}-duration`"
          :name="`routinePhaseExercisesAttributes[${index}].duration`"
          :model-value="routinePhaseExerciseAttributes.duration"
          type="number"
          class="text-xs"
          @update:model-value="(value) => handleChange('duration', value)"
        />
      </div>
      <div>
        <label
          :for="`exercise-${index}-rest`"
          class="mb-1 block truncate text-xs text-gray-500"
        >
          Descanso (seg)
        </label>
        <BaseInput
          :id="`exercise-${index}-rest`"
          :name="`routinePhaseExercisesAttributes[${index}].rest`"
          :model-value="routinePhaseExerciseAttributes.rest"
          type="number"
          class="text-xs"
          @update:model-value="(value) => handleChange('rest', value)"
        />
      </div>
    </div>
    <div class="my-2 flex flex-col">
      <div class="flex w-full items-center gap-2">
        <span class="w-4 text-xs text-gray-500" />
        <span class="w-14 text-xs text-gray-500">
          Reps
        </span>
        <span class="w-14 text-xs text-gray-500">
          Peso
        </span>
        <span class="text-xs text-gray-500">
          Comentario
        </span>
      </div>
      <div
        v-for="(set, setIndex) in sets"
        :key="set.setNumber"
        class="flex items-start gap-2 py-1"
      >
        <div class="flex h-9 items-center justify-center">
          <span class="w-4 p-1 text-xs text-gray-500">
            {{ set.setNumber }}
          </span>
        </div>
        <BaseInput
          :id="`exercise-${index}-set-${setIndex}-repetitions`"
          :name="`routinePhaseExercisesAttributes[${index}].routineExerciseSetsAttributes[${setIndex}].repetitions`"
          :model-value="set.repetitions"
          type="number"
          class="w-14 text-xs"
          @update:model-value="(value) => updateSet(setIndex, 'repetitions', value)"
          @blur="fillEmptyRepetitionsAndWeight"
        />
        <BaseInput
          :id="`exercise-${index}-set-${setIndex}-weight`"
          :name="`routinePhaseExercisesAttributes[${index}].routineExerciseSetsAttributes[${setIndex}].weight`"
          :model-value="set.weight"
          type="number"
          class="w-14 text-xs"
          @update:model-value="(value) => updateSet(setIndex, 'weight', value)"
          @blur="fillEmptyRepetitionsAndWeight"
        />
        <BaseTextarea
          :id="`exercise-${index}-set-${setIndex}-comment`"
          :name="`routinePhaseExercisesAttributes[${index}].routineExerciseSetsAttributes[${setIndex}].comment`"
          :model-value="set.comment"
          placeholder="Comentario"
          class="w-full text-xs"
          @update:model-value="(value) => updateSet(setIndex, 'comment', value)"
        />
        <AudioInput
          :id="`exercise-${index}-set-${setIndex}-audio`"
          :model-value="set.audio"
          :audio-url="getAudioUrl(set)"
          @update="(value) => updateSet(setIndex, 'audio', value)"
        />
      </div>
    </div>
  </div>
  <ExerciseDetails
    v-if="showExerciseDetails && traineeId"
    :exercise-id="routinePhaseExerciseAttributes.exerciseId"
    :trainee-id="traineeId"
    @close="closeExerciseDetails"
  />
  <ExercisePicker
    v-if="showExercisePicker"
    mode="single"
    @close="closeExercisePicker"
    @select="handleExerciseSelection"
  />
</template>
