<script setup lang="ts">
import { ref, computed } from 'vue';
import { Ellipsis, Loader2, BellDot } from 'lucide-vue-next';
import type { Routine } from '@/types/extended';
import { useQueryClient } from '@tanstack/vue-query';
import { BaseButton, BaseMenu } from '.';
import NewRoutineTemplateModal from './new-routine-template-modal.vue';
import CloneRoutineModal from './clone-routine-modal.vue';
import DeleteRoutineModal from './delete-routine-modal.vue';
import ProgressRoutineModal from './progress-routine-modal.vue';

const props = defineProps<{
  routine: Routine;
}>();

const isNewRoutineTemplateModalOpen = ref(false);
const isCloneRoutineOpen = ref(false);
const isDeleteRoutineOpen = ref(false);
const isRoutineProgressorOpen = ref(false);

const isProgressRoutinePending = ref(false);
const isProgressRoutineSuccess = ref(false);

const queryClient = useQueryClient();
function invalidateRoutinesQueries() {
  queryClient.invalidateQueries({ queryKey: ['routines'] });
}

const menuIcon = computed(() => {
  if (isProgressRoutinePending.value) return Loader2;
  if (isProgressRoutineSuccess.value) return BellDot;

  return Ellipsis;
});

const progressRoutineLabel = computed(() => {
  if (isProgressRoutinePending.value) return 'Generando progresión... ⏳';
  if (isProgressRoutineSuccess.value) return 'Progresión generada 👀';

  return 'Progresar y clonar';
});

const menuItems = computed(() => [
  { label: 'Clonar', onClick: () => { isCloneRoutineOpen.value = true; } },
  { label: progressRoutineLabel.value, onClick: () => { isRoutineProgressorOpen.value = true; } },
  { label: 'Guardar como plantilla', onClick: () => { isNewRoutineTemplateModalOpen.value = true; } },
  { label: 'Eliminar', onClick: () => { isDeleteRoutineOpen.value = true; } },
]);

function handleProgressRoutineSuccess() {
  isProgressRoutineSuccess.value = true;
  isProgressRoutinePending.value = false;
}

function handleProgressRoutineReset() {
  isProgressRoutineSuccess.value = false;
  isProgressRoutinePending.value = false;
}

function handleDeleteRoutineClose(isSuccess: boolean) {
  isDeleteRoutineOpen.value = false;
  if (isSuccess) {
    invalidateRoutinesQueries();
  }
}

function handleCloneRoutineClose(isSuccess: boolean) {
  isCloneRoutineOpen.value = false;
  if (isSuccess) {
    invalidateRoutinesQueries();
  }
}
</script>

<template>
  <BaseMenu
    class="right-0"
    :items="menuItems"
  >
    <template #button>
      <BaseButton
        variant="ghost"
        size="sm"
      >
        <component
          :is="menuIcon"
          class="size-4"
          :class="{ 'animate-spin': isProgressRoutinePending }"
        />
      </BaseButton>
    </template>
  </BaseMenu>
  <NewRoutineTemplateModal
    :routine="props.routine"
    :is-open="isNewRoutineTemplateModalOpen"
    @close="isNewRoutineTemplateModalOpen = false"
  />
  <CloneRoutineModal
    :routine="props.routine"
    :is-open="isCloneRoutineOpen"
    @close="handleCloneRoutineClose"
  />
  <DeleteRoutineModal
    :routine="props.routine"
    :is-open="isDeleteRoutineOpen"
    @close="handleDeleteRoutineClose"
  />
  <ProgressRoutineModal
    :routine="props.routine"
    :is-open="isRoutineProgressorOpen"
    @close="isRoutineProgressorOpen = false"
    @pending="isProgressRoutinePending = true"
    @success="handleProgressRoutineSuccess"
    @reset="handleProgressRoutineReset"
  />
</template>
