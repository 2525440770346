import type { TallySubmissionPayload, FollowUpMeetingSurvey } from '@/types';
import { api } from './index';

export const followUpMeetingSurveysApi = {
  create(traineeId: number, submission: Partial<TallySubmissionPayload>): Promise<AxiosResponse<FollowUpMeetingSurvey>> {
    const path = '/api/internal/follow_up_meeting_surveys';

    return api({
      method: 'post',
      url: path,
      data: { followUpMeetingSurvey: { ...submission, traineeId } },
    });
  },
};
