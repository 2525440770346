<script setup lang="ts">
import { ref, computed } from 'vue';
import { useUrlSearchParams } from '@vueuse/core';
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue';
import { isBefore, addHours } from 'date-fns';
import type { Trainer, Routine, FollowUpMeetingSurvey } from '@/types';
import type { Trainee, WorkoutPlan } from '@/types/extended';
import { analytics } from '@/utils/analytics';
import { BaseLink } from '@/components';
import { ArrowRight } from 'lucide-vue-next';
import ChatGroupMessages from './chat-group-messages.vue';
import AiAssistant from '../ai-assistant.vue';
import NotesList from './notes-list.vue';
import RoutinesCalendar from '../routines-calendar.vue';
import RpeChart from './rpe-chart.vue';
import ExerciseExecutions from './exercise-executions.vue';
import TraineeStats from './trainee-stats.vue';
import SurveysList from './surveys-list.vue';

const props = defineProps<{
  trainees: Trainee[],
  trainer: Trainer,
  openaiApiKey: string,
}>();

const searchParams = useUrlSearchParams('history');
const selectedTraineeId = ref<number | null>(
  searchParams.traineeId ? Number(searchParams.traineeId) : null,
);

const selectedTrainee = computed(() =>
  props.trainees.find(trainee => trainee.id === selectedTraineeId.value) || props.trainees[0],
);

const workoutPlan = computed<WorkoutPlan | undefined>(() => selectedTrainee.value?.workoutPlans[0]);

function getLastRoutines(trainee: Trainee): Routine[] {
  const NUMBER_OF_ROUTINES = 2;

  return trainee.routines
    .sort((a: Routine, b: Routine) => new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime())
    .filter((routine: Routine) => new Date(routine.scheduledAt) < new Date())
    .slice(-NUMBER_OF_ROUTINES);
}

const routinesState = computed(() => props.trainees.reduce((state, trainee) => {
  const lastRoutines = getLastRoutines(trainee);
  const allCompleted = lastRoutines.every(routine => routine.isCompleted);
  const allNotCompleted = lastRoutines.every(routine => !routine.isCompleted);

  if (lastRoutines.length === 0) {
    state[trainee.id] = 'ok';
  } else if (allCompleted) {
    state[trainee.id] = 'ok';
  } else if (allNotCompleted) {
    state[trainee.id] = 'danger';
  } else {
    state[trainee.id] = 'warning';
  }

  return state;
}, {} as Record<number, 'ok' | 'danger' | 'warning'>));

const messagesState = computed(() =>
  // eslint-disable-next-line max-statements
  props.trainees.reduce((state, trainee) => {
    const lastRoutines = getLastRoutines(trainee);
    const lastRoutine = lastRoutines[lastRoutines.length - 1];

    if (trainee.chatGroup) {
      const trainerMessages = trainee.chatGroup.chatMessages
        .filter(message => message.userableType === 'Trainer')
        .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      const lastMessage = trainerMessages[0];

      const HOURS_WITHOUT_MESSAGE_THRESHOLD = 36;
      if (
        !lastMessage || !lastRoutine ||
        isBefore(lastMessage.createdAt, lastRoutine.scheduledAt) ||
        isBefore(lastMessage.createdAt, addHours(new Date(), -HOURS_WITHOUT_MESSAGE_THRESHOLD))
      ) {
        state[trainee.id] = 'danger';
      } else {
        state[trainee.id] = 'ok';
      }
    } else {
      state[trainee.id] = 'danger';
    }

    return state;
  }, {} as Record<number, 'ok' | 'danger'>),
);

const selectedTab = ref('routines');

const tabs = [
  {
    name: 'routines',
    label: 'Rutinas',
  },
  {
    name: 'stats',
    label: 'Estadísticas',
  },
  {
    name: 'assistant',
    label: 'Asistente IA',
  },
  {
    name: 'messages',
    label: 'Mensajes',
  },
  {
    name: 'notes',
    label: 'Notas',
  },
  {
    name: 'exercise_executions',
    label: 'Historial',
  },
  {
    name: 'surveys',
    label: 'Formularios',
  },
];

function selectTrainee(traineeId: number) {
  selectedTab.value = 'routines';
  selectedTraineeId.value = traineeId;
  searchParams.traineeId = traineeId.toString();
  if (analytics) analytics.capture('select_trainee_click', { traineeId });
}

function tabChanged(index: number) {
  if (analytics) analytics.capture('trainee_tab_click', { tab: tabs[index].name });
}

function onFollowUpSurveySubmitted(newSurvey: FollowUpMeetingSurvey) {
  if (selectedTrainee.value) {
    selectedTrainee.value.followUpMeetingSurveys.push(newSurvey);
  }
}

</script>

<template>
  <div class="w-full">
    <h2 class="text-2xl font-bold">
      Trainees
    </h2>
    <div class="mt-4 flex gap-4">
      <div class="flex w-48 flex-col">
        <button
          v-for="trainee in props.trainees"
          :key="trainee.id"
          class="flex cursor-pointer justify-between rounded-lg border p-3 text-sm transition-colors duration-200 hover:bg-gray-200"
          :class="{ 'border-2 bg-gray-200': selectedTrainee?.id === trainee.id }"
          @click="selectTrainee(trainee.id)"
        >
          <span class="truncate">{{ trainee.fullName }}</span>
          <span
            v-if="messagesState[trainee.id] === 'danger'"
            class="ml-2 shrink-0"
          >
            🔴
          </span>
        </button>
      </div>
      <div
        v-if="selectedTrainee"
        class="w-full rounded-lg border bg-gray-50 p-4"
      >
        <div class="flex flex-col gap-4">
          <div>
            <h3 class="text-xl font-bold">
              {{ selectedTrainee?.fullName }}
            </h3>
            <p class="text-sm">
              {{ selectedTrainee?.email }}
            </p>
          </div>
          <TabGroup @change="tabChanged">
            <TabList class="flex gap-1">
              <Tab
                v-for="tab in tabs"
                :key="tab.name"
                v-slot="{ selected }"
                class="w-28 cursor-pointer rounded-lg border p-2 text-base"
              >
                <p :class="{'border-b border-black font-semibold': selected}">
                  {{ tab.label }}
                  <template v-if="tab.name === 'routines'">
                    <span
                      v-if="routinesState[selectedTrainee.id] === 'danger'"
                    >
                      🔴
                    </span>
                    <span
                      v-else-if="routinesState[selectedTrainee.id] === 'warning'"
                    >
                      🟡
                    </span>
                    <span v-else>
                      🟢
                    </span>
                  </template>
                  <template v-if="tab.name === 'messages'">
                    <span
                      v-if="messagesState[selectedTrainee.id] === 'danger'"
                    >
                      🔴
                    </span>
                    <span v-else>
                      🟢
                    </span>
                  </template>
                </p>
              </Tab>
            </TabList>
            <TabPanels class="rounded-lg border bg-white p-4">
              <TabPanel class="flex flex-col gap-6">
                <div class="flex flex-col gap-y-2">
                  <div class="rounded-lg border bg-gray-100 p-2">
                    <span
                      v-if="routinesState[selectedTrainee.id] === 'danger'"
                      class="text-red-700"
                    >
                      🔴 No ha completado sus 2 últimas rutinas
                    </span>
                    <span
                      v-else-if="routinesState[selectedTrainee.id] === 'warning'"
                      class="text-yellow-700"
                    >
                      🟡 No ha completado alguna de sus 2 últimas rutinas
                    </span>
                    <span
                      v-else
                      class="text-green-700"
                    >
                      🟢 Ha completado sus 2 últimas rutinas
                    </span>
                  </div>
                  <BaseLink
                    :href="`/workout_plans/${workoutPlan?.id}/routines`"
                    target="_blank"
                    class="w-min"
                  >
                    Ver rutinas completas
                    <ArrowRight class="size-4" />
                  </BaseLink>
                  <BaseLink
                    :href="`/workout_plans/${workoutPlan?.id}/week`"
                    class="w-min"
                  >
                    Ver rutinas por semana
                    <ArrowRight class="size-4" />
                  </BaseLink>
                </div>
                <div class="flex flex-col gap-y-6">
                  <div class="flex flex-col gap-2 gap-x-4 md:flex-row">
                    <div class="flex w-full flex-col gap-y-4">
                      <routines-calendar
                        v-if="selectedTrainee"
                        :routines="selectedTrainee.routines"
                      />
                      <rpe-chart
                        v-if="selectedTrainee"
                        :key="selectedTrainee.id"
                        :trainee="selectedTrainee"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <trainee-stats
                  v-if="selectedTrainee && workoutPlan"
                  :key="selectedTrainee.id"
                  :routines="selectedTrainee.routines"
                  :workout-plan-id="workoutPlan.id"
                  :trainee-id="selectedTrainee.id"
                />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <ai-assistant
                  v-if="workoutPlan"
                  :key="selectedTrainee.id"
                  class="h-full"
                  :trainee="selectedTrainee"
                  :trainer="props.trainer"
                  :workout-plan-id="workoutPlan.id"
                  :chat-messages="selectedTrainee.chatGroup.chatMessages"
                  :openai-api-key="props.openaiApiKey"
                  :is-intercom-style="false"
                />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <div
                  v-if="messagesState[selectedTrainee.id] === 'danger'"
                  class="rounded-lg border bg-gray-100 p-2 text-red-800"
                >
                  🔴 Debemos enviarle mensaje
                </div>
                <chat-group-messages
                  v-if="selectedTrainee.chatGroup"
                  :chat-group="selectedTrainee.chatGroup"
                  :trainee="selectedTrainee"
                  :trainer="props.trainer"
                />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <notes-list :trainee-id="selectedTrainee.id" />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <exercise-executions
                  :key="selectedTrainee.id"
                  :trainee-id="selectedTrainee.id"
                />
              </TabPanel>
              <TabPanel class="flex flex-col gap-6">
                <surveys-list
                  v-if="selectedTrainee"
                  :initial-survey="selectedTrainee.initialSurveySubmission"
                  :pre-meeting-survey="selectedTrainee.preMeetingSurvey"
                  :follow-up-meeting-surveys="selectedTrainee.followUpMeetingSurveys"
                  :trainee-id="selectedTrainee.id"
                  @survey-submitted="onFollowUpSurveySubmitted"
                />
              </TabPanel>
            </TabPanels>
          </TabGroup>
        </div>
      </div>
    </div>
  </div>
</template>
