<script setup lang="ts">
import { computed, ref, inject } from 'vue';
import { Info } from 'lucide-vue-next';
import { useQuery } from '@tanstack/vue-query';
import type { RoutinePhase, RoutinePhaseExercise } from '@/types/extended';
import { BaseButton, ExerciseDetails } from '@/components';
import { routinePhasesApi } from '@/api/routine-phases';

interface Props {
  phaseId: number;
  phases: RoutinePhase[];
}

const props = defineProps<Props>();

const initialPhase = computed(() => props.phases.find((phase) => phase.id === props.phaseId));

const { data: routinePhase } = useQuery({
  queryKey: ['routinePhase', props.phaseId],
  queryFn: () => routinePhasesApi.get(props.phaseId),
  initialData: initialPhase.value,
  refetchOnMount: false,
});

const exercises = computed(() => routinePhase.value?.routinePhaseExercises || []);

const traineeId = inject('traineeId', 0);

const selectedExerciseForDetails = ref<RoutinePhaseExercise | null>(null);
</script>

<template>
  <div
    v-if="exercises.length > 0"
    class="flex flex-col gap-1 pl-5 text-xs"
  >
    <ul class="list-inside">
      <li
        v-for="exercise in exercises"
        :key="exercise.id"
        class="group/exercise mb-2"
      >
        <div class="flex items-center justify-between gap-2">
          <span class="my-1 font-semibold">{{ exercise.exerciseName }}</span>
          <BaseButton
            type="button"
            variant="ghost"
            size="icon"
            class="hidden group-hover/exercise:inline-flex"
            @click="selectedExerciseForDetails = exercise"
          >
            <Info class="size-4" />
          </BaseButton>
        </div>
        <div class="grid w-2/3 grid-cols-7 gap-1">
          <template
            v-for="set in exercise.routineExerciseSets"
            :key="set.setNumber"
          >
            <div
              v-if="set.setNumber"
              class="text-center text-gray-400"
            >
              {{ set.setNumber }}
            </div>
            <div
              v-if="set.repetitions"
              class="col-span-3 text-center"
            >
              {{ set.repetitions }} reps
            </div>
            <div
              v-else-if="exercise.durationInSeconds"
              class="col-span-3 text-center"
            >
              {{ exercise.durationInSeconds }} segundos
            </div>
            <div
              v-else
              class="col-span-3"
            />
            <div
              v-if="set.weight"
              class="col-span-3 text-center"
            >
              {{ set.weight }} kg
            </div>
            <div
              v-else
              class="col-span-3"
            />
          </template>
        </div>
      </li>
    </ul>
  </div>
  <div
    v-else
    class="flex w-full items-center justify-center text-sm text-gray-500"
  >
    0 ejercicios
  </div>

  <ExerciseDetails
    v-if="selectedExerciseForDetails"
    :exercise-id="selectedExerciseForDetails.exerciseId"
    :trainee-id="traineeId"
    @close="selectedExerciseForDetails = null"
  />
</template>
