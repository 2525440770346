<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { exerciseExecutionsApi } from '@/api/exercise-executions';
import { exercisesApi } from '@/api/exercises';
import type { ExerciseExecution } from '@/types/exercise-execution';
import type { NamedResource } from '@/types';
import { ExerciseExecutionDetails } from '@/components';

const props = defineProps<{ traineeId: number }>();

const exerciseExecutions = ref<ExerciseExecution[]>([]);
const exercises = ref<NamedResource[]>([]);
const selectedExerciseId = ref<number | null>(null);

async function fetchExerciseData() {
  // Fetch all exercise executions for the trainee
  const response = await exerciseExecutionsApi.getAll({ traineeId: props.traineeId });
  exerciseExecutions.value = response;

  // Extract unique exercise IDs
  const exerciseIds = [...new Set(exerciseExecutions.value.map(execution => execution.exerciseId))];
  if (exerciseIds.length === 0) return;

  // Fetch exercises by IDs
  const exerciseResponse = await exercisesApi.getAll(exerciseIds);
  exercises.value = exerciseResponse;

  // Count the number of executions per exercise
  const executionCounts: Record<number, number> = exerciseExecutions.value.reduce((counts, execution) => {
    counts[execution.exerciseId] = (counts[execution.exerciseId] || 0) + 1;

    return counts;
  }, {} as Record<number, number>);

  // Sort exercises by the number of executions, in descending order
  exercises.value.sort((a, b) => {
    const countA = executionCounts[a.id] || 0;
    const countB = executionCounts[b.id] || 0;

    return countB - countA;
  });
}

onMounted(fetchExerciseData);

const selectedExerciseExecutions = computed(() =>
  exerciseExecutions.value.filter(execution => execution.exerciseId === selectedExerciseId.value),
);

</script>

<template>
  <div class="flex">
    <div class="w-1/4 border-gray-300">
      <ul class="list-none p-0">
        <li
          v-for="exercise in exercises"
          :key="exercise.id"
          class="cursor-pointer border-b border-gray-300 px-4 py-2"
          :class="{
            'bg-gray-100 font-bold': selectedExerciseId === exercise.id,
          }"
          @click="selectedExerciseId = exercise.id"
        >
          {{ exercise.name }}
        </li>
      </ul>
    </div>

    <div
      v-if="selectedExerciseId"
      class="w-3/4 p-6"
    >
      <h2 class="mb-4 text-xl font-semibold">
        {{ exercises.find(exercise => exercise.id === selectedExerciseId)?.name }}
      </h2>

      <ExerciseExecutionDetails
        :exercise-executions="selectedExerciseExecutions"
      />
    </div>
  </div>
</template>
