<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/yup';
import * as yup from 'yup';
import { secondsInMinute } from 'date-fns/constants';
import { useMutation } from '@tanstack/vue-query';
import { AxiosError } from 'axios';
import type { Routine } from '@/types/extended';
import type { RoutineAttributes, RoutinePhaseAttributes } from '@/types';
import { routinesApi } from '@/api/routines';
import {
  AudioInput,
  BaseInput,
  BaseTextarea,
  BaseButton,
  BaseCheckbox,
  RoutineDetailsMenu,
  RoutineFormPhasesExercises, RoutinePhaseForm } from '@/components';
import { useTransformRoutineToAttributes } from '@/composables/transform-routine-to-attributes';
import { useDraggableList } from '@/composables/draggable-list';
import {
  ChevronDown, ChevronUp, Trash2, Plus, GripVertical,
  ChevronsUpDown, ChevronsDownUp, Pencil, ArrowRight,
} from 'lucide-vue-next';

interface Props {
  routine: Routine;
  workoutPlanId: number;
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'update', data: Routine): void;
}>();

const routineSchema = toTypedSchema(
  yup.object().shape({
    name: yup.string().required('El nombre es obligatorio'),
    scheduledAt: yup.string().required('La fecha programada es obligatoria'),
    duration: yup.number().positive('La duración debe ser positiva'),
    trainerNote: yup.string(),
    published: yup.boolean(),
    audio: yup.mixed().optional(),
    routinePhasesAttributes: yup.array().of(
      yup.object().shape({
        id: yup.number().nullable(),
        name: yup.string().required('El nombre es obligatorio'),
        position: yup.number().required('La posición es obligatoria'),
        sets: yup.number().required('El número de series es obligatorio'),
        _destroy: yup.boolean(),
      }),
    ),
  }),
);

const { transformRoutineToAttributes } = useTransformRoutineToAttributes();

const initialValues = ref({ ...transformRoutineToAttributes(props.routine) });

const { handleSubmit, values, setFieldValue, resetForm } = useForm<RoutineAttributes>({
  validationSchema: routineSchema,
  initialValues: initialValues.value,
});

const routinePhasesAttributes = computed(() => values.routinePhasesAttributes || []);

const {
  sortedItems: draggablePhasesAttributes,
  onDragStart,
  onDragOver,
  onDragEnter,
  onDragLeave,
  onDrop,
} = useDraggableList<RoutinePhaseAttributes>({
  items: routinePhasesAttributes,
  onUpdate: (newItems) => setFieldValue('routinePhasesAttributes', newItems),
});

watch(() => props.routine, (newRoutine) => {
  const newInitialValues = transformRoutineToAttributes(newRoutine);
  initialValues.value = newInitialValues;
  resetForm({ values: newInitialValues });
});

const errorMessage = ref<string | null>(null);

function formatErrors(responseErrors: Record<string, string | string[]>) {
  const items = Object.entries(responseErrors).map(([key, value], index) => {
    const messages = Array.isArray(value) ? value : [value];

    return `${index + 1}. ${key}:\n${messages.map(msg => `  - ${msg}`).join('\n')}`;
  });

  return `Errores al guardar la rutina:\n${items.join('\n')}`;
}

const RESET_MUTATION_TIMEOUT = 2000;

const { mutate, isIdle, isPending, isError, isSuccess, reset: resetMutation } = useMutation({
  mutationFn: (routineAttributes: RoutineAttributes) =>
    routinesApi.update(props.routine.id, routineAttributes),
  onSuccess: (routine: Routine) => {
    emit('update', routine);
    resetForm({ values });
    errorMessage.value = null;
    initialValues.value = { ...values };
    setTimeout(() => {
      resetMutation();
    }, RESET_MUTATION_TIMEOUT);
  },
  onError: (error) => {
    if (error instanceof AxiosError && error.response?.data?.errors) {
      const responseErrors = error.response.data.errors;
      errorMessage.value = formatErrors(responseErrors);
    } else if (error instanceof AxiosError && error.response?.data.detail) {
      errorMessage.value = error.response.data.detail;
    } else {
      errorMessage.value = 'Ocurrió un error al guardar la rutina';
    }
  },
});

const onSubmit = handleSubmit((data: RoutineAttributes) => {
  mutate({
    ...data,
    duration: Number(data.duration) * secondsInMinute,
    published: data.published === true,
    workoutPlanId: props.workoutPlanId,
  });
});

function copyRoutineAttributes(routineAttributes: RoutineAttributes) {
  return {
    name: routineAttributes.name,
    published: routineAttributes.published,
    scheduledAt: routineAttributes.scheduledAt,
    audio: routineAttributes.audio,
    duration: routineAttributes.duration,
    trainerNote: routineAttributes.trainerNote,
    routinePhasesAttributes: routineAttributes.routinePhasesAttributes?.map(phase => ({
      ...phase,
      routinePhaseExercisesAttributes: phase.routinePhaseExercisesAttributes?.map(exercise => ({
        ...exercise,
        routineExerciseSetsAttributes: exercise.routineExerciseSetsAttributes?.map(set => ({
          ...set,
        })),
      })),
    })),
  };
}

const isFormChanged = computed(() => {
  const currentValues = copyRoutineAttributes(values);
  const initialFormValues = copyRoutineAttributes(initialValues.value);

  return JSON.stringify(currentValues) !== JSON.stringify(initialFormValues);
});

const buttonText = computed(() => {
  if (!isFormChanged.value) return 'Sin cambios';
  if (isIdle.value) return 'Guardar';
  if (isPending.value) return 'Guardando...';
  if (isError.value) return 'Error';
  if (isSuccess.value) return 'Guardado';

  return 'Guardar';
});

const buttonClass = computed(() => {
  if (!isFormChanged.value) return 'bg-gray-300 text-gray-500';
  if (isIdle.value) return 'bg-black text-white hover:bg-gray-800';
  if (isPending.value) return 'bg-black text-white cursor-not-allowed';
  if (isError.value) return 'bg-red-500 text-white';
  if (isSuccess.value) return 'bg-green-500 text-white hover:bg-green-600';

  return 'bg-black text-white hover:bg-gray-800';
});

function handlePublishedChange(event: Event) {
  const target = event.target as HTMLInputElement;
  setFieldValue('published', target.checked);
}

const isSubmitDisabled = computed(() => !isFormChanged.value || isPending.value);
const completedWorkouts = computed(() => props.routine.workouts.filter(workout => !!workout.endedAt));

const expandedPhases = ref<Set<number>>(new Set());
const allExpanded = ref(false);
const editingPhaseIndex = ref<number | null>(null);

function toggleAllPhases() {
  if (allExpanded.value) {
    expandedPhases.value.clear();
  } else {
    expandedPhases.value = new Set(draggablePhasesAttributes.value.map((_, index) => index));
  }
  allExpanded.value = !allExpanded.value;
}

function toggleExpand(index: number) {
  if (expandedPhases.value.has(index)) {
    expandedPhases.value.delete(index);
  } else {
    expandedPhases.value.add(index);
  }
  allExpanded.value = expandedPhases.value.size === draggablePhasesAttributes.value.length;
}

function addPhase() {
  const newPhase: RoutinePhaseAttributes = {
    routineId: props.routine.id,
    name: 'Nuevo bloque',
    position: draggablePhasesAttributes.value.length + 1,
    sets: 1,
    routinePhaseExercisesAttributes: [],
  };
  setFieldValue('routinePhasesAttributes', [...draggablePhasesAttributes.value, newPhase]);
}

function removePhase(index: number) {
  const newPhasesAttributes = [...draggablePhasesAttributes.value];
  const phase = newPhasesAttributes[index];
  if (phase.id) {
    newPhasesAttributes[index] = { ...phase, _destroy: true };
  } else {
    newPhasesAttributes.splice(index, 1);
  }
  setFieldValue('routinePhasesAttributes', newPhasesAttributes);
  expandedPhases.value.delete(phase.position);
}

function editPhase(index: number) {
  editingPhaseIndex.value = index;
}

function closePhaseForm() {
  editingPhaseIndex.value = null;
}

function updatePhase(phase: RoutinePhaseAttributes, index: number) {
  const newPhasesAttributes = [...draggablePhasesAttributes.value];
  newPhasesAttributes[index] = phase;
  if (initialValues.value.routinePhasesAttributes) {
    initialValues.value.routinePhasesAttributes[index] = phase;
  }
  setFieldValue('routinePhasesAttributes', newPhasesAttributes);
}

</script>

<template>
  <form
    class="flex flex-col gap-3"
    @submit="onSubmit"
  >
    <div class="flex items-center gap-2">
      <BaseInput
        id="name"
        v-model="values.name"
        name="name"
        placeholder="Nombre de la rutina"
        class="grow font-semibold"
        autocomplete="off"
      />
      <RoutineDetailsMenu :routine="props.routine" />
    </div>

    <div>
      <label
        for="scheduledAt"
        class="mb-1 block text-xs font-medium text-gray-700"
      >
        Fecha programada
      </label>
      <BaseInput
        id="scheduledAt"
        v-model="values.scheduledAt"
        name="scheduledAt"
        type="datetime-local"
        placeholder="Fecha programada"
        class="text-xs"
      />
    </div>

    <div>
      <label
        for="trainerNote"
        class="mb-1 block text-xs font-medium text-gray-700"
      >
        Nota para el entrenador
      </label>
      <BaseTextarea
        id="trainerNote"
        v-model="values.trainerNote"
        name="trainerNote"
        class="text-xs"
        rows="2"
      />
    </div>

    <div class="grid grid-cols-3 gap-4">
      <div>
        <label
          for="duration"
          class="mb-1 block text-xs font-medium text-gray-700"
        >
          Duración (min)
        </label>
        <BaseInput
          id="duration"
          :model-value="values.duration"
          name="duration"
          type="number"
          placeholder="Duración"
          class="text-xs"
          @update="setFieldValue('duration', $event)"
        />
      </div>

      <div>
        <label
          for="audio"
          class="mb-1 block text-xs font-medium text-gray-700"
        >
          Audio
        </label>
        <AudioInput
          :model-value="values.audio"
          :audio-url="props.routine.audioUrl"
          @update="setFieldValue('audio', $event)"
        />
      </div>

      <div>
        <label
          for="published"
          class="mb-1 block text-xs font-medium text-gray-700"
        >
          Publicada
        </label>
        <BaseCheckbox
          :model-value="values.published"
          name="published"
          :true-value="true"
          :false-value="false"
          class="py-2"
          @change="handlePublishedChange"
        />
      </div>
    </div>

    <a
      v-if="completedWorkouts.length > 0"
      :href="`/workouts/${completedWorkouts[0].id}`"
      class="flex justify-center gap-2 rounded-md bg-green-100 px-3 py-2 text-xs text-green-700 hover:bg-green-200"
    >
      <span class="font-medium">
        Ver entrenamiento completado
      </span>
      <ArrowRight class="size-4" />
    </a>
    <BaseButton
      v-else
      type="submit"
      size="sm"
      :class="['w-full text-xs', buttonClass]"
      :disabled="isSubmitDisabled"
    >
      {{ buttonText }}
    </BaseButton>

    <p
      v-if="errorMessage"
      class="whitespace-pre-wrap text-sm text-red-500"
    >
      {{ errorMessage }}
    </p>

    <div class="flex flex-col gap-2">
      <div class="flex items-center justify-between">
        <h4 class="font-semibold">
          Bloques
        </h4>
        <div class="flex items-center">
          <BaseButton
            type="button"
            variant="outline"
            size="sm"
            @click="addPhase"
          >
            <Plus class="size-4" />
          </BaseButton>
          <BaseButton
            type="button"
            variant="outline"
            size="sm"
            @click="toggleAllPhases"
          >
            <component
              :is="allExpanded ? ChevronsDownUp : ChevronsUpDown"
              class="mr-1 size-4"
            />
          </BaseButton>
        </div>
      </div>

      <ul class="flex flex-col divide-y divide-gray-200">
        <li
          v-for="(phaseAttributes, index) in draggablePhasesAttributes"
          :id="`phase-${phaseAttributes.position}`"
          :key="phaseAttributes.position"
          :class="{ 'hidden': phaseAttributes._destroy }"
          draggable="true"
          @dragstart="onDragStart($event, phaseAttributes, index)"
          @dragover="onDragOver"
          @dragenter="onDragEnter"
          @dragleave="onDragLeave"
          @drop="onDrop($event, index)"
        >
          <RoutinePhaseForm
            v-if="editingPhaseIndex === index"
            :phase-id="phaseAttributes.id"
            :phases="props.routine.routinePhases"
            :phase-attributes="phaseAttributes"
            :open="editingPhaseIndex === index"
            @update="updatePhase($event, index)"
            @close="closePhaseForm"
          />
          <div class="group/phase flex w-full flex-col rounded-md py-1 pl-1">
            <div class="flex items-center justify-between">
              <div class="flex items-center gap-1">
                <GripVertical
                  class="size-4 shrink-0 cursor-move"
                />
                <h5 class="font-semibold">
                  {{ phaseAttributes.name || 'Bloque sin nombre' }}
                </h5>
              </div>
              <div class="flex items-center">
                <BaseButton
                  type="button"
                  variant="ghost"
                  size="sm"
                  @click="removePhase(index)"
                >
                  <Trash2 class="hidden size-4 group-hover/phase:inline-flex" />
                </BaseButton>
                <BaseButton
                  type="button"
                  variant="ghost"
                  size="sm"
                  @click="editPhase(index)"
                >
                  <Pencil class="hidden size-4 group-hover/phase:inline-flex" />
                </BaseButton>
                <BaseButton
                  v-if="phaseAttributes.id"
                  type="button"
                  variant="ghost"
                  size="sm"
                  @click="toggleExpand(index)"
                >
                  <component
                    :is="expandedPhases.has(index) ? ChevronUp : ChevronDown"
                    class="size-4"
                  />
                </BaseButton>
                <div
                  v-else
                  class="h-8 w-10"
                />
              </div>
            </div>
            <div v-if="expandedPhases.has(index)">
              <RoutineFormPhasesExercises
                v-if="phaseAttributes.id"
                :phase-id="phaseAttributes.id"
                :phases="props.routine.routinePhases"
              />
            </div>
            <span
              v-else
              class="flex w-full items-center justify-center gap-1 text-sm text-gray-500"
            >
              <span>
                {{ phaseAttributes.routinePhaseExercisesAttributes?.length }}
                {{ phaseAttributes.routinePhaseExercisesAttributes?.length == 1 ? 'ejercicio' : 'ejercicios' }}
              </span>
              <span>
                - {{ phaseAttributes.sets }}
                {{ phaseAttributes.sets == 1 ? 'serie' : 'series' }}
              </span>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </form>
</template>
