<script setup lang="ts">
import { computed } from 'vue';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import type { RoutinePhaseExercise, Workout } from '@/types/extended';
import type { ExerciseExecution } from '@/types/exercise-execution';
import WorkoutDetailsExerciseSet from './workout-details-exercise-set.vue';

const props = defineProps<{
  routinePhaseExercise: RoutinePhaseExercise;
  workout: Workout;
  phaseId: number;
  selectedTab: string;
  exerciseExecutions?: ExerciseExecution[];
}>();

const workoutPhase = computed(() => props.workout.workoutPhases.find(phase => phase.routinePhaseId === props.phaseId));
const exerciseExecutions = computed(() => props.exerciseExecutions || []);

const EXERCISE_EXECUTION_LIMIT = 5;
const lastFiveExecutions = computed(() => exerciseExecutions.value
  .filter(exerciseExecution => exerciseExecution.exerciseId === props.routinePhaseExercise.exerciseId)
  .slice(0, EXERCISE_EXECUTION_LIMIT));

function isCurrentPhaseExecution(execution: ExerciseExecution) {
  return execution.workoutPhaseId === workoutPhase.value?.id;
}

function getWorkoutExercise(setNumber: number) {
  if (!workoutPhase.value) return undefined;

  return workoutPhase.value.workoutExercises.find(
    we => we.routinePhaseExerciseId === props.routinePhaseExercise.id && we.setNumber === setNumber,
  );
}

function formatDate(date: Date) {
  return format(date, 'dd/MM', { locale: es });
}
</script>

<template>
  <div class="mb-5">
    <h5 class="mb-2 font-semibold">
      {{ routinePhaseExercise.exerciseName }}
    </h5>
    <div
      v-if="selectedTab === 'Series'"
      class="overflow-x-auto"
    >
      <table class="w-full table-fixed border-collapse bg-white text-xs">
        <thead>
          <tr class="bg-gray-100">
            <th class="w-14 border-b px-3 py-2 text-center font-medium text-gray-500" />
            <th class="border-b px-3 py-2 text-left font-medium">
              Reps
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Peso
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Esfuerzo
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Completado
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Comentario
            </th>
          </tr>
        </thead>
        <tbody>
          <WorkoutDetailsExerciseSet
            v-for="set in routinePhaseExercise.routineExerciseSets"
            :key="set.id"
            :set="set"
            :routine-phase-exercise="routinePhaseExercise"
            :workout-exercise="getWorkoutExercise(set.setNumber)"
          />
        </tbody>
      </table>
    </div>
    <div
      v-else-if="selectedTab === 'Ejecuciones'"
      class="overflow-x-auto"
    >
      <table class="w-full table-fixed border-collapse bg-white text-xs">
        <thead>
          <tr class="bg-gray-100">
            <th class="border-b px-3 py-2 text-left font-medium">
              Fecha
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              1RM estimado
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Reps
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Series
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Peso máximo
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Volumen
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Descanso series
            </th>
            <th class="border-b px-3 py-2 text-left font-medium">
              Promedio esfuerzo
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="execution in lastFiveExecutions"
            :key="execution.id"
            :class="[
              'border-b last:border-b-0',
              { 'bg-blue-100': isCurrentPhaseExecution(execution) }
            ]"
          >
            <td class="px-3 py-2">
              {{ formatDate(new Date(execution.executionDate)) }}
            </td>
            <td class="px-3 py-2">
              {{ execution.estimatedOneRepetitionMax?.toFixed(1) }} kg
            </td>
            <td class="px-3 py-2">
              {{ execution.totalRepetitions }}
            </td>
            <td class="px-3 py-2">
              {{ execution.sets }}
            </td>
            <td class="px-3 py-2">
              {{ execution.maxWeight?.toFixed(1) }} kg
            </td>
            <td class="px-3 py-2">
              {{ execution.totalVolume?.toFixed(1) }} kg
            </td>
            <td class="px-3 py-2">
              {{ execution.restDuration }}s
            </td>
            <td class="px-3 py-2">
              {{ execution.averageEffort ? `${execution.averageEffort?.toFixed(0)}%` : '-' }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
