<script setup lang="ts">
import { useAttrs, computed, ref, toRef, watch } from 'vue';
import { useField } from 'vee-validate';
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from '@headlessui/vue';
import { Check, ChevronDown } from 'lucide-vue-next';

function useAttrsWithoutClass() {
  const attrs = useAttrs();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return Object.fromEntries(Object.entries(attrs).filter(([key, _]) => key !== 'class'));
}

declare interface SelectOption {
  name: string
  id: number | string
}

interface Props {
  modelValue?: string | number
  options: SelectOption[]
  label?: string
  placeholder?: string
  name: string
  required?: boolean
  showAddNewButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: undefined,
  placeholder: 'Buscar',
  label: undefined,
  showAddNewButton: false,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number): void
  (e: 'new'): void
}>();

const name = toRef(props, 'name');

const {
  meta,
  value: inputValue,
  errorMessage,
} = useField(name, undefined, {
  initialValue: props.modelValue,
  syncVModel: true,
});

const query = ref('');

const filteredOptions = computed(() => {
  const MAX_OPTIONS_TO_SHOW = 50;
  const normalizedQuery = query.value.toLowerCase().replace(/\s+/g, '');

  return query.value === '' ?
    props.options.slice(0, MAX_OPTIONS_TO_SHOW) :
    props.options
      .filter((option) =>
        option.name.toLowerCase().replace(/\s+/g, '').includes(normalizedQuery),
      )
      .slice(0, MAX_OPTIONS_TO_SHOW);
});

const selectedOption = computed(() => props.options.find(option => option.id === inputValue.value));

const displayValue = computed(() => selectedOption.value?.name || query.value);

const noResults = computed(() => filteredOptions.value.length === 0 && query.value !== '');

const attrsWithoutClass = useAttrsWithoutClass();
const disabled = computed(() => !!attrsWithoutClass.disabled);

watch(inputValue, (value) => {
  emit('update:modelValue', value);
});

</script>

<template>
  <div :class="$attrs.class">
    <Combobox
      v-model="inputValue"
      :name="name"
      :disabled="disabled"
      as="div"
      class="relative"
    >
      <div class="rounded border border-slate-200 bg-white text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-slate-950 focus-within:ring-offset-2">
        <div class="flex w-full items-center justify-between">
          <ComboboxInput
            v-bind="attrsWithoutClass"
            class="w-full px-3 py-2 outline-none disabled:bg-gray-100 disabled:text-gray-500"
            :display-value="() => displayValue"
            :placeholder="placeholder"
            autocomplete="off"
            @change="query = $event.target.value"
          />
          <ComboboxButton
            class="px-3 py-2"
            :disabled="disabled"
          >
            <ChevronDown class="size-4" />
          </ComboboxButton>
        </div>
      </div>
      <ComboboxOptions
        class="absolute z-50 mt-1 flex max-h-80 w-full flex-col gap-1 overflow-auto rounded border border-gray-300 bg-white p-2 text-left text-xs font-normal shadow focus:outline-none"
      >
        <ComboboxOption
          v-for="option in filteredOptions"
          :key="option.id"
          v-slot="{ active, selected }"
          :value="option.id"
          as="template"
        >
          <div
            class="flex cursor-pointer items-center gap-2 rounded px-2 py-3"
            :class="{'bg-gray-100': active}"
          >
            <Check
              v-if="selected"
              class="size-4"
            />
            <div
              v-else
              class="size-4"
            />
            <span>{{ option.name }}</span>
          </div>
        </ComboboxOption>
        <div
          v-if="noResults"
          class="flex cursor-pointer justify-between px-2 py-3"
        >
          <p>No hay resultados</p>
          <button
            v-if="showAddNewButton"
            class="text-sky-400"
            type="button"
            @click="emit('new')"
          >
            Agregar nuevo
          </button>
        </div>
      </ComboboxOptions>
    </Combobox>
    <p
      v-show="errorMessage && meta.touched"
      class="text-right text-xs text-red-600 md:col-span-2"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
