<script setup lang="ts">
import { format, isYesterday, isToday, isTomorrow } from 'date-fns';
import { computed, ref, onMounted, watch } from 'vue';
import type { Routine, WorkoutPlan, Workout } from '@/types/extended';
import { Circle, CircleCheck, ChevronDown, ChevronUp, ArrowRight } from 'lucide-vue-next';
import { BaseLink } from '@/components';

const PERCENTAGE_MULTIPLIER = 100;

const props = defineProps<{
  routines: Routine[],
  workouts: Workout[],
  workoutPlans: WorkoutPlan[],
}>();

const activeTab = ref('workouts');
const completionStatus = ref<Record<string, boolean>>({});

const showPastToday = ref(true);
const showUpcomingToday = ref(false);
const showYesterdayCompleted = ref(false);
const showYesterdayNotCompleted = ref(false);
const showTodayCompleted = ref(true);
const showTodayNotCompleted = ref(true);

const groupedRoutines = computed(() => {
  const groups: {
    yesterdayCompleted: Routine[],
    yesterdayNotCompleted: Routine[],
    todayCompleted: Routine[],
    todayNotCompleted: Routine[],
    tomorrow: Routine[],
  } = {
    yesterdayCompleted: [],
    yesterdayNotCompleted: [],
    todayCompleted: [],
    todayNotCompleted: [],
    tomorrow: [],
  };

  props.routines.forEach(routine => {
    const date = new Date(routine.scheduledAt);
    if (isYesterday(date)) {
      if (routine.isCompleted) {
        groups.yesterdayCompleted.push(routine);
      } else {
        groups.yesterdayNotCompleted.push(routine);
      }
    } else if (isToday(date)) {
      if (routine.isCompleted) {
        groups.todayCompleted.push(routine);
      } else {
        groups.todayNotCompleted.push(routine);
      }
    } else if (isTomorrow(date)) {
      groups.tomorrow.push(routine);
    }
  });

  // Sort today's routines by scheduledAt
  groups.todayCompleted.sort((a, b) => new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime());
  groups.todayNotCompleted.sort((a, b) => new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime());

  return groups;
});

// eslint-disable-next-line max-statements
const columnStats = computed(() => {
  const stats: Record<string, { total: number; completed: number; percentage: number }> = {};

  Object.entries(groupedRoutines.value).forEach(([day, routines]) => {
    const total = routines.length;
    const completed = routines.filter(r => r.isCompleted).length;
    const percentage = total > 0 ? Math.round((completed / total) * PERCENTAGE_MULTIPLIER) : 0;

    stats[day] = { total, completed, percentage };
  });

  // Calculate today's stats
  const todayTotal = groupedRoutines.value.todayCompleted.length + groupedRoutines.value.todayNotCompleted.length;
  const todayCompleted = groupedRoutines.value.todayCompleted.length;
  const todayPercentage = todayTotal > 0 ? Math.round((todayCompleted / todayTotal) * PERCENTAGE_MULTIPLIER) : 0;

  stats.today = { total: todayTotal, completed: todayCompleted, percentage: todayPercentage };

  // Calculate yesterday's stats
  const yesterdayTotal =
    groupedRoutines.value.yesterdayCompleted.length + groupedRoutines.value.yesterdayNotCompleted.length;
  const yesterdayCompleted = groupedRoutines.value.yesterdayCompleted.length;
  const yesterdayPercentage = yesterdayTotal > 0 ?
    Math.round((yesterdayCompleted / yesterdayTotal) * PERCENTAGE_MULTIPLIER) :
    0;

  stats.yesterday = { total: yesterdayTotal, completed: yesterdayCompleted, percentage: yesterdayPercentage };

  return stats;
});

function formatTime(date: string): string {
  return format(new Date(date), 'HH:mm');
}

function traineeName(workoutOrRoutine: Workout | Routine): string {
  const trainees = props.workoutPlans?.flatMap(wp => wp.trainees) || [];
  if ('routineId' in workoutOrRoutine) {
    // For Workouts, find the trainee by traineeId
    const trainee = trainees.find(t => t.id === workoutOrRoutine.traineeId);

    return trainee?.fullName || 'Unknown';
  }
  const workoutPlan = props.workoutPlans.find(wp => wp.id === workoutOrRoutine.workoutPlanId);

  return workoutPlan?.trainees[0]?.fullName || 'Unknown';
}

const columnTitles = {
  yesterday: 'Ayer',
  today: 'Hoy',
  tomorrow: 'Mañana',
};

// eslint-disable-next-line complexity
function toggleSection(
  section:
    'past' | 'upcoming' | 'yesterdayCompleted' | 'yesterdayNotCompleted' | 'todayCompleted' | 'todayNotCompleted',
) {
  switch (section) {
  case 'past':
    showPastToday.value = !showPastToday.value;
    break;
  case 'upcoming':
    showUpcomingToday.value = !showUpcomingToday.value;
    break;
  case 'yesterdayCompleted':
    showYesterdayCompleted.value = !showYesterdayCompleted.value;
    break;
  case 'yesterdayNotCompleted':
    showYesterdayNotCompleted.value = !showYesterdayNotCompleted.value;
    break;
  case 'todayCompleted':
    showTodayCompleted.value = !showTodayCompleted.value;
    break;
  case 'todayNotCompleted':
    showTodayNotCompleted.value = !showTodayNotCompleted.value;
    break;
  default:
    break;
  }
}

onMounted(() => {
  const savedStatus = localStorage.getItem('completionStatus');
  if (savedStatus) {
    completionStatus.value = JSON.parse(savedStatus);
  }
});

watch(completionStatus, (newStatus) => {
  localStorage.setItem('completionStatus', JSON.stringify(newStatus));
}, { deep: true });

function toggleCompletion(id: string) {
  completionStatus.value[id] = !completionStatus.value[id];

  // If it's a workout, also toggle the corresponding routine
  if (id.startsWith('workout_')) {
    const workoutId = parseInt(id.split('_')[1], 10);
    const workout = props.workouts.find(w => w.id === workoutId);
    if (workout && workout.routineId) {
      const routineId = `routine_${workout.routineId}`;
      completionStatus.value[routineId] = completionStatus.value[id];
    }
  }
}

function isCompleted(id: string) {
  return completionStatus.value[id] || false;
}

const groupedWorkouts = computed(() => {
  const groups = {
    yesterday: [] as Workout[],
    today: [] as Workout[],
  };

  props.workouts.forEach(workout => {
    const date = new Date(workout.endedAt || workout.startedAt || new Date());
    if (isYesterday(date)) {
      groups.yesterday.push(workout);
    } else if (isToday(date)) {
      groups.today.push(workout);
    }
  });

  return groups;
});

const workoutStats = computed(() => {
  const stats: Record<string, { total: number }> = {};

  Object.entries(groupedWorkouts.value).forEach(([day, workouts]) => {
    stats[day] = { total: workouts.length };
  });

  return stats;
});

function formatTimeRange(startedAt: string, endedAt: string | null): string {
  const start = format(new Date(startedAt), 'HH:mm');
  const end = endedAt ? format(new Date(endedAt), 'HH:mm') : 'In progress';

  return `${start} - ${end}`;
}

function resetTrainerChecks() {
  completionStatus.value = {};
  localStorage.removeItem('completionStatus');
}

</script>

<template>
  <div>
    <div class="mb-6 flex items-center justify-between border-b border-gray-200">
      <nav
        class="-mb-px flex space-x-8"
        aria-label="Tabs"
      >
        <button
          v-for="tab in ['workouts', 'routines']"
          :key="tab"
          :class="[
            activeTab === tab
              ? 'border-indigo-500 text-indigo-600'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
          ]"
          @click="activeTab = tab"
        >
          {{ tab === 'workouts' ? 'Entrenamientos realizados' : 'Rutinas agendadas' }}
        </button>
      </nav>
      <a
        href="#"
        class="rounded border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
        @click.prevent="resetTrainerChecks"
      >
        Resetear checks
      </a>
    </div>

    <div
      v-if="activeTab === 'workouts'"
      class="flex gap-10"
    >
      <div
        v-for="(dayWorkouts, day) in groupedWorkouts"
        :key="day"
        class="flex-1"
      >
        <div class="mb-2 flex items-center justify-between">
          <h2 class="font-semibold capitalize">
            {{ day === 'yesterday' ? 'Ayer' : 'Hoy' }}
          </h2>
          <p class="text-sm text-gray-600">
            Total: {{ workoutStats[day].total }}
          </p>
        </div>
        <ul class="space-y-2">
          <li
            v-for="workout in dayWorkouts"
            :key="workout.id"
            class="flex items-center justify-between gap-2 rounded border bg-white px-4 py-3 shadow-sm"
          >
            <div class="flex w-full items-center gap-2">
              <CircleCheck
                class="size-6 shrink-0 rounded-full bg-green-500 text-white"
              />
              <div class="flex flex-col">
                <span class="text-sm font-semibold">{{ traineeName(workout) }}</span>
                <span class="text-xs text-gray-600">
                  {{ formatTimeRange(workout.startedAt || '', workout.endedAt) }} -
                  {{ workout.routine?.name || 'Unknown Routine' }}
                </span>
              </div>
              <BaseLink
                :href="`/workouts/${workout.id}`"
                class="mx-auto"
              >
                Ver entrenamiento <ArrowRight class="size-4" />
              </BaseLink>
            </div>
            <button
              class="focus:outline-none"
              @click="toggleCompletion(`workout_${workout.id}`)"
            >
              <CircleCheck
                v-if="isCompleted(`workout_${workout.id}`)"
                class="size-6 shrink-0 rounded-full bg-blue-500 text-white"
              />
              <Circle
                v-else
                class="size-6 shrink-0 text-gray-400"
              />
            </button>
          </li>
        </ul>
      </div>
    </div>

    <div v-else-if="activeTab === 'routines'">
      <div class="flex gap-10">
        <div
          v-for="(dayRoutines, day) in {
            yesterday: [...groupedRoutines.yesterdayCompleted, ...groupedRoutines.yesterdayNotCompleted],
            today: [...groupedRoutines.todayCompleted, ...groupedRoutines.todayNotCompleted],
            tomorrow: groupedRoutines.tomorrow
          }"
          :key="day"
          class="flex-1"
        >
          <div class="mb-2 flex items-center justify-between">
            <h2 class="font-semibold capitalize">
              {{ columnTitles[day as keyof typeof columnTitles] }}
            </h2>
            <p class="text-sm text-gray-600">
              {{ columnStats[day].percentage }}% ({{ columnStats[day].completed }}/{{ columnStats[day].total }})
            </p>
          </div>
          <template v-if="day === 'yesterday'">
            <div class="mb-2">
              <button
                class="flex items-center text-sm text-gray-600"
                @click="toggleSection('yesterdayCompleted')"
              >
                <component
                  :is="showYesterdayCompleted ? ChevronUp : ChevronDown"
                  class="mr-1 size-4"
                />
                Completadas ({{ groupedRoutines.yesterdayCompleted.length }})
              </button>
              <ul
                v-if="showYesterdayCompleted"
                class="mt-2 space-y-2"
              >
                <li
                  v-for="routine in groupedRoutines.yesterdayCompleted"
                  :key="routine.id"
                  class="flex items-center justify-between gap-2 rounded border bg-white px-4 py-3 shadow-sm"
                >
                  <div class="flex items-center gap-2">
                    <CircleCheck
                      class="size-6 shrink-0 rounded-full bg-green-500 text-white"
                    />
                    <div class="flex flex-col">
                      <span class="text-sm font-semibold">{{ traineeName(routine) }}</span>
                      <span class="text-xs text-gray-600">{{ formatTime(routine.scheduledAt) }} - {{ routine.name }}</span>
                    </div>
                  </div>
                  <button
                    class="focus:outline-none"
                    @click="toggleCompletion(`routine_${routine.id}`)"
                  >
                    <CircleCheck
                      v-if="isCompleted(`routine_${routine.id}`)"
                      class="size-6 shrink-0 rounded-full bg-blue-500 text-white"
                    />
                    <Circle
                      v-else
                      class="size-6 shrink-0 text-gray-400"
                    />
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <button
                class="flex items-center text-sm text-gray-600"
                @click="toggleSection('yesterdayNotCompleted')"
              >
                <component
                  :is="showYesterdayNotCompleted ? ChevronUp : ChevronDown"
                  class="mr-1 size-4"
                />
                No completadas ({{ groupedRoutines.yesterdayNotCompleted.length }})
              </button>
              <ul
                v-if="showYesterdayNotCompleted"
                class="mt-2 space-y-2"
              >
                <li
                  v-for="routine in groupedRoutines.yesterdayNotCompleted"
                  :key="routine.id"
                  class="flex items-center justify-between gap-2 rounded border bg-white px-4 py-3 shadow-sm"
                >
                  <div class="flex items-center gap-2">
                    <Circle
                      class="size-6 shrink-0 text-gray-400"
                    />
                    <div class="flex flex-col">
                      <span class="text-sm font-semibold">{{ traineeName(routine) }}</span>
                      <span class="text-xs text-gray-600">{{ formatTime(routine.scheduledAt) }} - {{ routine.name }}</span>
                    </div>
                  </div>
                  <button
                    class="focus:outline-none"
                    @click="toggleCompletion(`routine_${routine.id}`)"
                  >
                    <CircleCheck
                      v-if="isCompleted(`routine_${routine.id}`)"
                      class="size-6 shrink-0 rounded-full bg-blue-500 text-white"
                    />
                    <Circle
                      v-else
                      class="size-6 shrink-0 text-gray-400"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </template>
          <template v-else-if="day === 'today'">
            <div class="mb-2">
              <button
                class="flex items-center text-sm text-gray-600"
                @click="toggleSection('todayCompleted')"
              >
                <component
                  :is="showTodayCompleted ? ChevronUp : ChevronDown"
                  class="mr-1 size-4"
                />
                Completadas ({{ groupedRoutines.todayCompleted.length }})
              </button>
              <ul
                v-if="showTodayCompleted"
                class="mt-2 space-y-2"
              >
                <li
                  v-for="routine in groupedRoutines.todayCompleted"
                  :key="routine.id"
                  class="flex items-center justify-between gap-2 rounded border bg-white px-4 py-3 shadow-sm"
                >
                  <div class="flex items-center gap-2">
                    <CircleCheck
                      class="size-6 shrink-0 rounded-full bg-green-500 text-white"
                    />
                    <div class="flex flex-col">
                      <span class="text-sm font-semibold">{{ traineeName(routine) }}</span>
                      <span class="text-xs text-gray-600">{{ formatTime(routine.scheduledAt) }} - {{ routine.name }}</span>
                    </div>
                  </div>
                  <button
                    class="focus:outline-none"
                    @click="toggleCompletion(`routine_${routine.id}`)"
                  >
                    <CircleCheck
                      v-if="isCompleted(`routine_${routine.id}`)"
                      class="size-6 shrink-0 rounded-full bg-blue-500 text-white"
                    />
                    <Circle
                      v-else
                      class="size-6 shrink-0 text-gray-400"
                    />
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <button
                class="flex items-center text-sm text-gray-600"
                @click="toggleSection('todayNotCompleted')"
              >
                <component
                  :is="showTodayNotCompleted ? ChevronUp : ChevronDown"
                  class="mr-1 size-4"
                />
                No completadas ({{ groupedRoutines.todayNotCompleted.length }})
              </button>
              <ul
                v-if="showTodayNotCompleted"
                class="mt-2 space-y-2"
              >
                <li
                  v-for="routine in groupedRoutines.todayNotCompleted"
                  :key="routine.id"
                  class="flex items-center justify-between gap-2 rounded border bg-white px-4 py-3 shadow-sm"
                >
                  <div class="flex items-center gap-2">
                    <Circle
                      class="size-6 shrink-0 text-gray-400"
                    />
                    <div class="flex flex-col">
                      <span class="text-sm font-semibold">{{ traineeName(routine) }}</span>
                      <span class="text-xs text-gray-600">{{ formatTime(routine.scheduledAt) }} - {{ routine.name }}</span>
                    </div>
                  </div>
                  <button
                    class="focus:outline-none"
                    @click="toggleCompletion(`routine_${routine.id}`)"
                  >
                    <CircleCheck
                      v-if="isCompleted(`routine_${routine.id}`)"
                      class="size-6 shrink-0 rounded-full bg-blue-500 text-white"
                    />
                    <Circle
                      v-else
                      class="size-6 shrink-0 text-gray-400"
                    />
                  </button>
                </li>
              </ul>
            </div>
          </template>
          <ul
            v-else
            class="space-y-2"
          >
            <li
              v-for="routine in dayRoutines"
              :key="routine.id"
              class="flex items-center justify-between gap-2 rounded border bg-white px-4 py-3 shadow-sm"
            >
              <div class="flex items-center gap-2">
                <Circle class="size-6 shrink-0 text-gray-400" />
                <div class="flex flex-col">
                  <span class="text-sm font-semibold">{{ traineeName(routine) }}</span>
                  <span class="text-xs text-gray-600">{{ formatTime(routine.scheduledAt) }} - {{ routine.name }}</span>
                </div>
              </div>
              <button
                class="focus:outline-none"
                @click="toggleCompletion(`routine_${routine.id}`)"
              >
                <CircleCheck
                  v-if="isCompleted(`routine_${routine.id}`)"
                  class="size-6 shrink-0 rounded-full bg-blue-500 text-white"
                />
                <Circle
                  v-else
                  class="size-6 shrink-0 text-gray-400"
                />
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
