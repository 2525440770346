import axios, { type AxiosRequestTransformer, type AxiosResponseTransformer } from 'axios';
import qs from 'qs';
import { convertKeys, type ObjectToConvert } from '../utils/case-converter';
import { csrfToken } from '../utils/csrf-token';

const api = axios.create({
  transformRequest: [
    (data: ObjectToConvert) => convertKeys(data, 'decamelize'),
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
    (data: ObjectToConvert) => convertKeys(data, 'camelize'),
  ],
  headers: {
    'Accept': 'application/json',
    'X-CSRF-Token': csrfToken(),
  },
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets', indices: true }),
});

export { api };
