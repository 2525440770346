<script setup lang="ts">
import { computed, ref } from 'vue';
import { format } from 'date-fns';
import { FoldVertical, UnfoldVertical } from 'lucide-vue-next';
import type { Routine, Workout } from '@/types/extended';
import { useRoutinesStore } from '@/stores/routines';
import { analytics } from '@/utils/analytics';
import RoutinePhaseDetails from './routine-phase-details.vue';
import RoutineDetailsMenu from './routine-details-menu.vue';
import AudioRecorder from './audio-recorder.vue';
import { BaseButton } from '.';
import WorkoutSummary from './workout-summary.vue';

const props = defineProps<{
  routine: Routine,
  isPersonalizedWorkoutPlan: boolean,
}>();

const routinesStore = useRoutinesStore();

const isMinimized = ref(true);
const completedWorkouts = props.routine.workouts.filter(workout => workout.endedAt !== null);
const lastCompletedWorkout: Workout | undefined = completedWorkouts[completedWorkouts.length - 1];

const difficultyLabels: Record<number, string> = {
  0: 'Muy fácil 🥱',
  1: 'Muy fácil 🥱',
  2: 'Fácil 🥱',
  3: 'Fácil 🥱',
  4: 'Moderado 👍',
  5: 'Moderado 👍',
  6: 'Difícil 😅',
  7: 'Difícil 😅',
  8: 'Muy difícil 🥵',
  9: 'Muy difícil 🥵',
  10: 'Máximo esfuerzo 💀',
};

const editMode = ref(false);
const showWorkoutSummary = ref(false);

// eslint-disable-next-line max-statements
function areRoutinePositionsRight(routine: Routine) {
  let errorDetected = false;

  function areConsecutiveIfSorted(arr: { position: number | string }[]) {
    const sortedArr = [...arr].sort((a, b) => Number(a.position) - Number(b.position));

    for (let i = 0; i < sortedArr.length; i++) {
      if (Number(sortedArr[i].position) !== i + 1) {
        return false;
      }
    }

    return true;
  }

  for (let phaseIndex = 0; phaseIndex < routine.routinePhases.length; phaseIndex++) {
    const phase = routine.routinePhases[phaseIndex];

    if (!errorDetected && !areConsecutiveIfSorted(routine.routinePhases)) {
      // eslint-disable-next-line no-alert
      alert('ERROR: Las posiciones de los bloques no son consecutivas.');
      errorDetected = true;
      break;
    }

    if (!errorDetected && !areConsecutiveIfSorted(phase.routinePhaseExercises)) {
      // eslint-disable-next-line no-alert
      alert(`ERROR: Las posiciones de los ejercicios de ${phase.name} no son consecutivas.`);
      errorDetected = true;
      break;
    }
  }

  return !errorDetected;
}

function saveRoutine() {
  if (areRoutinePositionsRight(props.routine)) {
    routinesStore.saveRoutine(props.routine);
    if (analytics) analytics.capture('save_routine_click', { routineId: props.routine.id });
  }
}

function toggleEditMode() {
  editMode.value = !editMode.value;
  isMinimized.value = false;
}

function toggleMinimize() {
  isMinimized.value = !isMinimized.value;
  if (isMinimized.value) {
    editMode.value = false;
  }
}

function addEmptyPhase() {
  routinesStore.addEmptyPhase(props.routine.id);
}

function handleInputChange(event: Event, keyName: string): void {
  const target = event.target as HTMLInputElement;
  routinesStore.updateRoutine({ ...props.routine, [keyName]: target.value });
}

function handleFileInputChange(event: Event, keyName: string): void {
  const target = event.target as HTMLInputElement;
  routinesStore.updateRoutine({ ...props.routine, [keyName]: target.files?.[0] });
}

function handleAudioChange(audio: Blob) {
  const file = new File([audio], 'audio.wav', { type: 'audio/wav' });
  routinesStore.updateRoutine({ ...props.routine, audio: file });
}

const audioUrl = computed(() => {
  if (props.routine.audio) {
    return URL.createObjectURL(props.routine.audio);
  }

  return props.routine.audioUrl;
});

function toggleWorkoutSummary() {
  showWorkoutSummary.value = !showWorkoutSummary.value;
}

function closeWorkoutSummary() {
  showWorkoutSummary.value = false;
}

</script>

<template>
  <div class="flex flex-col gap-y-3">
    <div class="flex w-full flex-col gap-2">
      <div class="flex w-full items-center justify-between gap-x-2">
        <div class="flex items-center gap-x-2">
          <h2 class="text-base font-semibold">
            <template v-if="editMode">
              <input
                :value="routine.name"
                class="w-72 border-2 bg-white p-1"
                @input="event => handleInputChange(event, 'name')"
              >
            </template>
            <template v-else>
              {{ routine.name }}
            </template>
          </h2>
        </div>

        <div class="flex items-center gap-x-2">
          <BaseButton
            variant="secondary"
            size="sm"
            @click="toggleEditMode"
          >
            {{ editMode ? '📄 Volver a solo leer' : '✏️ Editar' }}
          </BaseButton>
          <BaseButton
            v-if="editMode"
            size="sm"
            @click="saveRoutine"
          >
            💾 Guardar
          </BaseButton>
          <BaseButton
            v-else
            size="sm"
            variant="secondary"
            @click="toggleMinimize"
          >
            <UnfoldVertical
              v-if="isMinimized"
              class="size-4"
            />
            <FoldVertical
              v-else
              class="size-4"
            />
          </BaseButton>
          <RoutineDetailsMenu
            :routine="routine"
          />
        </div>
      </div>
      <div class="grid grid-cols-6 gap-4">
        <div class="flex flex-col">
          <input
            v-if="editMode"
            :value="format(new Date(routine.scheduledAt), 'yyyy-MM-dd\'T\'HH:mm')"
            type="datetime-local"
            class="w-48 border-2 bg-white p-1 text-sm"
            @input="event => handleInputChange(event, 'scheduledAt')"
          >
          <span
            v-else
            class="text-sm"
          >{{ format(routine.scheduledAt, 'EEEE, dd MMM HH:mm') }}</span>
          <input
            v-if="editMode"
            type="number"
            placeholder="Duración en minutos"
            :value="routine.durationInMinutes"
            class="w-48 border-2 bg-white p-1 text-sm"
            @input="event => handleInputChange(event, 'durationInMinutes')"
          >
          <span
            v-else
            class="text-sm"
          >
            {{ routine.durationInMinutes }} minutos 🕐
          </span>
          <template v-if="editMode">
            <select
              :value="routine.isPublished"
              class="w-48 border-2 bg-white p-1 text-sm"
              @input="event => handleInputChange(event, 'isPublished')"
            >
              <option
                disabled
                value=""
              >
                Selecciona una
              </option>
              <option value="true">
                Publicada
              </option>
              <option value="false">
                No publicada
              </option>
            </select>
          </template>
          <template v-else>
            <span class="text-sm">{{ routine.isPublished ? '✅ Publicada' : '⚠️ No publicada' }}</span>
            <span
              v-if="props.isPersonalizedWorkoutPlan"
              class="text-sm"
            >
              {{ routine.isCompleted ? '✅ Completada' : '⚠️ Pendiente' }}
            </span>
          </template>
        </div>
        <template v-if="editMode">
          <textarea
            :value="routine.trainerNote"
            :placeholder="'Nota para el entrenador'"
            class="w-48 border-2 bg-white p-1 text-sm"
            @input="event => handleInputChange(event, 'trainerNote')"
          />
        </template>
        <template v-else>
          <div
            v-if="routine.trainerNote"
            class="flex flex-col rounded-lg border bg-white p-2 text-xs"
          >
            <span class="w-48 font-semibold text-gray-500">
              Nota para el entrenador
            </span>
            <p>
              {{ routine.trainerNote }}
            </p>
          </div>
        </template>
        <div
          v-if="props.isPersonalizedWorkoutPlan && lastCompletedWorkout"
          class="col-span-2 col-start-5 flex items-start rounded-lg border bg-white p-2 text-xs"
        >
          <div class="flex flex-col">
            <span class="mb-2 w-48 font-semibold text-gray-500">
              Feedback trainee
            </span>
            <span
              v-if="lastCompletedWorkout.perceivedExertion"
              class="mb-1"
            >
              Esfuerzo: {{ lastCompletedWorkout.perceivedExertion }}
              ({{ difficultyLabels[lastCompletedWorkout.perceivedExertion] }})
            </span>
          </div>
          <BaseButton
            size="sm"
            variant="secondary"
            @click="toggleWorkoutSummary"
          >
            📊 Ver detalles
          </BaseButton>
        </div>
      </div>
      <template v-if="routine.routinePhases.length === 0">
        <p>No hay fases para mostrar</p>
      </template>
      <template v-else>
        <div
          v-if="!isMinimized"
          class="flex h-full flex-col gap-2"
        >
          <div
            v-if="audioUrl || editMode"
            class="flex items-center gap-2 rounded-lg border bg-white p-2"
          >
            <audio
              v-if="audioUrl"
              :src="audioUrl"
              controls
            />
            <template v-if="editMode">
              <audio-recorder
                @update="handleAudioChange"
              />
              <input
                type="file"
                accept="audio/*"
                @change="event => handleFileInputChange(event, 'audio')"
              >
            </template>
          </div>
          <div class="flex flex-1 flex-col gap-2">
            <div
              v-for="phase in routine.routinePhases"
              :key="phase.id"
              class="flex flex-col"
            >
              <routine-phase-details
                :phase="phase"
                :edit-mode="editMode"
              />
            </div>
          </div>
        </div>
      </template>
      <div class="flex">
        <BaseButton
          v-if="editMode"
          variant="outline"
          size="sm"
          @click="addEmptyPhase"
        >
          Nueva fase
        </BaseButton>
      </div>
    </div>
    <WorkoutSummary
      v-if="showWorkoutSummary && lastCompletedWorkout"
      :routine="routine"
      :workout="lastCompletedWorkout"
      @close="closeWorkoutSummary"
    />
  </div>
</template>
