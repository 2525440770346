import { convertKeys, type ObjectToConvert } from '@/utils/case-converter';
import { csrfToken } from '@/utils/csrf-token';

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

// eslint-disable-next-line max-statements
async function fetchWrapper({
  url,
  method,
  headers,
  params,
  body,
}: {
  url: string;
  method: RequestMethod;
  headers?: Record<string, string>;
  params?: Record<string, unknown>;
  body?: Record<string, unknown>;
}) {
  const defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken(),
  };

  const allHeaders = { ...defaultHeaders, ...headers };

  let fullUrl = url;
  if (params) {
    const queryParams = new URLSearchParams(convertKeys(params, 'decamelize') as Record<string, string>).toString();
    fullUrl += `?${queryParams}`;
  }

  const fetchOptions: RequestInit = {
    method,
    headers: allHeaders,
    body: body ? JSON.stringify(convertKeys(body, 'decamelize')) : undefined,
  };

  const response = await fetch(fullUrl, fetchOptions);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.includes('application/json')) {
    const data = await response.json();

    return convertKeys(data, 'camelize') as ObjectToConvert;
  }

  return response;
}

type FetchOptions = {
  url: string;
  headers?: Record<string, string>;
  params?: Record<string, unknown>;
  body?: Record<string, unknown>;
};

export const api = {
  get: ({ url, headers, params }: FetchOptions) =>
    fetchWrapper({ url, method: 'GET', headers, params }),
  post: ({ url, body, headers }: FetchOptions) =>
    fetchWrapper({ url, method: 'POST', body, headers }),
  put: ({ url, body, headers }: FetchOptions) =>
    fetchWrapper({ url, method: 'PUT', body, headers }),
  delete: ({ url, headers }: FetchOptions) =>
    fetchWrapper({ url, method: 'DELETE', headers }),
  patch: ({ url, body, headers }: FetchOptions) =>
    fetchWrapper({ url, method: 'PATCH', body, headers }),
};
