<script setup lang="ts">
import { ref } from 'vue';
import type { Trainer } from '@/types/';
import { trainerSelectionApi } from '@/api/trainer-selection';

const props = defineProps<{
  trainers: Trainer[];
  email: string;
}>();

const selectedTrainer = ref<Trainer>(props.trainers[0]);
const isTrainerChosen = ref(false);

function selectTrainer(trainer: Trainer) {
  selectedTrainer.value = trainer;
}

function chooseTrainer(trainer: Trainer) {
  isTrainerChosen.value = true;

  trainerSelectionApi.create({
    trainerId: trainer.id,
    email: props.email,
  }).then(() => {
    document.cookie = `lead_email=${props.email}; path=/`;
    window.location.href = '/onboarding/checkout/personalized';
  });
}

</script>

<template>
  <div class="h-full px-6">
    <h2 class="mb-4 text-3xl font-bold">
      Elige a tu coach
    </h2>
    <p class="mb-4 flex flex-col leading-6">
      <span>De acuerdo a tus respuestas, creemos que estos son los mejores coaches para ti.</span>
      <span>Selecciona uno para empezar. Después puedes cambiar si quieres!</span>
    </p>
    <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      <a
        v-for="(trainer, index) in trainers"
        :key="trainer.id"
        class="cursor-pointer rounded-lg border-2 bg-white p-4 shadow-md"
        :class="selectedTrainer?.id === trainer.id ? 'border-black' : 'border-white bg-white'"
        @click="selectTrainer(trainer)"
      >
        <div class="relative">
          <img
            :src="trainer.avatarUrl"
            alt="Trainer image"
            class="h-64 w-full rounded-t-lg object-cover"
          >
          <div
            v-if="selectedTrainer?.id === trainer.id"
            class="absolute right-4 top-4"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="size-6 text-black"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-col gap-y-2 p-4">
          <h3 class="flex gap-x-1 text-xl font-semibold">
            <span>{{ index+ 1 }}.</span>
            <span>{{ trainer.fullName }}</span>
          </h3>
          <p class="whitespace-pre-line text-sm text-gray-600">
            {{ trainer.bio }}
          </p>
        </div>
      </a>
    </div>
    <div
      v-if="selectedTrainer"
      class="mt-8 flex flex-col items-center"
    >
      <button
        class="rounded-lg bg-gray-900 px-4 py-3 text-white hover:bg-gray-600"
        @click="chooseTrainer(selectedTrainer)"
      >
        <span v-if="isTrainerChosen">Cargando...</span>
        <span v-else>Entrenar con {{ selectedTrainer.fullName }}</span>
      </button>
    </div>
  </div>
</template>
