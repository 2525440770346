<script setup lang="ts">
import { ref, computed, provide } from 'vue';
import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { format, startOfWeek, endOfWeek, addWeeks, subWeeks, parseISO } from 'date-fns';
import { useUrlSearchParams } from '@vueuse/core';
import { ChevronLeft, ChevronRight, MapPin, Calendar, Smartphone, Dumbbell, Target } from 'lucide-vue-next';
import type { Routine, WorkoutPlan, Trainee } from '@/types/extended';
import { routinesApi } from '@/api/routines';
import { BaseMenu, BaseButton, RoutineForm } from '@/components';
import RoutineGeneratorModal from './routine-generator-modal.vue';
import NewRoutineModal from './new-routine-modal.vue';
import CloneAllRoutinesModal from './clone-all-routines-modal.vue';
import PublishAllRoutinesModal from './publish-all-routines-modal.vue';

interface Props {
  workoutPlan: WorkoutPlan;
  routines: Routine[];
  trainee: Trainee;
}

const props = defineProps<Props>();

provide('traineeId', computed(() => props.trainee.id));

const searchParams = useUrlSearchParams('history');

const start = ref(
  searchParams.start ?
    parseISO(searchParams.start as string) :
    startOfWeek(new Date(), { weekStartsOn: 1 }),
);
const end = ref(
  searchParams.end ?
    parseISO(searchParams.end as string) :
    endOfWeek(new Date(), { weekStartsOn: 1 }),
);

const routineQueryParams = computed(() => ({
  q: {
    'workout_plan_id_eq': props.workoutPlan.id,
    'scheduled_at_gteq': start.value.toISOString(),
    'scheduled_at_lteq': end.value.toISOString(),
  },
}));

const { data: weekRoutines, isFetching: isFetchingRoutines } = useQuery({
  queryKey: ['routines', routineQueryParams],
  queryFn: () => routinesApi.getAll(routineQueryParams.value),
  initialData: props.routines,
  staleTime: 1000,
  refetchOnWindowFocus: false,
  refetchOnMount: false,
});

const queryClient = useQueryClient();
function handleRoutineUpdate(updatedRoutine: Routine) {
  queryClient.setQueryData(
    ['routines', routineQueryParams],
    (previous: Routine[]) => previous.map(routine => (routine.id === updatedRoutine.id ? updatedRoutine : routine)),
  );
}

const daysOfWeek = ['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'];

const groupedRoutines = computed(() => {
  const groups: { [key: string]: Routine[] } = {};
  weekRoutines.value.forEach(routine => {
    const day = format(new Date(routine.scheduledAt), 'EEEE');
    if (!groups[day]) {
      groups[day] = [];
    }
    groups[day].push(routine);
  });

  return groups;
});

const daysWithRoutines = computed(
  () => daysOfWeek.filter(day => groupedRoutines.value[day] && groupedRoutines.value[day].length > 0),
);

const weekRange = computed(() => `${format(start.value, 'd MMM')} - ${format(end.value, 'd MMM yyyy')}`);

function invalidateWeekRoutines() {
  queryClient.invalidateQueries({ queryKey: ['routines', routineQueryParams] });
}

function updateUrlAndInvalidateWeekRoutines() {
  searchParams.start = format(start.value, 'yyyy-MM-dd');
  searchParams.end = format(end.value, 'yyyy-MM-dd');
  invalidateWeekRoutines();
}

function handlePreviousWeek() {
  start.value = subWeeks(start.value, 1);
  end.value = subWeeks(end.value, 1);
  updateUrlAndInvalidateWeekRoutines();
}

function handleNextWeek() {
  start.value = addWeeks(start.value, 1);
  end.value = addWeeks(end.value, 1);
  updateUrlAndInvalidateWeekRoutines();
}

const isCloneAllRoutinesModalOpen = ref(false);
const isPublishAllRoutinesModalOpen = ref(false);
const isRoutineGeneratorModalOpen = ref(false);
const addRoutineFrom = ref<'scratch' | 'template' | undefined>(undefined);

const allRoutinesPublished = computed(() => weekRoutines.value.every(routine => routine.isPublished));

const traineeInfo = computed(() => {
  const initialSurvey = props.trainee.initialSurveySubmission?.submissionAnswers || [];
  const preMeetingSurvey = props.trainee.preMeetingSurvey?.submissionAnswers || [];
  const followUpSurveys = props.trainee.followUpMeetingSurveys || [];

  const longGoalQuestion = '¿Cuál es el principal objetivo del cliente? ¿Cómo se ve el éxito a largo plazo de forma concreta?';
  const shortGoalQuestion = 'Objetivos definidos para revisar en la siguiente reunión';

  const longGoal = followUpSurveys[0]?.submissionAnswers.find(a => a.title === longGoalQuestion)?.value;
  const shortGoal = [...followUpSurveys].reverse().find(survey =>
    survey.submissionAnswers.some(a => a.title === shortGoalQuestion),
  )?.submissionAnswers.find(a => a.title === shortGoalQuestion)?.value;

  return {
    country: initialSurvey.find(a => a.title === '¿De qué país?')?.value,
    age: initialSurvey.find(a => a.title === '¿Qué edad tienes?')?.value,
    phone: preMeetingSurvey.find(a => a.title === '¿Qué tipo de celular tienes?')?.value,
    birthdate: preMeetingSurvey.find(a => a.title === '¿En qué fecha naciste?')?.value,
    equipment: preMeetingSurvey.find(a => a.title === '¿Qué implementos tienes disponibles para entrenar?')?.value,
    goal: shortGoal || longGoal,
  };
});

</script>

<template>
  <div class="flex flex-col gap-4">
    <h2 class="text-2xl font-bold">
      {{ props.workoutPlan.name }}
    </h2>

    <div class="mb-4 flex flex-col gap-2">
      <div class="flex flex-wrap gap-4">
        <div
          v-if="traineeInfo.country"
          class="flex items-center rounded-lg bg-gray-100 p-2"
        >
          <MapPin class="mr-2 size-5 text-gray-600" />
          <span>{{ traineeInfo.country }}</span>
        </div>
        <div
          v-if="traineeInfo.age"
          class="flex items-center rounded-lg bg-gray-100 p-2"
        >
          <Calendar class="mr-2 size-5 text-gray-600" />
          <span>{{ traineeInfo.age }} años</span>
        </div>
        <div
          v-if="traineeInfo.phone"
          class="flex items-center rounded-lg bg-gray-100 p-2"
        >
          <Smartphone class="mr-2 size-5 text-gray-600" />
          <span>{{ traineeInfo.phone }}</span>
        </div>
        <div
          v-if="traineeInfo.equipment"
          class="flex items-center rounded-lg bg-gray-100 p-2"
        >
          <Dumbbell class="mr-2 size-5 text-gray-600" />
          <span>{{ traineeInfo.equipment }}</span>
        </div>
      </div>

      <!-- Goal Information -->
      <div class="flex flex-wrap gap-4">
        <div
          v-if="traineeInfo.goal"
          class="flex items-center rounded-lg bg-gray-100 p-2"
        >
          <Target class="mr-2 size-5 text-gray-600" />
          <span>Objetivo: {{ traineeInfo.goal }}</span>
        </div>
        <div
          v-else
          class="flex items-center rounded-lg bg-gray-100 p-2"
        >
          <Target class="mr-2 size-5 text-gray-600" />
          <span>Objetivo no definido</span>
        </div>
      </div>
    </div>

    <div class="mb-2 flex items-center justify-between">
      <div class="flex items-center">
        <BaseButton
          variant="ghost"
          size="sm"
          @click="handlePreviousWeek"
        >
          <ChevronLeft class="size-5" />
        </BaseButton>
        <BaseButton
          variant="ghost"
          size="sm"
          class="mr-4"
          @click="handleNextWeek"
        >
          <ChevronRight class="size-5" />
        </BaseButton>
        <h2 class="text-lg font-bold">
          {{ weekRange }}
        </h2>
      </div>
      <div class="flex gap-2">
        <BaseButton
          variant="secondary"
          class="mr-2"
          :disabled="allRoutinesPublished"
          @click="isPublishAllRoutinesModalOpen = true"
        >
          Publicar todas las rutinas
        </BaseButton>
        <BaseButton
          variant="secondary"
          class="mr-2"
          @click="isCloneAllRoutinesModalOpen = true"
        >
          Clonar todas las rutinas
        </BaseButton>
        <BaseMenu
          :items="[
            { label: 'Usar rutina vacía', onClick: () => { addRoutineFrom = 'scratch'; } },
            { label: 'Usar una plantilla', onClick: () => { addRoutineFrom = 'template'; } },
            { label: 'Generar con IA', onClick: () => { isRoutineGeneratorModalOpen = true; } },
          ]"
        >
          <template #button>
            <BaseButton
              variant="secondary"
              class="mr-2"
            >
              Agregar rutina
            </BaseButton>
          </template>
        </BaseMenu>
        <NewRoutineModal
          v-if="addRoutineFrom !== undefined"
          :workout-plan-id="props.workoutPlan.id"
          :from="addRoutineFrom"
          @close="addRoutineFrom = undefined"
        />
        <RoutineGeneratorModal
          :workout-plan-id="props.workoutPlan.id"
          :is-open="isRoutineGeneratorModalOpen"
          @close="isRoutineGeneratorModalOpen = false"
        />
        <CloneAllRoutinesModal
          :is-open="isCloneAllRoutinesModalOpen"
          :routines="weekRoutines"
          :workout-plan-id="props.workoutPlan.id"
          @close="isCloneAllRoutinesModalOpen = false"
        />
        <PublishAllRoutinesModal
          :is-open="isPublishAllRoutinesModalOpen"
          :routines="weekRoutines"
          @close="isPublishAllRoutinesModalOpen = false"
          @published="invalidateWeekRoutines"
        />
      </div>
    </div>
    <div class="relative">
      <div
        v-if="isFetchingRoutines"
        class="flex w-full gap-4 overflow-x-auto pb-4"
      >
        <div
          v-for="day in daysOfWeek"
          :key="day"
          class="flex w-96 flex-none flex-col gap-2"
        >
          <h3 class="text-center font-semibold">
            {{ day }}
          </h3>
          <div
            class="rounded border border-gray-200 bg-white p-4 text-sm shadow-sm"
          >
            <div class="animate-pulse space-y-2">
              <div class="h-8 w-3/4 rounded bg-gray-200" />
              <div class="h-8 w-1/2 rounded bg-gray-200" />
              <div class="h-8 w-2/3 rounded bg-gray-200" />
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="daysWithRoutines.length > 0"
        ref="weekContainer"
        class="flex w-full gap-4 overflow-x-auto pb-4"
      >
        <div
          v-for="day in daysWithRoutines"
          :key="day"
          class="flex w-96 flex-none flex-col gap-2"
        >
          <h3 class="text-center font-semibold">
            {{ day }}
          </h3>
          <div
            v-for="routine in groupedRoutines[day] || []"
            :key="routine.id"
            class="rounded border border-gray-200 bg-white p-4 text-sm shadow-sm"
          >
            <RoutineForm
              :routine="routine"
              :workout-plan-id="props.workoutPlan.id"
              @update="handleRoutineUpdate"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex h-full items-center justify-center"
      >
        <p class="text-gray-500">
          No hay rutinas para esta semana
        </p>
      </div>
    </div>
  </div>
</template>
