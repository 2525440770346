<script setup lang="ts">
import { computed, ref } from 'vue';
import { endOfWeek, format, startOfWeek, compareAsc, addDays } from 'date-fns';
import { daysInWeek } from 'date-fns/constants';
import { fromZonedTime } from 'date-fns-tz';
import { storeToRefs } from 'pinia';
import type { Routine, WorkoutPlan } from '@/types/extended';
import { useRoutinesStore } from '@/stores/routines';
import { BaseButton, BaseMenu } from '.';
import RoutineDetails from './routine-details.vue';
import RoutineGeneratorModal from './routine-generator-modal.vue';
import NewRoutineModal from './new-routine-modal.vue';

const props = defineProps<{
  workoutPlan: WorkoutPlan,
  routines: Routine[],
  openaiApiKey: string,
}>();

const routinesStore = useRoutinesStore();
routinesStore.setOpenaiApiKey(props.openaiApiKey);
routinesStore.setWorkoutPlan(props.workoutPlan);
routinesStore.setRoutines(props.routines);

const { routines: normalizedRoutines } = storeToRefs(routinesStore);

const workoutPlanRoutines = computed(() =>
  Object.values(normalizedRoutines.value).filter((routine) => routine.workoutPlanId === props.workoutPlan.id));

const routinesByWeek = computed(() => {
  const grouped = new Map<string, Routine[]>();

  Object.values(workoutPlanRoutines.value).forEach((routine) => {
    const routineDate = new Date(routine.scheduledAt);
    const weekStart = startOfWeek(routineDate, { weekStartsOn: 1 });
    const weekStartKey = format(weekStart, 'yyyy-MM-dd');

    if (!grouped.has(weekStartKey)) {
      grouped.set(weekStartKey, []);
    }
    grouped.get(weekStartKey)?.push(routine);
  });

  let sortedGroups = Array.from(grouped.entries()).sort((a, b) => compareAsc(new Date(b[0]), new Date(a[0])));
  sortedGroups = sortedGroups.map(([weekStartKey, groupedRoutines]) => {
    const sortedRoutines = groupedRoutines.sort(
      (a, b) => compareAsc(new Date(a.scheduledAt), new Date(b.scheduledAt)),
    );

    return [weekStartKey, sortedRoutines];
  });

  return sortedGroups;
});

function getWeekRange(weekStart: string) {
  const weekStartZoned = fromZonedTime(weekStart, 'America/Santiago');
  const weekEndZoned = endOfWeek(weekStartZoned, { weekStartsOn: 1 });

  return `${format(weekStartZoned, 'd MMMM')} a ${format(weekEndZoned, 'd MMMM')} ${format(weekStart, 'yyyy')}`;
}

function publishAllWeekRoutines(weekRoutines: Routine[]) {
  routinesStore.saveRoutines(weekRoutines.map((routine) => ({
    ...routine,
    isPublished: true,
  })));
}

async function cloneWeekRoutines(weekRoutines: Routine[]) {
  const newRoutines = weekRoutines.map((routine) => ({
    ...routine,
    scheduledAt: addDays(new Date(routine.scheduledAt), daysInWeek).toISOString(),
  }));

  await routinesStore.cloneRoutines(newRoutines);

  // temporal: reload page, set routines not working in my env

  window.location.reload();
}

const isRoutineGeneratorModalOpen = ref(false);
const addRoutineFrom = ref<'scratch' | 'template' | undefined>(undefined);

</script>

<template>
  <div class="flex flex-col gap-8">
    <div class="flex justify-between gap-x-2">
      <h2 class="text-2xl font-bold">
        {{ props.workoutPlan.name }}
      </h2>
      <div class="flex gap-2">
        <BaseMenu
          :items="[
            { label: 'Usar rutina vacía', onClick: () => { addRoutineFrom = 'scratch'; } },
            { label: 'Usar una plantilla', onClick: () => { addRoutineFrom = 'template'; } },
            { label: 'Generar con IA', onClick: () => { isRoutineGeneratorModalOpen = true; } },
          ]"
        >
          <template #button>
            <BaseButton variant="secondary">
              Agregar rutina
            </BaseButton>
          </template>
        </BaseMenu>
        <NewRoutineModal
          v-if="addRoutineFrom !== undefined"
          :workout-plan-id="props.workoutPlan.id"
          :from="addRoutineFrom"
          @close="addRoutineFrom = undefined"
        />
        <RoutineGeneratorModal
          :workout-plan-id="props.workoutPlan.id"
          :is-open="isRoutineGeneratorModalOpen"
          @close="isRoutineGeneratorModalOpen = false"
        />
      </div>
    </div>
    <div
      v-for="([weekStart, weekRoutines]) in routinesByWeek"
      :key="weekStart.toString()"
      class="flex flex-col gap-4"
    >
      <div class="flex items-center gap-4">
        <h3 class="text-lg font-bold text-gray-600">
          🗓️ {{ getWeekRange(weekStart) }}
        </h3>
        <base-button
          v-if="weekRoutines.some((routine) => !routine.isPublished)"
          variant="secondary"
          size="sm"
          @click="publishAllWeekRoutines(weekRoutines)"
        >
          📢 Publicar todas las rutinas de esta semana
        </base-button>
        <base-button
          variant="secondary"
          size="sm"
          @click="cloneWeekRoutines(weekRoutines)"
        >
          📆 Clonar rutinas para prox semana
        </base-button>
      </div>
      <div
        v-for="routine in weekRoutines"
        :key="routine.id"
      >
        <routine-details
          :routine="routine"
          :is-personalized-workout-plan="props.workoutPlan.teamId === null"
          class="rounded-lg border bg-gray-50 p-4"
        />
      </div>
    </div>
  </div>
</template>
