<script setup lang="ts">
import { onUnmounted, ref, watch } from 'vue';
import { Form } from 'vee-validate';
import { object, string } from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import { addDays, format } from 'date-fns';
import { daysInWeek } from 'date-fns/constants';
import { api } from '@/api';
import { useMutation } from '@tanstack/vue-query';
import { routinesApi } from '@/api/routines';
import type { RoutineAttributes } from '@/types';
import type { Routine } from '@/types/extended';
import { BaseModal, BaseButton, BaseInput, BaseLabel } from '@/components';
import { useRoutinesStore } from '@/stores/routines';
import { AxiosError } from 'axios';
import WorkoutSummary from './workout-summary.vue';

// Add this utility function for deep cloning
function deepClone<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepClone) as unknown as T;
  }

  return Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, deepClone(value)]),
  ) as T;
}

const props = defineProps<{
  routine: Routine,
  isOpen: boolean,
}>();

const emit = defineEmits<{
  close: [],
  pending: [],
  success: [],
  reset: [],
}>();

const validationSchema = toTypedSchema(
  object({
    scheduledAt: string().required(),
  }));

const formValues = ref({
  scheduledAt: format(addDays(new Date(props.routine.scheduledAt), daysInWeek), "yyyy-MM-dd'T'HH:mm"),
});

type GeneratedRoutine = {
  changes: {
    exercise: string,
    sets: {
      set: number,
      repetitions: {
        previous: number | null,
        progression: number,
      },
      weight: {
        previous: number | null,
        progression: number,
      },
    }[],
  }[],
  routineAttributes: RoutineAttributes,
};

async function requestRoutineProgression(): Promise<GeneratedRoutine> {
  const cleanedRoutine = {
    ...props.routine,
    trainer: undefined,
    routinePhases: props.routine.routinePhases.map(phase => ({
      ...phase,
      routinePhaseExercises: phase.routinePhaseExercises.map((routinePhaseExercise) => ({
        ...routinePhaseExercise,
        exercise: undefined,
        routineExerciseSets: routinePhaseExercise.routineExerciseSets.map(set => ({
          ...set,
          audioUrl: undefined,
        })),
      })),
    })),
  };

  const response = await api({
    method: 'post',
    url: `/api/internal/routines/${props.routine.id}/progress`,
    data: {
      routine: cleanedRoutine,
    },
  });

  return response.data;
}

const {
  data: generatedRoutine,
  error: generateError,
  isIdle: isGenerateIdle,
  isPending: isGeneratePending,
  isError: isGenerateError,
  isSuccess: isGenerateSuccess,
  mutate: generateRoutine,
  reset: resetGenerateRoutine,
} = useMutation({
  mutationFn: requestRoutineProgression,
  onMutate: () => emit('pending'),
  onSuccess: () => emit('success'),
});

const showWorkoutSummary = ref(false);
const lastCompletedWorkout = props.routine.workouts.filter(workout => workout.endedAt !== null).pop();

const routinesStore = useRoutinesStore();
const {
  error: createError,
  isIdle: isCreateIdle,
  isPending: isCreatePending,
  isError: isCreateError,
  isSuccess: isCreateSuccess,
  mutate: createRoutine,
  reset: resetCreateRoutine,
} = useMutation({
  mutationFn: (routineData: GeneratedRoutine) => {
    if (!routineData || !routineData.routineAttributes) {
      throw new Error('Routine data is incomplete');
    }

    const newRoutineData = {
      ...routineData.routineAttributes,
      workoutPlanId: props.routine.workoutPlanId,
      scheduledAt: formValues.value.scheduledAt,
      published: false,
      duration: Number(routineData.routineAttributes.durationInMinutes) * 60,
      routinePhasesAttributes: routineData.routineAttributes.routinePhases.map(phase => ({
        ...phase,
        routinePhaseExercisesAttributes: phase.routinePhaseExercises.map(exercise => ({
          ...exercise,
          duration: exercise.durationInSeconds,
          routineExerciseSetsAttributes: exercise.routineExerciseSets.map((set, index) => ({
            ...set,
            weight: editableRoutine.value.changes.find(c => c.exercise === exercise.exercise.name)?.sets[index]?.weight.progression || set.weight,
            repetitions: editableRoutine.value.changes.find(c => c.exercise === exercise.exercise.name)?.sets[index]?.repetitions.progression || set.repetitions,
          })),
        })),
      })),
    };

    return routinesApi.create(newRoutineData);
  },
  onSuccess: (newRoutine) => {
    routinesStore.setRoutine(newRoutine);
  },
});

function handleClose() {
  resetGenerateRoutine();
  resetCreateRoutine();
  emit('reset');
  emit('close');
}

onUnmounted(() => {
  emit('reset');
});

const isEditMode = ref(false);
const displayRoutine = ref<GeneratedRoutine | null>(null);
const editableRoutine = ref<GeneratedRoutine | null>(null);

// Initialize displayRoutine when routine is generated
watch(generatedRoutine, (newValue) => {
  if (newValue) {
    displayRoutine.value = deepClone(newValue);
    editableRoutine.value = deepClone(newValue);
  }
});

// Function to toggle edit mode
function toggleEditMode() {
  isEditMode.value = !isEditMode.value;
  if (isEditMode.value && displayRoutine.value) {
    editableRoutine.value = deepClone(displayRoutine.value);
  }
}

function updateSet(changeIndex: number, setIndex: number, field: 'repetitions' | 'weight', value: number) {
  if (editableRoutine.value) {
    editableRoutine.value.changes[changeIndex].sets[setIndex][field].progression = value;
  }
}

function saveChanges() {
  if (editableRoutine.value) {
    displayRoutine.value = deepClone(editableRoutine.value);
  }
  isEditMode.value = false;
}

function handleCreateRoutine() {
  if (!displayRoutine.value) {
    console.error('Display routine is not defined');

    return;
  }

  createRoutine(displayRoutine.value);
}

</script>

<template>
  <BaseModal
    :open="props.isOpen"
    :title="props.routine.name"
    @close="() => emit('close')"
  >
    <Form
      v-if="isGenerateIdle"
      class="flex flex-col gap-4"
      :validation-schema="validationSchema"
      @submit="() => generateRoutine()"
    >
      <div class="flex flex-col gap-1">
        <BaseLabel
          label="Fecha"
          name="scheduledAt"
          required
        />
        <BaseInput
          v-model="formValues.scheduledAt"
          name="scheduledAt"
          type="datetime-local"
        />
      </div>
      <BaseButton type="submit">
        Clonar y progresar rutina
      </BaseButton>
    </Form>
    <div
      v-else-if="isGeneratePending"
      class="flex justify-center"
    >
      Generando rutina... Esto toma entre 25 y 35 segundos
    </div>
    <div
      v-else-if="isGenerateError"
      class="flex flex-col justify-center gap-4"
    >
      <p>Error al generar la rutina:</p>
      <p v-if="(generateError instanceof AxiosError)">
        {{ generateError.response?.data ?? generateError.message }}
      </p>
      <BaseButton
        @click="() => resetGenerateRoutine()"
      >
        Volver a intentar
      </BaseButton>
    </div>
    <div
      v-else-if="isGenerateSuccess"
      class="flex flex-col justify-center gap-8"
    >
      <div
        v-if="lastCompletedWorkout"
        class="flex justify-end"
      >
        <BaseButton
          size="sm"
          variant="secondary"
          @click="showWorkoutSummary = !showWorkoutSummary"
        >
          📊 Ver entrenamiento realizado
        </BaseButton>
      </div>
      <WorkoutSummary
        v-if="showWorkoutSummary && lastCompletedWorkout"
        :routine="routine"
        :workout="lastCompletedWorkout"
        @close="showWorkoutSummary = false"
      />
      <div
        v-if="isCreateIdle"
        class="flex flex-col justify-center gap-8"
      >
        <table
          class="w-full border-collapse text-xs"
        >
          <thead>
            <tr>
              <th class="border px-2 py-1 text-left">
                Ejercicio
              </th>
              <th class="border px-2 py-1 text-left">
                Serie
              </th>
              <th class="border px-2 py-1 text-left">
                Reps
              </th>
              <th class="border px-2 py-1 text-left">
                Peso
              </th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(change, index) in (isEditMode ? editableRoutine?.changes : displayRoutine?.changes || [])"
              :key="index"
            >
              <tr
                v-for="(set, setIndex) in change.sets"
                :key="`${index}-${setIndex}`"
              >
                <td
                  v-if="setIndex === 0"
                  class="border px-2 py-1"
                  :rowspan="change.sets.length"
                >
                  {{ change.exercise }}
                </td>
                <td class="border px-2 py-1 text-gray-400">
                  {{ set.set }}
                </td>
                <td class="border px-2 py-1">
                  <input
                    v-if="isEditMode"
                    :value="editableRoutine!.changes[index].sets[setIndex].repetitions.progression"
                    type="number"
                    @input="(e) => updateSet(index, setIndex, 'repetitions', Number((e.target as HTMLInputElement).value))"
                  >
                  <span v-else>
                    {{ set.repetitions.progression }}
                    <span
                      v-if="set.repetitions.previous !== null"
                      class="text-gray-400"
                    >
                      ({{ set.repetitions.progression - set.repetitions.previous > 0 ? '+' : '' }}{{ set.repetitions.progression - set.repetitions.previous }})
                    </span>
                  </span>
                </td>
                <td class="border px-2 py-1">
                  <input
                    v-if="isEditMode"
                    :value="editableRoutine!.changes[index].sets[setIndex].weight.progression"
                    type="number"
                    step="0.1"
                    @input="(e) => updateSet(index, setIndex, 'weight', Number((e.target as HTMLInputElement).value))"
                  >
                  <span v-else>
                    {{ set.weight.progression }}
                    <span
                      v-if="set.weight.previous !== null"
                      class="text-gray-400"
                    >
                      ({{ set.weight.progression - set.weight.previous > 0 ? '+' : '' }}{{ (set.weight.progression - set.weight.previous).toFixed(1) }})
                    </span>
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div
          class="flex justify-end gap-4"
        >
          <BaseButton
            variant="secondary"
            @click="handleClose"
          >
            Cancelar
          </BaseButton>
          <BaseButton
            variant="secondary"
            @click="isEditMode ? saveChanges() : toggleEditMode()"
          >
            <span class="flex items-center">
              {{ isEditMode ? 'Guardar Cambios' : 'Editar Progresiones' }}
            </span>
          </BaseButton>
          <BaseButton
            @click="handleCreateRoutine"
          >
            {{ isEditMode ? 'Crear Rutina Con Cambios' : 'Crear Rutina' }}
          </BaseButton>
        </div>
      </div>
      <div
        v-else-if="isCreatePending"
        class="flex justify-center"
      >
        Creando rutina...
      </div>
      <div
        v-else-if="isCreateError"
        class="flex flex-col justify-center gap-2"
      >
        <p>Error al crear la rutina</p>
        <p>{{ createError?.message }}</p>
        <BaseButton
          @click="() => resetCreateRoutine()"
        >
          Volver a intentar
        </BaseButton>
      </div>
      <div
        v-else-if="isCreateSuccess"
        class="flex flex-col justify-center gap-8"
      >
        <p>
          Rutina creada con éxito 🎉
        </p>
        <BaseButton
          @click="handleClose"
        >
          Cerrar
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>

