import type { MovementPattern } from '@/types';
import { api } from './index';

export const movementPatternsApi = {
  async getAll() {
    const response = await api({
      method: 'get',
      url: '/api/internal/movement_patterns',
    });

    return response.data as MovementPattern[];
  },
};
