<script setup lang="ts">
import { ref } from 'vue';
import { BaseButton, BaseModal, BaseCheckbox } from '@/components';
import { routinesApi } from '@/api/routines';
import type { Routine } from '@/types/extended';
import { useMutation } from '@tanstack/vue-query';
import { addDays, parseISO } from 'date-fns';
import { daysInWeek } from 'date-fns/constants';

const props = defineProps<{
  isOpen: boolean;
  routines: Routine[];
  workoutPlanId: number;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'cloned'): void;
}>();

const errorMessage = ref('');
const withAudios = ref(false);

const { isError, isPending, isSuccess, mutate, reset: resetMutation } = useMutation({
  mutationFn: async () => Promise.all(
    props.routines.map(routine =>
      routinesApi.clone({
        routineId: routine.id,
        scheduledAt: addDays(parseISO(routine.scheduledAt), daysInWeek).toISOString(),
        workoutPlanId: props.workoutPlanId,
        withAudios: withAudios.value,
      }),
    ),
  ),
  onSuccess: () => {
    emit('cloned');
  },
  onError: (error) => {
    errorMessage.value = `Ocurrió un error al clonar las rutinas: ${error.message}`;
  },
});

function cloneAllRoutines() {
  errorMessage.value = '';
  mutate();
}

function handleClose() {
  withAudios.value = false;
  resetMutation();
  emit('close');
}
</script>

<template>
  <BaseModal
    :open="isOpen"
    title="Clonar todas las rutinas"
    @close="handleClose"
  >
    <template #default="{ close }">
      <div
        v-if="!isPending && !isSuccess"
        class="flex flex-col gap-4"
      >
        <p>
          ¿Estás seguro de que quieres clonar todas las rutinas para la próxima semana?
        </p>
        <div
          v-if="!isPending && !isSuccess"
          class="mt-4 flex items-center gap-2"
        >
          <BaseCheckbox
            v-model="withAudios"
            name="withAudios"
          />
          <label
            class="text-sm"
            for="withAudios"
          >
            Incluir audios
          </label>
        </div>
      </div>
      <p v-if="isPending">
        Clonando rutinas...
      </p>
      <p v-if="isSuccess">
        ¡Rutinas clonadas exitosamente!
      </p>
      <p v-if="isError">
        {{ errorMessage }}
      </p>
      <div class="mt-6 flex justify-end gap-4">
        <BaseButton
          v-if="!isPending && !isSuccess"
          variant="secondary"
          @click="close"
        >
          Cancelar
        </BaseButton>
        <BaseButton
          v-if="!isPending && !isSuccess"
          @click="cloneAllRoutines"
        >
          Confirmar
        </BaseButton>
        <BaseButton
          v-if="isSuccess"
          variant="secondary"
          @click="close"
        >
          Cerrar
        </BaseButton>
        <BaseButton
          v-if="isError"
          variant="secondary"
          @click="close"
        >
          Cerrar
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>
