<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  DialogTitle,
} from '@headlessui/vue';

const props = withDefaults(defineProps<{
  open: boolean;
  title: string;
  size?: 'md' | 'lg';
}>(), {
  size: 'md',
});

const emit = defineEmits<{close: []}>();
</script>

<template>
  <Dialog
    v-if="props.open"
    :open="props.open"
    class="fixed inset-0 z-50 flex items-center justify-center"
    @close="() => emit('close')"
  >
    <div
      class="fixed inset-0 bg-black/60"
      aria-hidden="true"
    />
    <div class="fixed inset-0 w-screen overflow-y-auto">
      <div class="flex min-h-full items-center justify-center p-12">
        <DialogPanel
          class="relative mx-auto flex w-full flex-col gap-6 rounded-md bg-white p-9"
          :class="{
            'md:max-w-screen-md': props.size === 'md',
            'md:max-w-screen-lg': props.size === 'lg',
          }"
        >
          <DialogTitle class="text-xl font-bold">
            {{ props.title }}
          </DialogTitle>
          <slot :close="() => emit('close')" />
        </DialogPanel>
      </div>
    </div>
  </Dialog>
</template>
