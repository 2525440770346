<script setup lang="ts">
import { ref, watchEffect, onBeforeUnmount } from 'vue';
import { useDevicesList, useUserMedia } from '@vueuse/core';
import { Mic } from 'lucide-vue-next';
import { MediaRecorder, type IMediaRecorder } from 'extendable-media-recorder';
import BaseButton from './base-button.vue';

const emit = defineEmits<{
  'update': [audio: Blob],
}>();

const currentMicrophone = ref<string>();
const { audioInputs: microphones } = useDevicesList({
  requestPermissions: true,
  onUpdated() {
    if (!microphones.value.find(i => i.deviceId === currentMicrophone.value)) {
      currentMicrophone.value = microphones.value[0]?.deviceId;
    }
  },
});

const { stream, enabled: isEnabled } = useUserMedia({
  constraints: { audio: { deviceId: currentMicrophone.value } },
});

const mediaRecorder = ref<IMediaRecorder | null>(null);

watchEffect(() => {
  if (stream.value) {
    if (mediaRecorder.value) {
      mediaRecorder.value.stop();
    }
    mediaRecorder.value = new MediaRecorder(stream.value, { mimeType: 'audio/wav' });
    mediaRecorder.value.ondataavailable = (event) => {
      emit('update', event.data);
    };
    mediaRecorder.value.start();
  } else {
    if (mediaRecorder.value) {
      mediaRecorder.value.stop();
      mediaRecorder.value = null;
    }
  }
});

onBeforeUnmount(() => {
  if (mediaRecorder.value) {
    mediaRecorder.value.stop();
    mediaRecorder.value = null;
  }
});
</script>

<template>
  <div class="flex items-center gap-4">
    <BaseButton
      type="button"
      size="icon"
      variant="outline"
      @click="isEnabled = !isEnabled"
    >
      <mic
        class="size-5"
        :class="{ 'animate-pulse text-red-500': isEnabled }"
      />
    </BaseButton>
  </div>
</template>
