<script setup lang="ts">
import { ref, computed } from 'vue';
import type { Routine, Workout } from '@/types/extended';
import type { WorkoutExercise } from '@/types';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import {
  XCircle,
  Maximize2,
  Minimize2,
  Calendar,
  BarChart2,
  MessageSquare,
  Dumbbell,
  ChevronDown,
  ChevronUp,
} from 'lucide-vue-next';
import { BaseButton } from '.';

const props = defineProps<{
  routine: Routine;
  workout: Workout;
}>();

const emit = defineEmits(['close']);

const isExpanded = ref(true);
const collapsedPhases = ref<Record<number, boolean>>({});

const formattedDate = computed(() => (
  props.workout.startedAt ? format(new Date(props.workout.startedAt), 'dd MMM yyyy HH:mm') : 'N/A'
));

const formattedTitleDate = computed(() => (
  props.workout.startedAt ? format(new Date(props.workout.startedAt), 'EEEE dd MMM', { locale: es }) : 'N/A'
));

function toggleExpand() {
  isExpanded.value = !isExpanded.value;
}

function closeModal() {
  emit('close');
}

function getRoutinePhaseName(routinePhaseId: number) {
  return props.routine.routinePhases.find(rp => rp.id === routinePhaseId)?.name || 'Unknown Phase';
}

function getExerciseName(routinePhaseId: number, exerciseId: number) {
  const phase = props.routine.routinePhases.find(rp => rp.id === routinePhaseId);

  return phase?.routinePhaseExercises.find(rpe => rpe.id === exerciseId)?.exerciseName || 'Unknown Exercise';
}

function getSetLabel(setNumber: number) {
  return `(Serie ${setNumber})`;
}

function groupExercisesBySet(exercises: WorkoutExercise[]): WorkoutExercise[][] {
  const maxSetNumber = Math.max(...exercises.map(e => e.setNumber));
  const groupedExercises: WorkoutExercise[][] = [];

  for (let i = 1; i <= maxSetNumber; i++) {
    const setExercises = exercises.filter(e => e.setNumber === i);
    groupedExercises.push(setExercises);
  }

  return groupedExercises;
}

function togglePhase(phaseId: number) {
  collapsedPhases.value[phaseId] = !collapsedPhases.value[phaseId];
}

// Initialize collapsedPhases to true for all phases
props.workout.workoutPhases.forEach(phase => {
  collapsedPhases.value[phase.id] = true;
});
</script>

<template>
  <div
    class="fixed right-4 top-4 z-50 w-[32rem] rounded-lg bg-white shadow-lg"
    :class="{ 'h-auto': isExpanded, 'h-12': !isExpanded }"
  >
    <div class="flex items-center justify-between bg-gray-100 p-3">
      <h3 class="font-semibold">
        {{ routine.name }} ({{ formattedTitleDate }})
      </h3>
      <div class="flex items-center gap-2">
        <BaseButton
          variant="ghost"
          size="sm"
          @click="toggleExpand"
        >
          <Maximize2
            v-if="!isExpanded"
            class="size-5"
          />
          <Minimize2
            v-else
            class="size-5"
          />
        </BaseButton>
        <BaseButton
          variant="ghost"
          size="sm"
          @click="closeModal"
        >
          <XCircle class="size-5" />
        </BaseButton>
      </div>
    </div>
    <div
      v-if="isExpanded"
      class="max-h-[calc(100vh-6rem)] overflow-y-auto p-4"
    >
      <div class="mb-4 rounded-lg bg-gray-50 p-3">
        <div class="mb-2 flex items-center justify-between">
          <div class="flex items-center">
            <Calendar class="mr-2 size-4 text-gray-600" />
            <p class="text-sm">
              <span class="text-gray-600">Fecha:</span>
              <span class="ml-1 font-semibold">{{ formattedDate }}</span>
            </p>
          </div>
          <div class="flex items-center">
            <BarChart2 class="mr-2 size-4 text-gray-600" />
            <p class="text-sm">
              <span class="text-gray-600">Esfuerzo:</span>
              <span class="ml-1 font-semibold">{{ workout.perceivedExertion }}</span>
            </p>
          </div>
        </div>
        <div class="flex items-start">
          <MessageSquare class="mr-2 mt-1 size-4 text-gray-600" />
          <div class="text-sm">
            <p class="text-gray-600">
              Comentario trainee
            </p>
            <p class="italic">
              {{ workout.comment || 'Sin comentario' }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-for="phase in workout.workoutPhases"
        :key="phase.id"
        class="mt-4 rounded-lg bg-gray-50 p-3"
      >
        <button
          class="mb-2 flex cursor-pointer items-center justify-between"
          @click="togglePhase(phase.id)"
        >
          <h4 class="flex items-center text-base font-semibold">
            <Dumbbell class="mr-2 size-4 text-gray-600" />
            {{ getRoutinePhaseName(phase.routinePhaseId) }}
          </h4>
          <ChevronDown
            v-if="collapsedPhases[phase.id]"
            class="size-5"
          />
          <ChevronUp
            v-else
            class="size-5"
          />
        </button>
        <div v-show="!collapsedPhases[phase.id]">
          <div class="overflow-x-auto">
            <table class="w-full text-xs">
              <thead>
                <tr class="bg-gray-200">
                  <th class="rounded-tl-lg px-3 py-2 text-left">
                    Set
                  </th>
                  <th class="px-3 py-2 text-left">
                    Ejercicio
                  </th>
                  <th class="px-3 py-2 text-center">
                    Reps
                  </th>
                  <th class="px-3 py-2 text-center">
                    Peso
                  </th>
                  <th class="rounded-tr-lg px-3 py-2 text-center">
                    Esfuerzo
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="(setExercises, setIndex) in groupExercisesBySet(phase.workoutExercises)"
                  :key="setIndex"
                >
                  <tr
                    v-for="exercise in setExercises"
                    :key="exercise.id"
                    class="border-b border-gray-200 last:border-b-0"
                  >
                    <td class="px-3 py-2 text-center">
                      {{ exercise.setNumber }}
                    </td>
                    <td class="px-3 py-2">
                      {{ getExerciseName(phase.routinePhaseId, exercise.routinePhaseExerciseId) }}
                    </td>
                    <td class="px-3 py-2 text-center">
                      {{ exercise.repetitions || 'N/A' }}
                    </td>
                    <td class="px-3 py-2 text-center">
                      {{ exercise.weight ? `${exercise.weight} kg` : 'N/A' }}
                    </td>
                    <td class="px-3 py-2 text-center">
                      {{ exercise.effort || 'N/A' }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <div
            v-if="phase.workoutExercises.some(e => e.traineeComment)"
            class="mt-3 rounded-lg bg-white p-2"
          >
            <h5 class="mb-1 flex items-center text-sm font-semibold">
              <MessageSquare class="mr-1 size-3 text-gray-600" />
              Comentarios
            </h5>
            <ul class="space-y-1">
              <li
                v-for="exercise in phase.workoutExercises.filter(e => e.traineeComment)"
                :key="exercise.id"
                class="text-sm"
              >
                <span class="font-medium">{{ getExerciseName(phase.routinePhaseId, exercise.routinePhaseExerciseId) }} - {{ getSetLabel(exercise.setNumber) }}:</span>
                <span class="italic"> {{ exercise.traineeComment }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
