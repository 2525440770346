import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { camelizeKeys } from 'humps';
import { setDefaultOptions } from 'date-fns';
import { es } from 'date-fns/locale';
import InlineSvg from 'vue-inline-svg';
import VueApexCharts from 'vue3-apexcharts';
import InstantSearch from 'vue-instantsearch/vue3/es';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { BaseButton, BaseInput } from '@/components';
import BackButton from '@/components/back-button.vue';
import RoutineDetails from '@/components/routine-details.vue';
import RoutinesTable from '@/components/routines-table.vue';
import RoutinesCalendar from '@/components/routines-calendar.vue';
import RoutinesSchedule from '@/components/routines-schedule.vue';
import ExerciseForm from '@/components/exercise-form.vue';
import ExerciseSearch from '@/components/exercise-search.vue';
import TeamsDashboard from '@/components/teams/teams-dashboard.vue';
import TraineesDashboard from '@/components/trainees-dashboard/trainees-dashboard.vue';
import InitialSurvey from '@/components/onboarding/initial-survey.vue';
import OnboardingSurvey from '@/components/onboarding/onboarding-survey.vue';
import ChooseTrainer from '@/components/onboarding/choose-trainer.vue';
import RebillCheckout from '@/components/onboarding/rebill-checkout.vue';
import TeamsInitialSurvey from '@/components/teams/initial-survey.vue';
import TheLayout from '@/components/the-layout.vue';
import AiAssistant from '@/components/ai-assistant.vue';
import WeeklyRoutinesView from '@/components/weekly-routines-view.vue';
import WorkoutDetails from '@/components/workout-details.vue';
import Crm from '@/components/crm/crm.vue';
import { EncoderPlugin } from '@/utils/encoder-plugin';
import '../css/application.css';

setDefaultOptions({ locale: es });

// eslint-disable-next-line max-statements
document.addEventListener('DOMContentLoaded', () => {
  const pinia = createPinia();

  const app = createApp({
    components: {
      BackButton,
      BaseButton,
      BaseInput,
      RoutineDetails,
      RoutinesTable,
      RoutinesCalendar,
      RoutinesSchedule,
      ExerciseForm,
      ExerciseSearch,
      TeamsDashboard,
      TraineesDashboard,
      InitialSurvey,
      ChooseTrainer,
      OnboardingSurvey,
      RebillCheckout,
      TeamsInitialSurvey,
      TheLayout,
      AiAssistant,
      WeeklyRoutinesView,
      WorkoutDetails,
      Crm,
    },
  });

  app.config.globalProperties.$filters = {
    camelizeKeys,
  };
  app.component('InlineSvg', InlineSvg);

  app.use(pinia);
  app.use(VueQueryPlugin);
  app.use(InstantSearch);
  app.use(EncoderPlugin);
  app.use(VueApexCharts);

  app.mount('#vue-app');

  return app;
});
