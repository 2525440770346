<script setup lang="ts">
import { ref, computed } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';
import ExerciseSearchVideo from '@/components/exercise-search-video.vue';
import { BaseModal, BaseButton, BaseLink } from '@/components';

const searchClient = algoliasearch(
  'L3P44YCS6E',
  '635845362136cd45adea7d7d709a3410',
);

const algoliaIndex = `Exercise_${import.meta.env.MODE}`;

const props = defineProps<{
  mode: 'single' | 'multiple'
}>();

const emit = defineEmits<{
  close: [];
  select: [number[]];
}>();

const selectedExercises = ref<{ id: number; name: string }[]>([]);

const selectedExerciseNames = computed(() =>
  selectedExercises.value.map(exercise => exercise.name),
);

function toggleExerciseSelection(id: number, name: string) {
  if (props.mode === 'single') {
    emit('select', [id]);
    emit('close');
  } else {
    const index = selectedExercises.value.findIndex(e => e.id === id);
    if (index === -1) {
      selectedExercises.value.push({ id, name });
    } else {
      selectedExercises.value.splice(index, 1);
    }
  }
}

function handleSubmit() {
  emit('select', selectedExercises.value.map(e => e.id));
  emit('close');
}

function isExerciseSelected(exerciseId: number): boolean {
  return props.mode === 'multiple' && selectedExercises.value.some(e => e.id === exerciseId);
}

function buttonVariant(exerciseId: number): string {
  return isExerciseSelected(exerciseId) ? 'outline' : 'secondary';
}

function buttonText(exerciseId: number): string {
  if (props.mode === 'single') return 'Seleccionar';

  return isExerciseSelected(exerciseId) ? 'Deseleccionar' : 'Seleccionar';
}

</script>

<template>
  <BaseModal
    :open="true"
    :title="mode === 'single' ? 'Buscar ejercicio' : 'Buscar ejercicios'"
    size="lg"
    @close="emit('close')"
  >
    <div
      v-if="mode === 'multiple'"
      class="mb-4 flex items-center justify-between"
    >
      <div class="max-w-2xl overflow-x-auto">
        <span class="mr-2 font-semibold">Ejercicios seleccionados:</span>
        <span
          v-for="name in selectedExerciseNames"
          :key="name"
          class="mr-2 rounded bg-gray-200 px-2 py-1 text-sm"
        >
          {{ name }}
        </span>
      </div>
      <BaseButton
        @click="handleSubmit"
      >
        Agregar ejercicios
      </BaseButton>
    </div>

    <ais-instant-search
      :search-client="searchClient"
      :index-name="algoliaIndex"
      class="w-full"
    >
      <div class="flex w-full gap-4">
        <div class="flex min-w-48 flex-col gap-y-4">
          <ais-clear-refinements
            :class-names="{
              'ais-ClearRefinements-button': 'bg-slate-100 px-2 py-1.5 rounded hover:bg-slate-100/80 text-slate-900',
            }"
          />
          <div>
            <h2 class="font-semibold">
              Grupo muscular
            </h2>
            <ais-refinement-list
              attribute="muscle_groups"
              :show-more="true"
              searchable
              :class-names="{
                'ais-RefinementList-label': 'flex flex-row gap-1',
                'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
              }"
            />
          </div>
          <div>
            <h2 class="font-semibold">
              Equipamiento
            </h2>
            <ais-refinement-list
              attribute="equipment"
              :show-more="true"
              searchable
              :class-names="{
                'ais-RefinementList-label': 'flex flex-row gap-1',
                'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
              }"
            />
          </div>
          <div>
            <h2 class="font-semibold">
              Tipo
            </h2>
            <ais-refinement-list
              attribute="kind"
              :limit="10"
              searchable
              :class-names="{
                'ais-RefinementList-label': 'flex flex-row gap-1',
                'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
              }"
            />
          </div>
          <div>
            <h2 class="font-semibold">
              ID
            </h2>
            <ais-refinement-list
              attribute="exercise_id"
              :limit="5"
              searchable
              :class-names="{
                'ais-RefinementList-label': 'flex flex-row gap-1',
                'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
              }"
            />
          </div>
          <div>
            <h2 class="font-semibold">
              Patrón de movimiento
            </h2>
            <ais-refinement-list
              attribute="movement_pattern"
              :limit="10"
              searchable
              :class-names="{
                'ais-RefinementList-label': 'flex flex-row gap-1',
                'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
              }"
            />
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <ais-search-box
            :class-names="{
              'ais-SearchBox': 'mb-4 p-0',
            }"
          />
          <ais-hits
            :class-names="{
              'ais-Hits-list': 'grid grid-cols-2 gap-2 ml-0',
              'ais-Hits-item': 'w-full m-0',
            }"
          >
            <template #item="{ item }">
              <div class="flex flex-col gap-2">
                <div class="flex items-start justify-between gap-2">
                  <ais-highlight
                    attribute="name"
                    :hit="item"
                    :class-names="{
                      'ais-Highlight': 'text-base font-semibold',
                      'ais-Highlight-highlighted': 'text-base font-semibold bg-yellow-200',
                    }"
                  />
                  <BaseLink
                    :variant="buttonVariant(item.exercise_id)"
                    :href="`exercises/${item.exercise_id}/edit`"
                  >
                    Editar
                  </BaseLink>
                  <BaseButton
                    :variant="buttonVariant(item.exercise_id)"
                    @click="toggleExerciseSelection(item.exercise_id, item.name)"
                  >
                    {{ buttonText(item.exercise_id) }}
                  </BaseButton>
                </div>
                <exercise-search-video :exercise-id="item.exercise_id" />
                <div class="flex h-full">
                  <div class="flex h-full w-1/2 flex-col gap-2">
                    <div class="flex gap-x-1">
                      <h4 class="text-xs font-semibold">
                        ID:
                      </h4>
                      <ais-highlight
                        attribute="exercise_id"
                        :hit="item"
                        :class-names="{
                          'ais-Highlight': 'text-xs',
                          'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                        }"
                      />
                    </div>
                    <div>
                      <h4 class="text-xs font-semibold">
                        Grupos musculares
                      </h4>
                      <div class="flex flex-col">
                        <ais-highlight
                          v-for="(group, index) in item.muscle_groups"
                          :key="group"
                          :attribute="`muscle_groups.${index}`"
                          :hit="item"
                          :class-names="{
                            'ais-Highlight': 'text-xs',
                            'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                          }"
                        />
                      </div>
                    </div>
                    <div>
                      <h4 class="text-xs font-semibold">
                        Tipo
                      </h4>
                      <div class="flex flex-col">
                        <ais-highlight
                          attribute="kind"
                          :hit="item"
                          :class-names="{
                            'ais-Highlight': 'text-xs',
                            'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                          }"
                        />
                      </div>
                    </div>
                    <div>
                      <h4 class="text-xs font-semibold">
                        Equipamiento
                      </h4>
                      <div class="flex flex-col">
                        <ais-highlight
                          attribute="equipment"
                          :hit="item"
                          :class-names="{
                            'ais-Highlight': 'text-xs',
                            'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                          }"
                        />
                      </div>
                    </div>
                    <div>
                      <h4 class="text-xs font-semibold">
                        Patrón de movimiento
                      </h4>
                      <div class="flex flex-col">
                        <ais-highlight
                          attribute="movement_pattern"
                          :hit="item"
                          :class-names="{
                            'ais-Highlight': 'text-xs',
                            'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </ais-hits>
          <ais-pagination />
        </div>
      </div>
    </ais-instant-search>
  </BaseModal>
</template>
