<script setup lang="ts">
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';
import { BaseLink } from '@/components';
import ExerciseSearchVideo from '@/components/exercise-search-video.vue';

const searchClient = algoliasearch(
  'L3P44YCS6E',
  '635845362136cd45adea7d7d709a3410',
);
const algoliaIndex = `Exercise_${import.meta.env.MODE}`;

</script>

<template>
  <div class="mb-6 flex justify-between">
    <h1 class="text-2xl font-semibold">
      Ejercicios
    </h1>
    <BaseLink href="/exercises/new">
      Agregar ejercicio
    </BaseLink>
  </div>
  <ais-instant-search
    :search-client="searchClient"
    :index-name="algoliaIndex"
    class="w-full"
  >
    <div class="flex w-full gap-4">
      <div class="flex min-w-48 flex-col gap-y-4">
        <ais-clear-refinements
          :class-names="{
            'ais-ClearRefinements-button': 'bg-slate-100 px-2 py-1.5 rounded hover:bg-slate-100/80 text-slate-900',
          }"
        />
        <div>
          <h2 class="font-semibold">
            Grupo muscular
          </h2>
          <ais-refinement-list
            attribute="muscle_groups"
            :show-more="true"
            searchable
            :class-names="{
              'ais-RefinementList-label': 'flex flex-row gap-1',
              'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
            }"
          />
        </div>
        <div>
          <h2 class="font-semibold">
            Equipamiento
          </h2>
          <ais-refinement-list
            attribute="equipment"
            :show-more="true"
            searchable
            :class-names="{
              'ais-RefinementList-label': 'flex flex-row gap-1',
              'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
            }"
          />
        </div>
        <div>
          <h2 class="font-semibold">
            Tipo
          </h2>
          <ais-refinement-list
            attribute="kind"
            :limit="10"
            searchable
            :class-names="{
              'ais-RefinementList-label': 'flex flex-row gap-1',
              'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
            }"
          />
        </div>
        <div>
          <h2 class="font-semibold">
            ID
          </h2>
          <ais-refinement-list
            attribute="exercise_id"
            :limit="5"
            searchable
            :class-names="{
              'ais-RefinementList-label': 'flex flex-row gap-1',
              'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
            }"
          />
        </div>
        <div>
          <h2 class="font-semibold">
            Patrón de movimiento
          </h2>
          <ais-refinement-list
            attribute="movement_pattern"
            :limit="10"
            searchable
            :class-names="{
              'ais-RefinementList-label': 'flex flex-row gap-1',
              'ais-RefinementList-showMore': 'bg-slate-100 py-1.5 hover:bg-slate-100/80 text-slate-900'
            }"
          />
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <ais-search-box
          :class-names="{
            'ais-SearchBox': 'mb-4 p-0',
          }"
        />
        <ais-hits
          :class-names="{
            'ais-Hits-list': 'grid grid-cols-2 gap-2 ml-0',
            'ais-Hits-item': 'w-full m-0',
          }"
        >
          <template #item="{ item }">
            <div class="flex flex-col gap-2">
              <div class="flex items-center justify-between">
                <ais-highlight
                  attribute="name"
                  :hit="item"
                  :class-names="{
                    'ais-Highlight': 'text-base font-semibold',
                    'ais-Highlight-highlighted': 'text-base font-semibold bg-yellow-200',
                  }"
                />
                <BaseLink
                  class="place-self-end"
                  :href="`exercises/${item.exercise_id}/edit`"
                >
                  Editar
                </BaseLink>
              </div>
              <exercise-search-video :exercise-id="item.exercise_id" />
              <div class="flex gap-x-1">
                <h4 class="text-xs font-semibold">
                  ID:
                </h4>
                <ais-highlight
                  attribute="exercise_id"
                  :hit="item"
                  :class-names="{
                    'ais-Highlight': 'text-xs',
                    'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                  }"
                />
              </div>
              <div>
                <h4 class="text-xs font-semibold">
                  Grupos musculares
                </h4>
                <div class="flex flex-col">
                  <ais-highlight
                    v-for="(group, index) in item.muscle_groups"
                    :key="group"
                    :attribute="`muscle_groups.${index}`"
                    :hit="item"
                    :class-names="{
                      'ais-Highlight': 'text-xs',
                      'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                    }"
                  />
                </div>
              </div>
              <div>
                <h4 class="text-xs font-semibold">
                  Tipo
                </h4>
                <div class="flex flex-col">
                  <ais-highlight
                    attribute="kind"
                    :hit="item"
                    :class-names="{
                      'ais-Highlight': 'text-xs',
                      'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                    }"
                  />
                </div>
              </div>
              <div>
                <h4 class="text-xs font-semibold">
                  Equipamiento
                </h4>
                <div class="flex flex-col">
                  <ais-highlight
                    attribute="equipment"
                    :hit="item"
                    :class-names="{
                      'ais-Highlight': 'text-xs',
                      'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                    }"
                  />
                </div>
              </div>
              <div>
                <h4 class="text-xs font-semibold">
                  Patrón de movimiento
                </h4>
                <div class="flex flex-col">
                  <ais-highlight
                    attribute="movement_pattern"
                    :hit="item"
                    :class-names="{
                      'ais-Highlight': 'text-xs',
                      'ais-Highlight-highlighted': 'text-xs bg-yellow-200',
                    }"
                  />
                </div>
              </div>
            </div>
          </template>
        </ais-hits>
        <ais-pagination />
      </div>
    </div>
  </ais-instant-search>
</template>
