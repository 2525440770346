<script setup lang="ts">
import { computed, ref } from 'vue';
import { startOfWeek, subWeeks } from 'date-fns';
import { useQuery } from '@tanstack/vue-query';
import type { ChatMessage, Trainer } from '@/types';
import type { Trainee } from '@/types/extended';
import { routinesApi } from '@/api/routines';
import { traineeNotesApi } from '@/api/trainee-notes';
import DeepChatWrapper from './deep-chat-wrapper.vue';

const props = defineProps<{
  openaiApiKey: string;
  trainee: Trainee;
  trainer: Trainer;
  workoutPlanId: number;
  chatMessages: ChatMessage[];
  isIntercomStyle: boolean;
}>();

const WEEKS_TO_SUBSTRACT = 3;
const dateSince = startOfWeek(subWeeks(new Date(), WEEKS_TO_SUBSTRACT), { weekStartsOn: 1 });

const routineQueryParams = ref({
  q: {
    'workout_plan_id_eq': props.workoutPlanId,
    'scheduled_at_gt': dateSince.toISOString(),
  },
});

const { data: routines } = useQuery({
  queryKey: ['routines', routineQueryParams],
  queryFn: () => routinesApi.getAll(routineQueryParams.value),
  initialData: [],
});

const traineeNotesQueryParams = ref({
  traineeId: props.trainee.id,
});

const { data: traineeNotes } = useQuery({
  queryKey: ['traineeNotes', traineeNotesQueryParams],
  queryFn: () => traineeNotesApi.getAll(traineeNotesQueryParams.value),
  initialData: [],
});

const hasRoutines = computed(() => routines.value.length > 0);
const isChatOpen = ref(false);

/* eslint-disable max-len, max-statements */
const traineeInfo = computed(() => {
  let textRepresentation = 'Initial Trainee responses to Surveys:\n';

  // Initial Survey Submission
  if (props.trainee.initialSurveySubmission) {
    textRepresentation += 'First Survey:\n';
    props.trainee.initialSurveySubmission.submissionAnswers.forEach(answer => {
      textRepresentation += `${answer.title}: ${answer.value}\n`;
    });
    textRepresentation += `Country: ${props.trainee.initialSurveySubmission.country}\n`;
    textRepresentation += '-----\n';
  }

  // Pre-Meeting Survey
  if (props.trainee.preMeetingSurvey) {
    textRepresentation += 'More information answered by the trainee:\n';
    props.trainee.preMeetingSurvey.submissionAnswers.forEach(answer => {
      textRepresentation += `${answer.title}: ${answer.value}\n`;
    });
    textRepresentation += '-----\n';
  }

  // Trainee Notes
  textRepresentation += 'Notes from the trainer:\n';
  traineeNotes.value.forEach(note => {
    const noteDetails = [
      `Note: ${note.content}\n`,
      `Created At: ${note.createdAt}\n`,
    ].join(', ');
    textRepresentation += `${noteDetails}\n`;
  });

  textRepresentation += '-----\n';

  textRepresentation += 'Trainee Routines:\n';
  routines.value.forEach(routine => {
    const routineDetails = [
      `Routine Name: ${routine.name}, ID: ${routine.id}, Scheduled At: ${routine.scheduledAt}`,
      `Duration: ${routine.durationInMinutes} minutes`,
      `Completed: ${routine.isCompleted ? 'Yes' : 'No'}`,
      `Published: ${routine.isPublished ? 'Yes' : 'No'}`,
    ].join(', ');
    textRepresentation += `${routineDetails}\n`;

    // Workout feedback
    routine.workouts.forEach(workout => {
      const workoutDetails = [
        `Workout ID: ${workout.id}`,
        `Trainee Comment: ${workout.comment}`,
        `Perceived RPE: ${workout.perceivedExertion}`,
        `Started At: ${workout.startedAt}`,
        `Ended At: ${workout.endedAt}`,
      ].join(', ');
      textRepresentation += `${workoutDetails}\n`;
    });

    // Routine Phases and Exercises
    routine.routinePhases.forEach(phase => {
      const phaseDetails = [
        `Phase: ${phase.name}`,
        `Position: ${phase.position}`,
      ].join(', ');
      textRepresentation += `${phaseDetails}\n`;

      phase.routinePhaseExercises.forEach(exercise => {
        const exerciseDetails = [
          `Exercise: ${exercise.exerciseName}`,
          `ID: ${exercise.id}`,
          `Duration: ${exercise.durationInSeconds} secs`,
          `Rest: ${exercise.restInSeconds} secs`,
        ].join(', ');
        textRepresentation += `${exerciseDetails}\n`;

        // eslint-disable-next-line max-nested-callbacks
        exercise.routineExerciseSets.forEach(set => {
          const setDetails = [
            `Set: ${set.setNumber}`,
            `Trainer Comment: ${set.comment}`,
            `Repetitions: ${set.repetitions ? set.repetitions : 'N/A'}`,
            `Weight: ${set.weight ? `${set.weight} kg` : 'N/A'}`,
          ].join(', ');
          textRepresentation += `${setDetails}\n`;
        });
      });
    });
    textRepresentation += '-----\n';
  });

  if (props.chatMessages.length > 0) {
    textRepresentation += 'Chat messages with the trainer:\n';
    props.chatMessages.forEach(message => {
      if (new Date(message.createdAt) > dateSince) {
        const messageDetails = [
          `Message: ${message.textContent}`,
          `Created At: ${message.createdAt}`,
          `Userable Type: ${message.userableType}`,
          `Is Kap: ${message.isBot}`,
        ].join(', ');

        textRepresentation += `${messageDetails}\n`;
      }
    });
  }

  return textRepresentation;
});
/* eslint-enable max-len, max-statements */

function getSystemPrompt() {
  return `You are a personal trainer assistant.\
    You help a personal trainer with a customer.\
    Current date: ${new Date().toLocaleString()}.\
    You are concise and you only explain general information if the trainer asks.\
    You receive the customer context, routines, and messages with the trainer.\
    In the messages, if isBot is true, is a message from Kap, the bot assistant in the chat.
    When it is relevant, mention that you only have data from the last ${WEEKS_TO_SUBSTRACT} weeks.
    Trainee name: ${props.trainee.fullName}.\
    Trainer name: ${props.trainer.fullName}.\
    Routines and messages:
    ${traineeInfo.value}`;
}

function getDirectConnection() {
  return {
    'openAI': {
      'key': props.openaiApiKey,
      'chat': {
        'model': 'gpt-4o-mini',
        'system_prompt': getSystemPrompt(),
      },
    },
  };
}

const initialMessages = [
  {
    'role': 'ai',
    'text': `Hola! ¿Cómo te puedo ayudar hoy? Tengo información de todos los mensajes de chat y entrenamientos de este
    cliente de las últimas tres semanas. Puedes pedirme cosas como: resumen de los entrenamientos realizados, resumen de
    los comentarios del cliente, pedirme los items que se mencionaron en el chat que debemos ajustar de los
    entrenamientos, etc. Pruébame!`,
  },
];

const textInput = {
  'placeholder': {
    'text': 'Pregúntale al asistente sobre el trainee',
  },
};

function toggleChat() {
  isChatOpen.value = !isChatOpen.value;
}

</script>

<template>
  <div
    v-if="props.isIntercomStyle"
    class="absolute bottom-4 right-4 z-40"
  >
    <button
      v-if="!isChatOpen"
      class="flex size-16 items-center justify-center rounded-full bg-blue-500 text-white shadow-lg transition-colors duration-300 hover:bg-blue-600"
      @click="toggleChat"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="size-8"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
        />
      </svg>
    </button>
    <div
      v-else
      class="flex h-[32rem] w-96 flex-col overflow-hidden rounded-lg bg-white shadow-xl"
    >
      <div class="flex items-center justify-between bg-blue-500 p-4 text-white">
        <h3 class="font-bold">
          AI Assistant
        </h3>
        <button
          class="text-white hover:text-gray-200"
          @click="toggleChat"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="size-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="grow overflow-auto">
        <deep-chat-wrapper
          v-if="hasRoutines"
          :key="props.trainee.id"
          :direct-connection="getDirectConnection()"
          :text-input="textInput"
          :initial-messages="initialMessages"
        />
        <div
          v-else
          class="p-4"
        >
          <p>
            No hay suficientes rutinas para usar el asistente de IA.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <deep-chat-wrapper
      v-if="hasRoutines"
      :key="props.trainee.id"
      :direct-connection="getDirectConnection()"
      :text-input="textInput"
      :initial-messages="initialMessages"
    />
    <div v-else>
      <p>
        No hay suficientes rutinas para usar el asistente de IA.
      </p>
    </div>
  </div>
</template>
