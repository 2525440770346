<script setup lang="ts">
import { computed, ref } from 'vue';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { useField } from 'vee-validate';
import { Check, ChevronDown } from 'lucide-vue-next';
import { BaseInput } from '@/components';

type Option = {
  id: number | string,
  name: string,
};

const props = withDefaults(defineProps<{
  modelValue: (number | string)[],
  name: string,
  options: Option[],
  by?: keyof Option,
}>(), {
  by: 'id',
});

const { meta, value: inputValue, errorMessage } = useField(props.name, undefined, {
  initialValue: props.modelValue,
  syncVModel: true,
});

const selectedOptions = computed(() =>
  props.options
    .filter((option) => inputValue.value.includes(option[props.by]))
    .map((option) => option.name),
);

const query = ref('');
const filteredOptions = computed(() => {
  const MAX_OPTIONS_TO_SHOW = 50;

  if (query.value === '') return props.options.slice(0, MAX_OPTIONS_TO_SHOW);

  return props.options.filter((option) =>
    option.name
      .toLowerCase()
      .replace(/\s+/g, '')
      .includes(query.value.toLowerCase().replace(/\s+/g, '')),
  ).slice(0, MAX_OPTIONS_TO_SHOW);
});

const displayValue = computed(() => selectedOptions.value.join(', ') || 'Seleccionar');
</script>

<template>
  <Listbox
    v-model="inputValue"
    :name="props.name"
    multiple
    as="div"
    class="relative flex flex-col gap-1"
  >
    <div
      class="rounded border bg-white text-sm ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-slate-950 focus-within:ring-offset-2"
      :class="errorMessage && meta.touched ? 'border-red-600 text-red-600' : 'border-slate-200'"
    >
      <ListboxButton class="flex w-full items-center justify-between px-3 py-2">
        <span class="text-left">
          {{ displayValue }}
        </span>
        <ChevronDown />
      </ListboxButton>
    </div>
    <span
      v-show="errorMessage && meta.touched"
      class="text-right text-xs text-red-600"
    >
      {{ errorMessage }}
    </span>
    <ListboxOptions
      class="absolute z-50 mt-1 flex max-h-80 w-full flex-col gap-1 overflow-auto rounded border border-gray-300 bg-white p-2 text-left text-xs  shadow focus:outline-none"
    >
      <BaseInput
        v-model="query"
        name="query"
        class="mb-1 w-full rounded"
        autocomplete="off"
      >
        {{ query }}
      </BaseInput>
      <template v-if="filteredOptions.length">
        <ListboxOption
          v-for="option in filteredOptions"
          v-slot="{ active, selected }"
          :key="option.id"
          :value="props.by === 'id' ? option.id : option.name"
        >
          <div
            class="flex cursor-pointer items-center gap-2 rounded px-2 py-3"
            :class="{'bg-gray-100': active}"
          >
            <Check
              v-if="selected"
              class="size-4"
            />
            <div
              v-else
              class="size-4"
            />
            <span>
              {{ option.name }}
            </span>
          </div>
        </ListboxOption>
      </template>
      <div
        v-else
        class="flex justify-center px-2 py-3"
      >
        <span>
          No se encontraron resultados
        </span>
      </div>
    </ListboxOptions>
  </Listbox>
</template>
