<script setup lang="ts">
import { computed } from 'vue';
import type { ExerciseExecution } from '@/types/exercise-execution';

const props = defineProps<{
  exerciseExecutions: ExerciseExecution[];
}>();

const executionsWithOneRepetitionMax = computed(() =>
  props.exerciseExecutions.filter(
    execution => execution.estimatedOneRepetitionMax && execution.estimatedOneRepetitionMax > 0,
  ),
);

const currentEstimatedOneRepetitionMax = computed(() => {
  const executions = executionsWithOneRepetitionMax.value;

  return executions.length > 0 ? executions[0].estimatedOneRepetitionMax : 0;
});

const initialEstimatedOneRepetitionMax = computed(() => {
  const executions = executionsWithOneRepetitionMax.value;

  return executions.length > 0 ? executions[executions.length - 1].estimatedOneRepetitionMax : 0;
});

const improvementPercentage = computed(() => {
  if (!initialEstimatedOneRepetitionMax.value || !currentEstimatedOneRepetitionMax.value) {
    return '--';
  }
  const improvement = (
    (currentEstimatedOneRepetitionMax.value - initialEstimatedOneRepetitionMax.value) /
      initialEstimatedOneRepetitionMax.value
  ) * 100;

  return improvement > 0 ? `+${improvement.toFixed(1)}%` : `${improvement.toFixed(1)}%`;
});

const chartOptions = computed(() => ({
  chart: { id: 'one_rep_max_chart' },
  xaxis: {
    categories: [...executionsWithOneRepetitionMax.value].reverse()
      .map(execution => new Date(execution.executionDate).toLocaleDateString()),
  },
  yaxis: {
    min: 0,
    labels: {
      formatter: (value: number) => `${value.toFixed(1)} kg`,
    },
  },
  markers: { size: 4 },
  stroke: { width: 5, curve: 'smooth' },
}));

const chartSeries = computed(() => [
  {
    name: '1RM Estimate',
    data: [...executionsWithOneRepetitionMax.value].reverse().map(execution => execution.estimatedOneRepetitionMax),
  },
]);
</script>

<template>
  <div>
    <h3 class="mb-4 text-lg font-semibold">
      Historial de trainee
    </h3>
    <div class="mb-4 flex items-center justify-between">
      <div>
        <div class="text-3xl font-bold">
          {{ currentEstimatedOneRepetitionMax?.toFixed(1) }} kg
        </div>
        <div class="text-sm text-gray-500">
          1RM Estimado
        </div>
      </div>
      <div>
        <div class="text-3xl font-bold">
          {{ improvementPercentage }}
        </div>
        <div class="text-sm text-gray-500">
          Mejora desde el inicio
        </div>
      </div>
    </div>

    <apexchart
      v-if="currentEstimatedOneRepetitionMax"
      width="100%"
      type="line"
      :options="chartOptions"
      :series="chartSeries"
    />

    <div
      v-if="exerciseExecutions.length"
      class="mt-6 overflow-x-auto"
    >
      <table class="min-w-full bg-white text-sm">
        <thead>
          <tr class="w-full border-b border-gray-300 bg-gray-100">
            <th class="px-4 py-2 text-left">
              Fecha
            </th>
            <th class="px-4 py-2 text-left">
              1RM estimado
            </th>
            <th class="px-4 py-2 text-left">
              Reps
            </th>
            <th class="px-4 py-2 text-left">
              Sets
            </th>
            <th class="px-4 py-2 text-left">
              Peso Max
            </th>
            <th class="px-4 py-2 text-left">
              Volumen
            </th>
            <th class="px-4 py-2 text-left">
              Descanso series
            </th>
            <th class="px-4 py-2 text-left">
              Avg. Esfuerzo
            </th>
            <th class="px-4 py-2 text-left">
              Mejor Set Reps
            </th>
            <th class="px-4 py-2 text-left">
              Mejor Set Peso
            </th>
            <th class="px-4 py-2 text-left">
              Mejor Set Esfuerzo
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="execution in exerciseExecutions"
            :key="execution.id"
            class="border-b border-gray-300"
          >
            <td class="px-4 py-2">
              {{ new Date(execution.executionDate).toLocaleDateString() }}
            </td>
            <td class="px-4 py-2">
              {{ execution.estimatedOneRepetitionMax?.toFixed(1) }} kg
            </td>
            <td class="px-4 py-2">
              {{ execution.totalRepetitions }}
            </td>
            <td class="px-4 py-2">
              {{ execution.sets }}
            </td>
            <td class="px-4 py-2">
              {{ execution.maxWeight?.toFixed(1) }} kg
            </td>
            <td class="px-4 py-2">
              {{ execution.totalVolume?.toFixed(1) }} kg
            </td>
            <td class="px-4 py-2">
              {{ execution.restDuration }}s
            </td>
            <td class="px-4 py-2">
              {{ execution.averageEffort?.toFixed(1) }}
            </td>
            <td class="px-4 py-2">
              {{ execution.bestSetRepetitions }}
            </td>
            <td class="px-4 py-2">
              {{ execution.bestSetWeight?.toFixed(2) }} kg
            </td>
            <td class="px-4 py-2">
              {{ execution.bestSetEffort?.toFixed(1) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p
      v-else
      class="mt-6"
    >
      No history available.
    </p>
  </div>
</template>
