<script setup lang="ts">
import { ref } from 'vue';
import { Form } from 'vee-validate';
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/vue-query';
import { object, string } from 'yup';
import { toTypedSchema } from '@vee-validate/yup';
import type { Routine } from '@/types/extended';
import type { RoutineTemplateAttributes } from '@/types';
import { useHumanReadableRoutine } from '@/composables/human-readable-routine';
import { useTransformRoutineToAttributes } from '@/composables/transform-routine-to-attributes';
import { routineTemplatesApi } from '@/api/routine-templates';
import { BaseButton, BaseInput, BaseLabel, BaseModal } from '.';

const props = defineProps<{
  routine: Routine,
  isOpen: boolean,
}>();

const emit = defineEmits<{ close: [] }>();

const { getHumanReadableRoutine } = useHumanReadableRoutine();
const { transformRoutineToAttributes } = useTransformRoutineToAttributes();

const validationSchema = toTypedSchema(
  object({
    name: string().required(),
  }));

const routineTemplateAttributes = ref<RoutineTemplateAttributes>({
  name: '',
  details: getHumanReadableRoutine(props.routine),
  routineAttributes: JSON.stringify({
    ...transformRoutineToAttributes(props.routine),
    scheduledAt: undefined,
    workoutPlanId: undefined,
    published: false,
  }),
});

const {
  isError,
  isIdle,
  isPending,
  isSuccess,
  error: createRoutineTemplateError,
  mutate: createRoutineTemplate,
  reset: resetCreateRoutineTemplate,
} = useMutation({
  mutationFn: () => routineTemplatesApi.create(routineTemplateAttributes.value),
});

</script>

<template>
  <BaseModal
    :open="props.isOpen"
    title="Guardar rutina como plantilla"
    @close="emit('close')"
  >
    <template
      v-if="isIdle"
    >
      <Form
        class="flex flex-col gap-4"
        :validation-schema="validationSchema"
        @submit="() => createRoutineTemplate()"
      >
        <div class="flex flex-col gap-1">
          <BaseLabel
            label="Nombre plantilla"
            name="name"
            required
          />
          <BaseInput
            v-model="routineTemplateAttributes.name"
            name="name"
          />
        </div>
        <div class="flex flex-col gap-2">
          <h3>
            Detalles
          </h3>
          <p class="h-[50dvh] overflow-y-scroll whitespace-pre-wrap text-xs">
            {{ getHumanReadableRoutine(props.routine) }}
          </p>
        </div>
        <BaseButton
          :disabled="routineTemplateAttributes.name === ''"
          type="submit"
        >
          Guardar plantilla
        </BaseButton>
      </Form>
    </template>
    <template
      v-else-if="isError"
    >
      <div class="flex flex-col gap-4">
        <p>Error al guardar la plantilla</p>
        <p
          v-if="(createRoutineTemplateError instanceof AxiosError)"
          class="whitespace-pre-wrap text-sm"
        >
          {{ createRoutineTemplateError.response?.data }}
        </p>

        <BaseButton
          @click="resetCreateRoutineTemplate"
        >
          Reintentar
        </BaseButton>
      </div>
    </template>
    <template
      v-else-if="isPending"
    >
      <p>Guardando plantilla...</p>
    </template>
    <template
      v-else-if="isSuccess"
    >
      <p>Plantilla guardada con éxito</p>
      <BaseButton
        @click="emit('close')"
      >
        Cerrar
      </BaseButton>
    </template>
  </BaseModal>
</template>
