<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';

type Item = {
  label: string;
  href?: string;
  onClick: () => void;
  disabled?: boolean;
};

const props = defineProps<{
  items: Item[];
}>();

</script>

<template>
  <Menu
    as="div"
    class="relative"
  >
    <MenuButton>
      <slot name="button" />
    </MenuButton>
    <MenuItems class="absolute right-0 z-10 mt-2 flex min-w-56 flex-col rounded-lg bg-white text-sm shadow-lg focus:outline-none">
      <MenuItem
        v-for="item in props.items"
        :key="item.label"
        v-slot="{ active }"
        :disabled="item.disabled"
        as="div"
        class="flex cursor-pointer border-b border-slate-50 px-2 py-1 first:rounded-t-lg first:pt-2 last:rounded-b-lg last:border-none last:pb-2"
        @click.self.prevent
      >
        <template v-if="item.onClick">
          <button
            :disabled="item.disabled"
            class="size-full rounded p-2 text-left hover:bg-slate-50"
            :class="{ 'bg-slate-50': active }"
            @click="item.onClick"
          >
            {{ item.label }}
          </button>
        </template>
        <template v-else>
          <a
            class="size-full rounded p-2 text-left hover:bg-slate-50"
            :class="{ 'bg-gray-100': item.disabled }"
            :href="item.href"
          >
            {{ item.label }}
          </a>
        </template>
      </MenuItem>
    </MenuItems>
  </Menu>
</template>
