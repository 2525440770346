<script setup lang="ts">
import { onMounted } from 'vue';
import { followUpMeetingSurveysApi } from '@/api/follow-up-meeting-surveys';
import type { TallySubmissionPayload, FollowUpMeetingSurvey } from '@/types';

const props = defineProps<{
  traineeId: number;
  tallyFormId: string;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'submitted', survey: FollowUpMeetingSurvey): void;
}>();

// eslint-disable-next-line max-statements
onMounted(() => {
  const widgetScriptSrc = 'https://tally.so/widgets/embed.js';

  // eslint-disable-next-line func-style
  const load = () => {
    // Load Tally embeds
    if (typeof Tally !== 'undefined') {
      Tally.loadEmbeds();

      return;
    }

    // Fallback if window.Tally is not available
    document
      .querySelectorAll('iframe[data-tally-src]:not([src])')
      .forEach((iframeEl) => {
        iframeEl.src = iframeEl.dataset.tallySrc;
      });
  };

  // If Tally is already loaded, load the embeds
  if (typeof Tally !== 'undefined') {
    load();

    return;
  }

  // If the Tally widget script is not loaded yet, load it
  if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
    const script = document.createElement('script');
    script.src = widgetScriptSrc;
    script.onload = load;
    script.onerror = load;
    document.body.appendChild(script);

    return;
  }
});

function handleSubmission(payload: TallySubmissionPayload) {
  followUpMeetingSurveysApi.create(props.traineeId, payload)
    .then((response) => {
      emit('submitted', response.data);
    })
    .catch((error) => {
      console.error('Error submitting follow-up survey:', error);
    });
}

// Add event listener for Tally form submission
window.addEventListener('message', (e) => {
  if (e?.data?.includes('Tally.FormSubmitted')) {
    const payload = JSON.parse(e.data).payload as TallySubmissionPayload;
    handleSubmission(payload);
  }
});
</script>

<template>
  <div class="fixed inset-0 flex items-center justify-center overflow-y-auto bg-gray-600 bg-opacity-50">
    <div class="flex size-3/4 flex-col rounded-lg bg-white p-8 shadow-lg">
      <div class="flex items-center justify-between p-4">
        <h2 class="text-xl font-semibold">
          Formulario de Seguimiento
        </h2>
        <button
          class="text-gray-500 hover:text-gray-700"
          @click="emit('close')"
        >
          <span class="sr-only">Close</span>
          <svg
            class="size-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="grow overflow-y-auto">
        <iframe
          :data-tally-src="`https://tally.so/embed/${tallyFormId}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`"
          loading="lazy"
          width="100%"
          height="100%"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
          title="Survey de Seguimiento"
        />
      </div>
    </div>
  </div>
</template>
