import { connect } from 'extendable-media-recorder-wav-encoder';
import { register } from 'extendable-media-recorder';

let encoderRegistered = false;

async function registerEncoder() {
  if (encoderRegistered) return;
  await register(await connect());
  encoderRegistered = true;
}

export const EncoderPlugin = {
  install: async () => {
    await registerEncoder();
  },
};

