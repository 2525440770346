<script setup lang="ts">
import { computed, ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { formatDate } from '@/utils/format-date';
import type { Trainee } from '@/types/extended';
import { workoutsApi } from '@/api/workouts';

const props = defineProps<{
  trainee: Trainee,
}>();

const workoutPlan = props.trainee.workoutPlans[0];

const workoutsQueryParams = ref({
  'perceived_exertion_not_null': true,
  'routine_workout_plan_id_eq': workoutPlan.id,
});

const { data: workouts } = useQuery({
  queryKey: ['workouts', workoutsQueryParams],
  queryFn: () => workoutsApi.getAll({
    filters: workoutsQueryParams.value,
  }),
  initialData: [],
});

const options = computed(() => ({
  chart: {
    id: 'rpe_chart',
  },
  xaxis: {
    categories: workouts.value?.map(
      (workout) => `${workout?.routine?.name} ${formatDate(workout.endedAt || new Date())}`,
    ) ?? [],
  },
  yaxis: {
    min: 1,
    max: 10,
  },
  annotations: {
    yaxis: [
      {
        y: 1,
        y2: 3,
        fillColor: '#ADD8E6',
        opacity: 0.2,
        borderWidth: 0,
      },
      {
        y: 8,
        y2: 10,
        fillColor: '#FFA07A',
        opacity: 0.2,
        borderWidth: 0,
      },
    ],
  },
  markers: {
    size: 4,
  },
  stroke: {
    width: 5,
    curve: 'smooth',
  },
}));

const series = computed(() => [
  {
    name: 'RPE',
    data: workouts.value?.map((workout) => workout.perceivedExertion) ?? [],
  },
]);

</script>

<template>
  <div>
    <h1>RPE</h1>
    <apexchart
      width="100%"
      type="line"
      :options="options"
      :series="series"
    />
  </div>
</template>
