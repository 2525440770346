<script setup lang="ts">
import { computed } from 'vue';
import type { RoutineExerciseSet, WorkoutExercise } from '@/types';
import type { RoutinePhaseExercise } from '@/types/extended';

const props = defineProps<{
  set: RoutineExerciseSet;
  routinePhaseExercise: RoutinePhaseExercise;
  workoutExercise?: WorkoutExercise;
}>();

const completedRepetitions = computed(() => props.workoutExercise?.repetitions || '-');
const completedWeight = computed(() => props.workoutExercise?.weight || '-');
const effort = computed(() => props.workoutExercise?.effort || '-');
const isCompleted = computed(() => (props.workoutExercise?.completedAt ? '✅' : '-'));
const traineeComment = computed(() => props.workoutExercise?.traineeComment || '-');

const repsDifference = computed(() => {
  if (!props.workoutExercise?.repetitions || !props.set.repetitions) return '';

  const diff = props.workoutExercise.repetitions - props.set.repetitions;

  return diff === 0 ? '' : `(${diff > 0 ? '+' : ''}${diff})`;
});

const weightDifference = computed(() => {
  if (!props.workoutExercise?.weight || !props.set.weight) return '';

  const diff = props.workoutExercise.weight - props.set.weight;

  return diff === 0 ? '' : `(${diff > 0 ? '+' : ''}${diff})`;
});
</script>

<template>
  <tr class="border-b last:border-b-0">
    <td class="px-3 py-2 text-center text-gray-500">
      {{ set.setNumber }}
    </td>
    <td
      v-if="set.repetitions"
      class="px-3 py-2"
    >
      {{ `${completedRepetitions} / ${set.repetitions}` }}
      <span class="ml-1 text-gray-500">{{ repsDifference }}</span>
    </td>
    <td
      v-else
      class="px-3 py-2"
    >
      -
    </td>
    <td class="px-3 py-2">
      <span v-if="set.weight">
        {{ `${completedWeight} / ${set.weight}` }}
        <span class="ml-1 text-gray-500">{{ weightDifference }}</span>
      </span>
      <span v-else>
        -
      </span>
    </td>
    <td class="px-3 py-2">
      {{ effort }}
    </td>
    <td class="px-3 py-2">
      {{ isCompleted }}
    </td>
    <td class="px-3 py-2">
      {{ traineeComment }}
    </td>
  </tr>
</template>
