<script setup lang="ts">
import { ref } from 'vue';
import { Menu, X } from 'lucide-vue-next';

const props = defineProps<{
  controllerPath: string;
}>();

const hasSidebar = ref(['trainees', 'teams', 'exercises', 'routines', 'workouts'].includes(props.controllerPath));
const isSidebarOpen = ref(false);

function toggleSidebar() {
  isSidebarOpen.value = !isSidebarOpen.value;
}

const sidebarItems = [
  { name: 'Trainees', path: '/', controllerPath: 'trainees' },
  { name: 'Agenda', path: '/routines/schedule', controllerPath: '/routines/schedule' },
  { name: 'Ejercicios', path: '/exercises', controllerPath: 'exercises' },
];

</script>

<template>
  <div class="flex h-full">
    <template v-if="hasSidebar">
      <aside
        class="absolute left-0 top-0 z-40 h-screen w-48 bg-white pt-14 shadow-md transition-transform"
        :class="[isSidebarOpen ? 'translate-x-0' : '-translate-x-full']"
      >
        <div class="h-full overflow-y-auto px-3 py-4">
          <ul class="space-y-2 font-medium">
            <li
              v-for="item in sidebarItems"
              :key="item.name"
            >
              <a
                :href="item.path"
                class="flex items-center rounded-lg p-2 text-gray-900 hover:bg-gray-100"
                :class="{ 'bg-gray-100': item.controllerPath === props.controllerPath }"
              >
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
      </aside>
      <button
        type="button"
        class="fixed left-0 top-0 z-50 m-2 rounded-md bg-white p-2 transition-colors duration-200 hover:bg-gray-100"
        @click="toggleSidebar"
      >
        <span class="sr-only">Toggle sidebar</span>
        <Menu
          v-if="!isSidebarOpen"
          class="size-6"
        />
        <X
          v-else
          class="size-6"
        />
      </button>
    </template>
    <main
      class="h-screen flex-1 overflow-y-auto transition-all duration-300"
      :class="{ 'sm:ml-48': isSidebarOpen, 'sm:pl-14': !isSidebarOpen && hasSidebar }"
    >
      <slot />
    </main>
  </div>
</template>
