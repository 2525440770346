// From https://github.com/domchristie/humps/issues/51#issuecomment-425113505
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { camelize, decamelize } from 'humps';

export type ObjectToConvert =
  | File
  | FormData
  | Blob
  | URLSearchParams
  | ReadableStream<unknown>
  | BufferSource
  | Record<string, unknown>
  | Array<ObjectToConvert>;

function convertKeys(
  object: ObjectToConvert,
  conversion: 'camelize' | 'decamelize',
): ObjectToConvert {
  const converter = {
    camelize,
    decamelize,
  };
  if (object && !(object instanceof File) && !(object instanceof Blob) && !(object instanceof ReadableStream)) {
    if (object instanceof Array) {
      return object.map((item: ObjectToConvert) => convertKeys(item, conversion));
    }
    if (object instanceof FormData) {
      const formData = new FormData();
      for (const [key, value] of object.entries()) {
        formData.append(converter[conversion](key), value);
      }

      return formData;
    }
    if (object instanceof URLSearchParams) {
      const params = new URLSearchParams();
      for (const [key, value] of object.entries()) {
        params.append(converter[conversion](key), value);
      }

      return params;
    }
    if (typeof object === 'object' && !ArrayBuffer.isView(object)) {
      return Object.keys(object as Record<string, unknown>).reduce((acc, next) => {
        const convertedKey = converter[conversion](next);
        const convertedValue = convertKeys(
          (object as Record<string, unknown>)[next] as ObjectToConvert,
          conversion,
        );

        return { ...acc, [convertedKey]: convertedValue };
      }, {});
    }
  }

  return object;
}

export { convertKeys };
