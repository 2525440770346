<script setup lang="ts">
import 'deep-chat';

const props = defineProps<{
  directConnection: {
    openAI: {
      key: string;
      chat: {
        model: string;
        system_prompt: string;
      }
    },
  },
  initialMessages: {
    role: string;
    text: string;
  }[],
  textInput: {
    placeholder: {
      text: string;
    }
  },
}>();

</script>

<template>
  <deep-chat
    style="background-color: #f7f7f7; border-radius: 8px; display: flex; width: 100%; height: 100%;"
    stream="true"
    chat-style="{ height: '100%' }"
    :direct-connection="props.directConnection"
    :text-input="props.textInput"
    :initial-messages="props.initialMessages"
  />
</template>
