function isPlainObject(value: unknown): value is Record<string, unknown> {
  return value !== null && typeof value === 'object' && !Array.isArray(value);
}

export function transformToNestedAttributes<T extends Record<string, unknown>>(
  obj: T, nestedAttributeKeys: Set<string>,
): T {
  const result: Record<string, unknown> = {};

  for (const [key, value] of Object.entries(obj)) {
    if (value instanceof Blob) {
      result[key] = value;
    } else if (nestedAttributeKeys.has(key) && Array.isArray(value)) {
      result[`${key}Attributes`] = value.map((item) =>
        transformToNestedAttributes(item as Record<string, unknown>, nestedAttributeKeys),
      );
    } else if (isPlainObject(value)) {
      result[key] = transformToNestedAttributes(value, nestedAttributeKeys);
    } else {
      result[key] = value;
    }
  }

  return result as T;
}
