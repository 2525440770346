<script setup lang="ts">
import { ref, computed } from 'vue';
import type { WorkoutPlan, Team } from '@/types/extended';
import { BaseLink } from '@/components';
import { formatDate } from '@/utils/format-date';

const props = defineProps<{
  workoutPlans: WorkoutPlan[];
}>();

const teams = ref<Team[]>(props.workoutPlans.map((workoutPlan) => workoutPlan.team));

const selectedTeam = ref(teams.value[0]);

const selectedTeamWorkoutPlans = computed<WorkoutPlan[]>(() => {
  return props.workoutPlans.filter((workoutPlan) => workoutPlan.team.id === selectedTeam.value.id);
});

const trainees = computed(() => {
  return selectedTeamWorkoutPlans.value
    .map((workoutPlan) => workoutPlan.trainees)
    .flat();
});
</script>

<template>
  <div class="flex flex-col gap-8">
    <h1 class="text-2xl font-bold">
      Equipos
    </h1>
    <div class="flex gap-4">
      <div class="flex w-48 flex-col gap-1">
        <button
          v-for="team in teams"
          :key="team.id"
          class="rounded-lg border p-3 text-sm hover:bg-gray-100"
          :class="{ 'border-2 bg-gray-100': selectedTeam?.id === team.id }"
          @click="selectedTeam = team"
        >
          {{ team.name }}
        </button>
      </div>
      <div
        v-if="selectedTeam"
        class="flex w-full flex-col gap-4 rounded-lg border bg-gray-50 p-4"
      >
        <h2 class="text-xl font-bold">
          {{ selectedTeam.name }}
        </h2>
        <h3 class="font-medium">
          Planes de entrenamiento
        </h3>
        <div class="flex flex-col gap-2">
          <template v-if="workoutPlans.length === 0">
            <p class="text-sm">
              No hay planes de entrenamiento
            </p>
          </template>
          <template v-else>
            <div
              v-for="workoutPlan in selectedTeamWorkoutPlans"
              :key="workoutPlan.id"
              class="flex items-center justify-between rounded-lg border bg-white p-3 text-sm"
            >
              <p>
                {{ formatDate(workoutPlan.startAt, { dateStyle: 'long' }) }} -
                {{ formatDate(workoutPlan.endAt, { dateStyle: 'long' }) }}
              </p>
              <BaseLink :href="`/workout_plans/${workoutPlan.id}/routines`">
                Ver
              </BaseLink>
            </div>
          </template>
        </div>
        <h3 class="font-medium">
          Trainees
        </h3>
        <div class="flex flex-col gap-2">
          <template v-if="trainees.length === 0">
            <p class="text-sm">
              No hay trainees
            </p>
          </template>
          <template v-else>
            <div
              v-for="trainee in trainees"
              :key="trainee.id"
              class="flex items-center justify-between rounded-lg border bg-white p-3 text-sm"
            >
              <p>
                {{ trainee.fullName }}
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
