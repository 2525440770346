import { serialize } from 'object-to-formdata';
import type { Exercise, ExerciseAttributes } from '@/types/extended';
import { api } from './index';

export const exercisesApi = {
  async getAll(ids?: number[]) {
    const response = await api({
      method: 'get',
      url: '/api/internal/exercises',
      params: { q: { 'id_in': ids } },
    });

    return response.data as { id: number; name: string }[];
  },

  async get(id: number) {
    const response = await api({
      method: 'get',
      url: `/api/internal/exercises/${id}`,
    });

    return response.data as Exercise;
  },

  async create(exerciseAttributes: ExerciseAttributes) {
    const formData = serialize({ exercise: exerciseAttributes }, { indices: true });

    const response = await api({
      method: 'post',
      url: '/api/internal/exercises',
      data: formData,
    });

    return response.data as Exercise;
  },

  async update(id: number, exerciseAttributes: ExerciseAttributes) {
    const formData = serialize({ exercise: exerciseAttributes }, { indices: true });

    const response = await api({
      method: 'put',
      url: `/api/internal/exercises/${id}`,
      data: formData,
    });

    return response.data as Exercise;
  },
};
