<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';

const isScriptLoaded = ref(false);
let checkoutForm;

const props = defineProps<{
  email: string;
  firstName: string;
  lastName: string;
  subscriptionId: string;
  redirectPath: string;
  currency: string;
}>();

const formData = ref({
  email: props.email,
  firstName: props.firstName,
  lastName: props.lastName,
  countryCode: '',
  phone: '',
  addressLine1: '',
  state: '',
  city: '',
  postalCode: '',
});

const fieldState = ref<{ [key: string]: { error: string | null, touched: boolean } }>({});

function updateRebillForm() {
  if (!checkoutForm) return;

  checkoutForm.set({
    customerInformation: {
      email: formData.value.email,
      firstName: formData.value.firstName.trim(),
      lastName: formData.value.lastName.trim(),
      phoneNumber: {
        countryCode: formData.value.countryCode.replace('+', '').replace(' ', ''),
        number: formData.value.phone,
      },
    },
    billing: {
      city: formData.value.city,
      line1: formData.value.addressLine1,
      zipCode: formData.value.postalCode,
      state: formData.value.state,
    },
    cardHolderDetails: {
      name: `${formData.value.firstName} ${formData.value.lastName}`,
    },
  });
}

onMounted(() => {
  const script = document.createElement('script');
  script.src = 'https://sdk.rebill.to/v3/rebill.js';
  script.async = true;
  script.onload = () => {
    isScriptLoaded.value = true;

    const style = document.createElement('style');
    style.innerHTML = `
      #rebill_elements {
        height: 100% !important;
      }
    `;
    document.head.appendChild(style);
  };
  document.head.appendChild(script);
});

watch(isScriptLoaded, (value) => {
  if (value) {
    const rebill = new Rebill(
      'pk_1e32efb9-76de-4ed3-93f5-f15b8e871942',
    );

    checkoutForm = rebill.checkout.create({
      id: props.subscriptionId,
      currency: props.currency,
    });

    checkoutForm.translations({
      default: 'es',
      es: {
        'Pay now': 'Continuar',
      },
    });

    checkoutForm.on('approved', () => {
      window.location.href = props.redirectPath;
    });

    checkoutForm.custom({
      css:
        `
          #billing-information-title {
            display: none;
          }

          #checkout-payment-title {
            display: none;
          }

          #checkout-form-container {
            max-width: 100%;
            width: 100%;
          }

          #input-container {
            height: 100%;
          }

          #rebill_elements {
            height: 100% !important;
          }

          #checkout-payment-container {
            width: 100%;
          }

          #checkout-form {
            width: 100%;
          }
        `,
    });

    checkoutForm.display({
      checkoutSummary: false,
      discountCode: false,
      userLogin: false,
      cardHolderDetail: false,
      customerInformation: false,
      billing: false,
      excludePaymentMethods: ['CASH', 'TRANSFER'],
    });

    checkoutForm.mount('rebill');

    checkoutForm.on('formStatus', (event) => {
      console.log(event);
      Object.keys(event.errors || {}).forEach(field => {
        if (!fieldState.value[field]) {
          fieldState.value[field] = { error: event.errors[field], touched: false };
        } else {
          fieldState.value[field].error = event.errors[field];
        }
      });

      // Clear formStatus errors if no longer relevant
      Object.keys(fieldState.value).forEach(field => {
        if (!event.errors[field]) {
          fieldState.value[field].error = null;
        }
      });
    });

    updateRebillForm(); // Initial set with form data
  }
});

watch(formData, () => {
  updateRebillForm();
}, { deep: true });

function handleBlur(field: string) {
  if (!fieldState.value[field]) {
    fieldState.value[field] = { error: null, touched: true };
  } else {
    fieldState.value[field].touched = true;
  }

  validateField(field);
}

function validateField(field: string) {
  if (!formData.value[field]) {
    fieldState.value[field].error = `${field.charAt(0).toUpperCase() + field.slice(1)} es requerido`;
  }
}
</script>

<template>
  <div class="flex flex-col gap-y-6">
    <div class="space-y-4">
      <div>
        <label
          for="email"
          class="block text-sm font-medium text-gray-700"
        >Correo electrónico <span class="text-red-600">*</span></label>
        <input
          id="email"
          v-model="formData.email"
          type="email"
          required
          class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          @blur="handleBlur('email')"
          @input="validateField('email')"
        >
        <p
          v-if="fieldState.email?.touched && fieldState.email?.error"
          class="text-sm text-red-600"
        >
          {{ fieldState.email.error }}
        </p>
      </div>

      <div class="flex justify-between gap-x-2">
        <div class="flex-1">
          <label
            for="firstName"
            class="block text-sm font-medium text-gray-700"
          >Nombre <span class="text-red-600">*</span></label>
          <input
            id="firstName"
            v-model="formData.firstName"
            type="text"
            required
            class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            @blur="handleBlur('firstName')"
            @input="validateField('firstName')"
          >
          <p
            v-if="fieldState.firstName?.touched && fieldState.firstName?.error"
            class="text-sm text-red-600"
          >
            {{ fieldState.firstName.error }}
          </p>
        </div>

        <div class="flex-1">
          <label
            for="lastName"
            class="block text-sm font-medium text-gray-700"
          >Apellido <span class="text-red-600">*</span></label>
          <input
            id="lastName"
            v-model="formData.lastName"
            type="text"
            required
            class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            @blur="handleBlur('lastName')"
            @input="validateField('lastName')"
          >
          <p
            v-if="fieldState.lastName?.touched && fieldState.lastName?.error"
            class="text-sm text-red-600"
          >
            {{ fieldState.lastName.error }}
          </p>
        </div>
      </div>

      <div class="flex gap-x-2">
        <div class="w-2/6 md:w-1/6">
          <label
            for="countryCode"
            class="block text-sm font-medium text-gray-700"
          >Código país<span class="text-red-600">*</span></label>
          <input
            id="countryCode"
            v-model="formData.countryCode"
            type="text"
            required
            class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            @blur="handleBlur('countryCode')"
            @input="validateField('countryCode')"
          >
          <p
            v-if="fieldState.countryCode?.touched && fieldState.countryCode?.error"
            class="text-sm text-red-600"
          >
            {{ fieldState.countryCode.error }}
          </p>
        </div>

        <div class="w-4/6 md:w-5/6">
          <label
            for="phone"
            class="block text-sm font-medium text-gray-700"
          >Teléfono <span class="text-red-600">*</span></label>
          <input
            id="phone"
            v-model="formData.phone"
            type="tel"
            required
            class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            @blur="handleBlur('phone')"
            @input="validateField('phone')"
          >
          <p
            v-if="fieldState.phone?.touched && fieldState.phone?.error"
            class="text-sm text-red-600"
          >
            {{ fieldState.phone.error }}
          </p>
        </div>
      </div>

      <div class="flex justify-between gap-x-2">
        <div class="flex-1">
          <label
            for="state"
            class="block text-sm font-medium text-gray-700"
          >Estado <span class="text-red-600">*</span></label>
          <input
            id="state"
            v-model="formData.state"
            type="text"
            required
            class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            @blur="handleBlur('state')"
            @input="validateField('state')"
          >
          <p
            v-if="fieldState.state?.touched && fieldState.state?.error"
            class="text-sm text-red-600"
          >
            {{ fieldState.state.error }}
          </p>
        </div>

        <div class="flex-1">
          <label
            for="city"
            class="block text-sm font-medium text-gray-700"
          >Ciudad <span class="text-red-600">*</span></label>
          <input
            id="city"
            v-model="formData.city"
            type="text"
            required
            class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            @blur="handleBlur('city')"
            @input="validateField('city')"
          >
          <p
            v-if="fieldState.city?.touched && fieldState.city?.error"
            class="text-sm text-red-600"
          >
            {{ fieldState.city.error }}
          </p>
        </div>
      </div>

      <div class="flex justify-between gap-x-2">
        <div class="flex-1">
          <label
            for="addressLine1"
            class="block text-sm font-medium text-gray-700"
          >Dirección <span class="text-red-600">*</span></label>
          <input
            id="addressLine1"
            v-model="formData.addressLine1"
            type="text"
            required
            class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            @blur="handleBlur('addressLine1')"
            @input="validateField('addressLine1')"
          >
          <p
            v-if="fieldState.addressLine1?.touched && fieldState.addressLine1?.error"
            class="text-sm text-red-600"
          >
            {{ fieldState.addressLine1.error }}
          </p>
        </div>

        <div class="flex-1">
          <label
            for="postalCode"
            class="block text-sm font-medium text-gray-700"
          >Código postal <span class="text-red-600">*</span></label>
          <input
            id="postalCode"
            v-model="formData.postalCode"
            type="text"
            required
            class="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            @blur="handleBlur('postalCode')"
            @input="validateField('postalCode')"
          >
          <p
            v-if="fieldState.postalCode?.touched && fieldState.postalCode?.error"
            class="text-sm text-red-600"
          >
            {{ fieldState.postalCode.error }}
          </p>
        </div>
      </div>
    </div>

    <div
      id="rebill"
      class="w-full"
      :class="{
        'h-108': currency !== 'MXN',
        'h-84': currency === 'MXN',
      }"
    />
  </div>
</template>

<style scoped>
  .h-108 {
    height: 27rem;
  }
  .h-84 {
    height: 21rem;
  }
</style>
