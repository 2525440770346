import type { OnboardingTrainerSelection } from '@/types';
import { api } from './index';

export const trainerSelectionApi = {
  async create(onboardingTrainerSelection: Partial<OnboardingTrainerSelection>) {
    const response = await api({
      method: 'post',
      url: '/api/internal/trainer_selections',
      data: { onboardingTrainerSelection },
    });

    return response.data as OnboardingTrainerSelection;
  },
};
